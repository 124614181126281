import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { SubmissionError } from 'redux-form';
import styled from 'styled-components';

import { required, WhiteStyledField, DictInput } from 'libs/form';
import { Button, themes, ButtonContainer } from 'libs/ui';
import { useTranslation } from 'react-i18next';

const StyledButtonContainer = styled(ButtonContainer)`
  margin-top: 20px;
`;

function Form(props) {
  const { t } = useTranslation();

  const submit = (values) => {
    let decorateValues = {};

    for (let key in values) {
      switch (key) {
        case 'is_active':
          if (values[key].toString() === 'true') {
            decorateValues[key] = true;
          } else if (values[key].toString() === 'false') {
            decorateValues[key] = false;
          }
          break;
        default:
          decorateValues[key] = values[key];
          break;
      }
    }

    return props.updateEntity(props.id, decorateValues, afterSubmit);
  };

  const afterSubmit = () => {
    props.getEntries({ offset: 0 });
    props.updateMessageDisplayHandler(true);
    setTimeout(() => {
      props.updateMessageDisplayHandler(false);
    }, 3000);
    props.displayHandler(false);
    props.destroy(props.form);
  };

  return (
    <form onSubmit={props.handleSubmit(submit)}>
      <DictInput dict="users.status" />
      <WhiteStyledField
        type="text"
        label={t('form.Номер БСК')}
        placeholder={t('form.Номер БСК')}
        name="card_id"
        validate={required(t)}
        readOnly
        disabled
      />
      <WhiteStyledField
        type="text"
        label={t('form.ФИО')}
        placeholder={t('form.Иванов Иван Иванович')}
        name="name"
        validate={required(t)}
      />
      <StyledButtonContainer space="20px">
        <Button
          type="button"
          onClick={() => {
            props.displayHandler(false);
            props.destroy(props.form);
          }}
        >
          {t('buttons.Отменить')}
        </Button>
        <Button theme={themes.blue}>
          <i className="fa fa-save" />
          {t('buttons.Сохранить')}
        </Button>
      </StyledButtonContainer>
    </form>
  );
}

Form = reduxForm()(Form);

Form = connect((store, props) => {
  return {
    form: `updateUserForm_${props.id}`,
    destroyOnUnmount: false,
    initialValues: props.initialValues,
  };
})(Form);

export default Form;
