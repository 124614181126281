import { gridsConfig } from 'config-grids';
import { LocalStorage } from 'libs/common';
import { user } from 'services/user';
const gridStorage = new LocalStorage('grid');

import { useTranslation } from 'react-i18next';

export const isPageWithSetup = (page) => {
  return !!gridsConfig[page];
};

/**
 * get page config
 * @param {*} page
 * @param {*} t
 * @returns
 */
export const getPageConfig = (page) => {
  const { t } = useTranslation();

  let out = getUserConfig(page);
  if (!out) {
    if (gridsConfig[page]) {
      out = JSON.parse(JSON.stringify(gridsConfig[page](t)));
    }
  }
  return out;
};

/**
 * load config
 * @param {*} page
 * @returns
 */
export const getPageSavedConfig = (page) => {
  return gridStorage.getItem(page);
};

/**
 * save config
 * @param {*} page
 * @param {*} config
 * @returns
 */
export const setPageSavedConfig = (page, config) => {
  return gridStorage.setItem(page, config);
};

/**
 * get grid config from backend
 * @param {*} page
 * @returns
 */
export const getUserConfig = (page) => {
  const items = user.getGridConfig();
  // console.log('🚀 ~ getUserConfig ~ items:', items);
  return (items.grid && items.grid[page]) ?? {};
};
