import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SubmissionError } from 'redux-form';

import { user } from 'services';
import { UpdateMessage } from 'libs/ui';
import { ClientData, PersonalDataHeader } from '../styledComponents';
import ContactFaceForm from './forms/ContactFace';

import { useTranslation } from 'react-i18next';

const ContactFacesDataWrapper = styled(ClientData)`
  position: relative;
`;

const UpdateMessageBox = styled(UpdateMessage)`
  bottom: -40px;
  left: 100px;
  z-index: 1;
`;

const ContactFace = (props) => {
  const { t } = useTranslation();

  const [showUpdateMessage, setShowUpdateMessage] = useState(false);

  useEffect(() => {
    if (props.info && props.showMessageAfterAddContact === props.info.id) {
      removeMessageAfterAdd();
    }
  }, [props.info, props.showMessageAfterAddContact]);

  const removeMessageAfterAdd = () => {
    setTimeout(() => {
      props.addContactMessageDisplayHandler(false);
    }, 3000);
  };

  const submit = (values) => {
    const rightUpdate = user.haveRight('clients.update');
    if (!rightUpdate) {
      return Promise.resolve();
    }

    if (props.info && props.info.id) {
      return props
        .updateClientContact(
          {
            id: props.info.id,
            info: {
              client_id: props.info.client_id,
              ...values,
            },
          },
          showMessage
        )
        .then((errors) => {
          let submissionErrors = {};
          if (Array.isArray(errors)) {
            errors.forEach((element) => {
              submissionErrors[element.field] = element.type;
              submissionErrors._error = errors.type;
            });
          }

          throw new SubmissionError({ ...submissionErrors });
        });
    } else {
      return props
        .addClientContact(
          {
            client_id: props.client_id,
            info: { ...values },
          },
          afterAddContact
        )
        .then((errors) => {
          let submissionErrors = {};
          if (Array.isArray(errors)) {
            errors.forEach((element) => {
              submissionErrors[element.field] = element.type;
              submissionErrors._error = errors.type;
            });
          }

          throw new SubmissionError({ ...submissionErrors });
        });
    }
  };

  const showMessage = () => {
    setShowUpdateMessage(true);
    setTimeout(() => {
      setShowUpdateMessage(false);
    }, 3000);
  };

  const afterAddContact = (contactId) => {
    props.addContactMessageDisplayHandler(contactId);
    props.hideNewContactForm();
  };

  return (
    <ContactFacesDataWrapper>
      <PersonalDataHeader>{t('grid.Контактное лицо')}</PersonalDataHeader>
      <ContactFaceForm onSubmit={submit} info={props.info} />
      {showUpdateMessage && <UpdateMessageBox>{t('grid.Изменения сохранены')}</UpdateMessageBox>}
      {props.info && props.showMessageAfterAddContact === props.info.id ? (
        <UpdateMessageBox>{t('messages.Контактное лицо сохранено')}</UpdateMessageBox>
      ) : null}
    </ContactFacesDataWrapper>
  );
};

export default ContactFace;
