import { data } from 'services';
import { getAmount } from 'libs/common';
import { getDuration, getDateTime, getShortRFID, getMeanStatus } from 'services/types';
import { ParkingCell, ZonesIcons, MeanStatus } from 'libs/ui';

export const getTableParams = (inList = true, t) => {
  // get row class
  const getRowClass = (row) => {
    let out = '';
    row.is_suspicious && (out += ' is_suspicious');
    out += row.is_active ? ' active' : ' archive';
    return out;
  };

  const getFields = () => {
    const titles = {
      Зона: 'grid.Зона',
      Статус: 'grid.Статус',
      Клиент: 'form.Клиент',
      ГРЗ: 'sections.ГРЗ',
      Тип_СО: 'dict_filter.Тип СО',
      СО: 'grid.СО',
      Статус_СО: 'grid.Статус СО',
      Начало: 'grid.Начало',
      Конец: 'grid.Конец',
      Длительность: 'grid.Длительность',
      Тариф: 'form.Тариф',
      Сумма: 'grid.Сумма',
      Дополнительная_информация: 'grid.Дополнительная информация',
    };

    const fields = [
      {
        name: 'stay_zones',
        title: titles.Зона,
        width: 'auto',
        render: (val) => <ZonesIcons stayZones={val} />,
      },
      {
        name: 'is_active',
        title: titles.Статус,
        width: 'auto',
        render: (val) => data.getDictValue('stays.status', val),
      },
      { name: 'client_name', title: titles.Клиент },

      { name: 'vehicle_plate', title: titles.ГРЗ, width: 'auto', sortField: 'vehicle_plate', clip: true },
      {
        name: 'mean_type',
        title: titles.Тип_СО,
        width: 'auto',
        render: (val) => data.getDictValue('stays.mean_type', val),
        clip: true,
      },

      {
        name: 'mean_number',
        title: titles.СО,
        width: 'auto',
        render: (val, __other, row) => (row.mean_type === 'rfid' ? getShortRFID(val) : val),
        clip: true,
      },
      { name: 'entry_at', title: titles.Начало, width: 210, sortField: 'entry_at', render: (val) => getDateTime(val) },
      { name: 'leave_at', title: titles.Конец, width: 210, sortField: 'leave_at', render: (val) => getDateTime(val) },
      {
        name: 'duration',
        title: titles.Длительность,
        width: 153,
        sortField: 'duration',
        render: (val) => getDuration(val),
        numeric: true,
        clip: true,
      },
      {
        name: 'tariff_name',
        title: titles.Тариф,
        width: 175,
        render: (val, _other, row) => {
          if (!val) {
            return '';
          }
          if (inList) {
            return val;
          }

          const link = '/card/tariff?id=' + row.tariff_id;
          return (
            <a target="_blank" rel="noopener noreferrer" href={link}>
              {val}
            </a>
          );
        },
      },
      {
        name: 'amount',
        title: titles.Сумма,
        width: 100,
        sortField: 'amount',
        render: (val) => getAmount(val),
        numeric: true,
        clip: true,
      },
      {
        name: 'additional_info',
        title: <img src="/images/infoIcon.svg" alt="" title={titles.Дополнительная_информация} />,
        width: 'auto',
        render: (_val, _other, val) => <MeanStatus row={val} />,
      },
    ];

    if (window.config.central) {
      fields.unshift({
        name: 'parking_id',
        title: titles.Парковка,
        render: (val) => <ParkingCell parking_id={val} cmp={'span'} />,
        width: 'auto',
      });
    }

    return fields;
  };

  return { getFields, getRowClass, withComment: false };
};
