import React, { useEffect } from 'react';
import { reduxForm } from 'redux-form';
import styled from 'styled-components';
import moment from 'moment';

import { useTranslation } from 'react-i18next';

import { DateSelect } from 'libs/form';
import { ParkingFilter } from 'libs/form';
import { Container, Input, DoubleField, DateTime, ButtonsContainer } from 'libs/form';
import { UseBtn, ResetBtn } from 'libs/ui';

const StyledContainer = styled(Container)`
  form {
  }
`;

const initialValues = {
  datetime_from: moment().startOf('day').format(),
  datetime_to: moment().endOf('day').format(),
};

const Filter = ({ handleSubmit, reset, change, setInitial }) => {
  const { t } = useTranslation();

  useEffect(() => {
    setInitial(initialValues);
  }, [setInitial]);

  return (
    <StyledContainer>
      <form onSubmit={handleSubmit}>
        <DateSelect from_name="datetime_from" to_name="datetime_to" change={change}>
          <DoubleField>
            <DateTime width="160px" name="datetime_from" type="dateTime" label={t('grid.Дата и время')} />
            <DateTime width="160px" name="datetime_to" type="dateTime" />
          </DoubleField>
        </DateSelect>

        <Input
          width="150px"
          name="vehicle_plate"
          type="text"
          label={t('sections.ГРЗ')}
          placeholder={t('form.А000AA 000 rus')}
        />
        <Input width="220px" name="mean_id" type="text" label={t('buttons.Билет')} placeholder={t('buttons.Билет')} />

        <ParkingFilter />

        <ButtonsContainer>
          <ResetBtn onClick={reset} />
          <UseBtn />
        </ButtonsContainer>
      </form>
    </StyledContainer>
  );
};

export default reduxForm({
  form: 'benefitFilter',
  destroyOnUnmount: false,
  initialValues: initialValues,
})(Filter);
