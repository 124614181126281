import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { data } from 'services';
import { routes } from 'router';
import { getAmount } from 'libs/common';
import { GridRow, ImgViewer, Cell, OptionsCell } from 'libs/ui';
import { getParking, getDateTime } from 'services/types';
import { ClosePicturesButton, ImageWrap, PictureRow } from './Row.styled';

const Operation = (props) => {
  const { t } = useTranslation();

  const [showPhotos, setShowPhotos] = useState(false);
  const history = useHistory();

  const showCard = () => {
    history.push(`${routes.stay}?id=${props.stay_id}`);
  };

  const togglePhotos = () => {
    setShowPhotos((prev) => !prev);
  };

  const getAppNumber = () => {
    return props.apps[props.payment_terminal] || data.getRacksMap()[props.payment_terminal] || props.payment_terminal;
  };

  const getPhoto = (photo) => {
    if (props.data && props.data.photos) {
      return props.data.photos[photo];
    }
  };

  const actions = [];
  if (props.stay_id) {
    actions.push({ text: t('buttons.Перейти в карточку парковки'), func: showCard });
  }

  const parking = getParking(props.parking_id);

  return (
    <>
      <GridRow onClick={togglePhotos}>
        {window.config.central && <Cell>{parking}</Cell>}
        <Cell>{getDateTime(props.timestamp)}</Cell>
        <Cell>{data.getDictValue('payment.payment_type', props.payment_type)}</Cell>
        <Cell>{data.getDictValue('payment.success', props.success)}</Cell>
        <Cell number>{getAmount(props.amount)}</Cell>
        <Cell number>{getAmount(props.amount_in)}</Cell>
        <Cell number>{getAmount(props.amount_out)}</Cell>
        <Cell number>{getAmount(props.shortage)}</Cell>
        <Cell>{data.getDictValue('payment.mean_type', props.mean_type)}</Cell>
        <Cell>{props.mean_number}</Cell>
        <Cell>{getAppNumber()}</Cell>
        <Cell number>{props.check_number}</Cell>
        <Cell number>{props.shift}</Cell>
        <OptionsCell options={actions} left={-61} />
      </GridRow>
      {showPhotos && (
        <PictureRow>
          <ImageWrap>
            <ImgViewer images={[getPhoto('photo_1')]} width={'432px'} height={'300px'} />
            <ImgViewer images={[props.photo]} width={'432px'} height={'300px'} />
            <ImgViewer images={[getPhoto('photo_3')]} width={'432px'} height={'300px'} />
            <ClosePicturesButton onClick={togglePhotos}>
              <i className="fa fa-arrow-up"></i> {t('buttons.Скрыть')}
            </ClosePicturesButton>
          </ImageWrap>
        </PictureRow>
      )}
    </>
  );
};

export default Operation;
