import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';

import { user } from 'services';
import { ClientData, PersonalDataHeader } from '../styledComponents';
import NaturalPersonForm from './forms/NaturalPerson';
import LegalPersonForm from './forms/LegalPerson';
import SoleProprietorForm from './forms/SoleProprietor';
import { LoginInfo, UpdateMessageBox } from './PersonalData.styled';
import { useTranslation } from 'react-i18next';

const PersonalData = ({ client_id, clientType, updateClientInfo, submitText, login, showMessageAfterAddClient }) => {
  const [showUpdateMessage, setShowUpdateMessage] = useState(false);

  const { t } = useTranslation();

  const submit = useCallback(
    async (values) => {
      const rightUpdate = user.haveRight('clients.update');
      if (!rightUpdate) {
        return Promise.resolve();
      }

      const data = {
        id: client_id,
        info: { ...values },
      };

      try {
        await updateClientInfo(data);
        showMessage();
      } catch (error) {
        const submissionErrors = {};
        if (Array.isArray(error)) {
          error.forEach((element) => {
            submissionErrors[element.field] = element.type;
          });
        } else {
          submissionErrors['_error'] = error;
        }
        throw new SubmissionError(submissionErrors);
      }
    },
    [client_id, updateClientInfo]
  );

  const showMessage = () => {
    setShowUpdateMessage(true);
    setTimeout(() => {
      setShowUpdateMessage(false);
    }, 3000);
  };

  const getForm = useCallback(() => {
    switch (clientType) {
      case 'natural_person':
        return <NaturalPersonForm submitText={submitText} onSubmit={submit} />;
      case 'legal_person':
        return <LegalPersonForm submitText={submitText} onSubmit={submit} />;
      case 'sole_proprietor':
        return <SoleProprietorForm submitText={submitText} onSubmit={submit} />;
      default:
        return null;
    }
  }, [clientType, submit, submitText]);

  return (
    <ClientData>
      <PersonalDataHeader>{t('form.Персональные данные')}</PersonalDataHeader>
      {login && (
        <LoginInfo>
          <b>{t('form.Логин ЛК')}</b>
          <i>{login}</i>
        </LoginInfo>
      )}
      {getForm()}
      {showUpdateMessage && <UpdateMessageBox>{t('grid.Изменения сохранены')}</UpdateMessageBox>}
      {showMessageAfterAddClient && <UpdateMessageBox>{t('messages.Клиент успешно создан')}</UpdateMessageBox>}
    </ClientData>
  );
};

const mapStateToProps = (store) => {
  let { data } = store.client.clientInfo;
  return {
    login: data ? data.lk_login : '',
  };
};

export default connect(mapStateToProps)(PersonalData);
