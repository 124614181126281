import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { data } from 'services';
import { routes } from 'router';
import { ImgViewer, OptionsCell, Cell, GridRow, ZoneIcon } from 'libs/ui';
import { getShortRFID, getParking, getDateTime } from 'services/types';
import { ClosePicturesButton, ImageWrap, PictureRow } from './Row.styled';

const Ride = (props) => {
  const [showPhotos, setShowPhotos] = useState(false);
  const history = useHistory();

  const { t } = useTranslation();

  const togglePhotos = () => {
    setShowPhotos((prev) => !prev);
  };

  const showCard = () => {
    history.push(`${routes.stay}?id=${props.stay_id}`);
  };

  const getZonesIcons = (type) => {
    const zones = props.zones.getZones();
    const rideZone = type === 'from' ? props.zone_from : props.zone_to;

    const zone = zones.find((zone) => zone.id === rideZone);
    return zone ? <ZoneIcon color={zone.color}>{zone.name}</ZoneIcon> : null;
  };

  const getPhoto = (photo) => {
    return props.data && props.data.photos ? props.data.photos[photo] : null;
  };

  const getRack = (nodeId, rackId) => {
    const node = getDecorateName('node', nodeId);
    const rack = getDecorateName('rack', rackId);
    return `${node} ${rack}`;
  };

  const getDecorateName = (type, id) => {
    if (id) {
      const prefix = type === 'node' ? 'У' : 'C';
      return `${prefix}${id < 10 ? `0${id}` : id}`;
    }
    return '-';
  };

  const getOptionsCell = () => {
    const options = [];
    if (props.stay_id && props.stay_id !== 'system_30_07_2018_rfids') {
      options.push({ text: t('buttons.Перейти в карточку парковки'), func: showCard });
    }
    return <OptionsCell left={-61} options={options} />;
  };

  const {
    type,
    mean_type,
    mean_number,
    parking_id,
    is_suspicious,
    timestamp,
    node_id,
    rack_id,
    vehicle_class,
    vehicle_plate,
    csc_owner,
    result,
    client_name,
  } = props;

  const parking = getParking(parking_id);
  const mean = mean_type === 'rfid' ? getShortRFID(mean_number) : mean_number;

  return (
    <>
      <GridRow $is_suspicious={is_suspicious} onClick={togglePhotos}>
        {window.config.central && <Cell pr10>{parking}</Cell>}
        <Cell pr10>{getDateTime(timestamp)}</Cell>
        <Cell pr10>{vehicle_plate}</Cell>
        <Cell pr10>{data.getDictValue('passage.type', type)}</Cell>
        <Cell pr10>{data.getDictValue('passage.result', result)}</Cell>
        <Cell pr10>{getZonesIcons('from')}</Cell>
        <Cell pr10>{getRack(node_id, rack_id)}</Cell>
        <Cell pr10>{getZonesIcons('to')}</Cell>
        <Cell pr10>{vehicle_class}</Cell>
        <Cell pr10>{data.getDictValue('payment_mean_type', mean_type)}</Cell>
        <Cell pr10>{mean}</Cell>
        <Cell>{csc_owner}</Cell>
        <Cell>{client_name}</Cell>
        {getOptionsCell()}
      </GridRow>
      {showPhotos && (
        <PictureRow>
          <ImageWrap>
            <ImgViewer images={[getPhoto('photo_1')]} width={'432px'} height={'300px'} />
            <ImgViewer images={[getPhoto('photo_2')]} width={'432px'} height={'300px'} />
            <ImgViewer images={[getPhoto('photo_3')]} width={'432px'} height={'300px'} />
            <ClosePicturesButton onClick={togglePhotos}>
              <i className="fa fa-arrow-up" />
              {t('buttons.Скрыть')}
            </ClosePicturesButton>
          </ImageWrap>
        </PictureRow>
      )}
    </>
  );
};

export default Ride;
