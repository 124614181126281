import React from 'react';
import PropTypes from 'prop-types';

//import { getRacks } from 'services/types';
import { Place } from './parkomats-select.styled';
import { useTranslation } from 'react-i18next';

export const ParkomatsSelect = (props) => {
  const { value, onChange, parkingId, racks, label } = props;

  const { t } = useTranslation();

  /**
   * get parkomats list
   * @returns [option]
   */
  const getParkomats = () => {
    return racks
      .filter((el) => {
        if (window.config.central && !parkingId) {
          return el.parking_id === value;
        }
        return true;
      })
      .map((rack) => {
        return (
          <option key={rack.id} value={rack.id}>
            {rack.mapped_name}
          </option>
        );
      });
  };

  /**
   * change
   * @param {*} e
   */
  const change = (e) => {
    onChange(e.target.value);
  };

  const parkomats = getParkomats();
  const title = label ? label : t('form.Устройство');

  return (
    <Place>
      <label htmlFor="parkomat-select">{title}</label>
      <select id="parkomat-select" onChange={change} width="145px" value={value}>
        {parkomats}
      </select>
    </Place>
  );
};

ParkomatsSelect.propTypes = {
  parkingId: PropTypes.any,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.any.isRequired,
  racks: PropTypes.any.isRequired,
  label: PropTypes.any,
};
