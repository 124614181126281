import { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { routes } from 'router';
import { user } from 'services';
import { TableExt, TableInfo, GridBox, GridUpdateMessage } from 'libs/ui';
import { setTypeOfNewClient } from 'store/ducks/models/client';
import { getTableParams } from './grid.params';
import { AddClientAction } from './actions/add-client-action';

export const ClientsGrid = (props) => {
  const { count, entries, className, theme, getXlsAction, updateEntry } = props;
  //const interface1 = props.interface;
  const [message, setMessage] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  /**
   * go to client card
   * @param {*} row
   */
  const editItemAction = (row) => {
    history.push(routes.client + '?id=' + row.id);
  };

  /**
   * show new client form
   * @param {*} type
   */
  const showNewClientPage = (type) => {
    dispatch(setTypeOfNewClient(type));
    history.push(routes.newClient);
  };

  // actions
  const rightShow = user.haveRight('clients.view');

  // items
  const items = Object.keys(entries).map((key) => entries[key]);

  const actions = [];

  // top actions
  const topActions = [];
  if (user.haveRight('clients.add')) {
    topActions.push({
      cmp: (
        <AddClientAction
          options={[
            { text: t('form.Физ. лицо'), func: () => showNewClientPage('natural_person') },
            { text: t('form.ИП'), func: () => showNewClientPage('sole_proprietor') },
            { text: t('form.Юр. лицо'), func: () => showNewClientPage('legal_person') },
          ]}
        />
      ),
    });
  }
  if (count) {
    getXlsAction(topActions);
  }

  const tableParams = useMemo(() => getTableParams(t), []);

  return (
    <GridBox>
      <TableInfo theme={theme} count={count} actions={topActions} />

      <TableExt
        updateEntry={updateEntry}
        theme={theme}
        title={t('sections.Клиенты')}
        rows={{ count, items }}
        actions={actions}
        className={className}
        refresh={props.refresh}
        onRowClick={rightShow ? editItemAction : undefined}
        {...tableParams}
      />

      {message && <GridUpdateMessage>{message}</GridUpdateMessage>}
    </GridBox>
  );
};
