import React, { useMemo } from 'react';

import { TableHeader } from 'libs/ui';
import Row from './Row';
import { GridBox, Grid, TableHead, TableHeadRow, TopRow } from 'libs/ui';
import { Total } from 'libs/ui';

import { useTranslation } from 'react-i18next';

const BenefitsGrid = ({ entries, rides, count, getXlsLink, zones }) => {
  const { t } = useTranslation();

  const benefitsList = useMemo(() => {
    const benefits = entries || rides;
    let list = [];
    if (benefits) {
      for (let key in benefits) {
        list.push(<Row zones={zones} key={benefits[key].id} {...benefits[key]} />);
      }
    }
    return list;
  }, [entries, rides, zones]);

  return (
    <GridBox>
      <TopRow>
        <Total cnt={count} />
        {getXlsLink()}
      </TopRow>
      <Grid>
        <TableHead>
          <TableHeadRow>
            {window.config.central && <TableHeader width="90px">{t('grid.Парковка')}</TableHeader>}
            <TableHeader width="220px" sortField="timestamp">
              {t('grid.Дата и время')}
            </TableHeader>
            <TableHeader>{t('sections.ГРЗ')}</TableHeader>
            <TableHeader width="140px">{t('buttons.Билет')}</TableHeader>
            <TableHeader width="80px"></TableHeader>
          </TableHeadRow>
        </TableHead>
        <tbody>{benefitsList}</tbody>
      </Grid>
    </GridBox>
  );
};

export default BenefitsGrid;
