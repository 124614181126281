import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, change, getFormAsyncErrors, clearAsyncError } from 'redux-form';
import { withTranslation } from 'react-i18next';

import { api } from 'api';
import { Button, themes } from 'libs/ui';
import { getCategoriesList, getVehicleClassesList, getOptionsList } from 'services/types';
import { ParkingFilter2, required, rfid, WhiteStyledField } from 'libs/form';
import Warning from 'modules/components/warnings';

import { StyledButtonContainer, TextareaStyledField } from './Form.styled';

const asyncValidate = (values) => {
  const val = values.rfid;
  if (window.rfidFormEdit) {
    return Promise.resolve();
  }
  const flt = { rfid: val };
  if (values.parking_id) {
    flt.parking_id = values.parking_id;
  }

  return api.call('rfid.list', { filter_params: flt, sort_params: [], paging_params: { limit: 1 } }).then((data) => {
    if (data.items && data.items.length) {
      const item = data.items[0];
      window.rfid = item;
      if (item.client_id == window.clientClientId) {
        throw { rfid: 'Метка уже добавлена' };
      } else {
        throw { rfid: 'Метка с таким номером привязана к другому клиенту' };
      }
    }
  });
};

class ClientRfidForm extends React.Component {
  state = {
    tariffs: [],
    ready: false,
    rfidError: 0,
    warn: true,
    edit1: false,
  };

  componentDidMount() {
    window.clientClientId = this.props.clientId;
    api.call('rfid.tariff_list', { filter_params: {}, sort_params: [], paging_params: {} }).then((data) => {
      const out = data.map((el) => {
        return { id: el.id, name: el.name };
      });
      this.setState({ tariffs: out, ready: true });
    });
  }

  componentDidUpdate(prevProps) {
    window.clientClientId = this.props.clientId;
    if (this.state.edit1) {
      return;
    }
    if (this.props.type !== 'editForm') {
      if (this.props.transportClass !== prevProps.transportClass || this.props.transportCategory !== prevProps.transportCategory) {
        this.props.dispatch(change(this.props.form, 'tariff_id', ''));
      }
    } else {
      if (
        prevProps.transportClass &&
        prevProps.transportCategory &&
        (this.props.transportClass !== prevProps.transportClass || this.props.transportCategory !== prevProps.transportCategory)
      ) {
        //console.log('did update', this.props.transportClass, prevProps.transportClass, this.props.transportCategory, prevProps.transportCategory)
        this.props.dispatch(change(this.props.form, 'tariff_id', ''));
      }
    }
  }

  getRequestParams = () => {
    const { transportClass, transportCategory } = this.props;
    if (transportClass && transportCategory) {
      const filterParams = {
        vehicle_class: [Number(transportClass)],
        vehicle_category: [transportCategory],
        type: 'individual',
        status: 'active',
      };
      const sortParams = [{ field_name: 'id', direction: 'asc' }];

      return {
        filterParams: filterParams,
        sortParams: sortParams,
      };
    }
  };

  getInputStatus = () => {
    return this.props.type === 'editForm';
  };

  onSelectItem = (data) => {
    this.props.setTariff(data);
  };

  rfidTest = (val) => {
    return undefined;
  };

  hideWarn = () => {
    this.props.dispatch(change(this.props.form, 'rfid', ''));
    this.props.dispatch(clearAsyncError(this.props.form, 'rfid'));
  };

  changeRfid = () => {
    window.rfidFormEdit = true;

    this.setState({ edit1: true });
    this.props.dispatch(change(this.props.form, 'id1', window.rfid.id));
    this.props.dispatch(change(this.props.form, 'mode', 'change'));
    this.props.dispatch(change(this.props.form, 'owner', window.rfid.owner));
    this.props.dispatch(change(this.props.form, 'vehicle_plate', window.rfid.vehicle_plate));
    this.props.dispatch(change(this.props.form, 'vehicle_category', window.rfid.vehicle_category));
    this.props.dispatch(change(this.props.form, 'vehicle_class', window.rfid.vehicle_class));
    this.props.dispatch(change(this.props.form, 'tariff_id', window.rfid.tariff_id));
  };

  render() {
    const {
      handleSubmit,
      transportClass,
      transportCategory,
      parkingId,
      //getList,
      //setTariffList,
      removeFormInfo,
      errors1,
      type,
      formAsyncErrors,
      t,
    } = this.props;
    const { edit1, ready, warn, rfidError, tariffs } = this.state;

    if (!ready) {
      return null;
    }

    const changer = warn && formAsyncErrors && formAsyncErrors.rfid && formAsyncErrors.rfid === 'Метка с таким номером привязана к другому клиенту';

    //const { error } = rest;
    const rfidError1 = {};
    if (rfidError === 1) {
      rfidError1['rfid'] = 'Метка уже добавлена';
    }
    const error = { ...errors1, ...rfidError1 };
    const edit = type === 'editForm' || edit1;
    window.rfidFormEdit = edit;
    const disabledRfid = edit || (window.config.central && !parkingId);

    return (
      <>
        <form onSubmit={handleSubmit}>
          <input type="hidden" name="mode" />
          <input type="hidden" name="id1" />
          <input type="hidden" name="client_id1" />
          {!edit && <ParkingFilter2 withEmpty required />}
          {!edit && (
            <WhiteStyledField
              validate={[required, rfid, this.rfidTest]}
              placeholder="78497503480098"
              mask="999999"
              error={error}
              type="text"
              label={t('sections.RFID')}
              name="rfid"
              disabled={disabledRfid}
            />
          )}
          <WhiteStyledField
            validate={required(t)}
            placeholder={t('form.Иванов Иван Иванович')}
            type="text"
            error={error}
            label={t('form.ФИО водителя')}
            name="owner"
          />
          <WhiteStyledField validate={required(t)} type="select" label={t('dict_filter.Класс ТС')} error={error} name="vehicle_class">
            {!edit && <option value="">{t('form.Выберите класс ТС')}</option>}
            {getVehicleClassesList()}
          </WhiteStyledField>
          <WhiteStyledField validate={required(t)} type="select" label={t('filter.Категория СО')} error={error} name="vehicle_category">
            {!edit && <option value="">{t('form.Выберите категорию')}</option>}
            {getCategoriesList()}
          </WhiteStyledField>
          <WhiteStyledField
            validate={required(t)}
            placeholder={t('form.А000AA 000 rus')}
            type="text"
            label={t('sections.ГРЗ')}
            error={error}
            name="vehicle_plate"
          />
          <WhiteStyledField
            validate={required(t)}
            className="shortfield"
            type="select"
            label={t('form.Тариф')}
            name="tariff_id"
            error={error}
            disabled={!transportClass || !transportCategory}
          >
            {!edit && <option value="">{t('form.Выберите тариф')}</option>}
            {getOptionsList(tariffs)}
          </WhiteStyledField>
          {!edit && (
            <TextareaStyledField type="textarea" placeholder={t('filter.Напишите свой комментарий')} label={t('filter.Комментарий')} name="comment" />
          )}
          <StyledButtonContainer>
            <Button onClick={removeFormInfo}>{t('buttons.Отменить')}</Button>
            <Button theme={themes.blue}>
              <i className="fa fa-save" />
              {t('buttons.Сохранить')}
            </Button>
          </StyledButtonContainer>
        </form>
        {changer && (
          <Warning
            width="480px"
            header={t('messages.Метка с таким номером привязана к другому клиенту. Вы уверены, что хотите добавить метку к текущему клиенту?')}
            cancelButton={{
              func: this.hideWarn,
              text: t('buttons.Отменить'),
            }}
            acceptButton={{
              func: this.changeRfid,
              text: t('grid.Подтвердить'),
              theme: themes.blue,
            }}
          />
        )}
      </>
    );
  }
}

ClientRfidForm = withTranslation()(ClientRfidForm);

ClientRfidForm = reduxForm({
  form: 'ClientRfidForm',
  asyncValidate,
  asyncBlurFields: ['rfid'],
})(ClientRfidForm);

const selector = formValueSelector('ClientRfidForm');

ClientRfidForm = connect((state, props) => {
  const transportClass = selector(state, 'vehicle_class');
  const transportCategory = selector(state, 'vehicle_category');
  const parkingId = selector(state, 'parking_id');
  // console.log('🚀 ~ this.props.clientId:', props.clientId);
  const out = { ...props.data, mode: 'normal', client_id1: props.clientId, id1: props.data.id };
  return {
    transportClass,
    transportCategory,
    parkingId,
    formAsyncErrors: getFormAsyncErrors('ClientRfidForm')(state),
    initialValues: out,
  };
}, {})(ClientRfidForm);

export default ClientRfidForm;
