import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { user } from 'services';
import { Button, themes, ButtonContainer, ParkomatsSelect } from 'libs/ui';
import { Popup2 } from 'libs/ui';
import { ErrorMsg } from 'pages/styled';
import { PromiseContent, NewTicketHeader } from './app-pay.styled';
import { api } from 'api';
import { notify } from 'libs/common';
import { getPaymentRacks } from 'services/types';
import { useTranslation } from 'react-i18next';

export const AppPay = ({ id, parkingId, close }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [working, setWorking] = useState(false);
  const [validated, setValidated] = useState(false);
  const [parkingIdState, setParkingIdState] = useState(
    parkingId ? '' : user.getMaps().parking[0] ? user.getMaps().parking[0].id : ''
  );
  const [parkomat, setParkomat] = useState('');
  const [racks, setRacks] = useState([]);

  useEffect(() => {
    getPaymentRacks().then((data) => {
      if (data) {
        data.forEach((el) => (el.mapped_name = el.name));
        const def = data.length > 0 ? data[0].id : '';
        setRacks(data);
        setParkomat(def);
      }
    });
  }, []);

  const validate = (data) => {
    return !parkomat;
  };

  const save = () => {
    const out = {
      payment_rack_id: parkomat,
      stay_id: id,
    };

    api
      .call('stays.send_to_payment_rack', out, 'NetworkManager')
      .then((data) => {
        close();
      })
      .catch((error) => {
        notify(t('messages.Ошибка инициации оплаты'), error);
        setError(true);
      });
  };

  const handleParkomatChange = (selectedParkomat) => {
    setParkomat(selectedParkomat);
  };

  if (!racks.length) {
    return null;
  }

  return (
    <>
      <Popup2 w={400}>
        <PromiseContent>
          <NewTicketHeader>{t('grid.Оплата')}</NewTicketHeader>
          {error && (
            <ErrorMsg>
              <img src="/images/error-triangle-icon.png" alt="" />
              {t('messages.Ошибка')}
            </ErrorMsg>
          )}
          <form>
            <ParkomatsSelect racks={racks} onChange={handleParkomatChange} parkingId={parkingId} value={parkomat} />
          </form>
          <ButtonContainer space="20px" justifyContent={'flex-start'} style={{ marginTop: '40px' }}>
            <Button disabled={working} fontSize="16px" onClick={close}>
              {t('buttons.Отменить')}
            </Button>
            <Button disabled={working} theme={themes.blue} fontSize="16px" onClick={save}>
              {t('buttons.Инициировать оплату')}
            </Button>
          </ButtonContainer>
        </PromiseContent>
      </Popup2>
    </>
  );
};

AppPay.propTypes = {
  id: PropTypes.any.isRequired,
  parkingId: PropTypes.any,
  close: PropTypes.func.isRequired,
  action: PropTypes.any.isRequired,
};
