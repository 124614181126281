import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { user } from 'services';
import { Button, GridRow, Cell, OptionsCell, ImgViewer } from 'libs/ui';
import { getDateTime, getParking } from 'services/types';
import { routes } from 'router';

const PictureRow = styled(GridRow)`
  height: 300px;
  width: 1300px;
  display: flex;
  justify-content: space-between;
`;

const ImageWrap = styled.td`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 !important;
`;

const ClosePicturesButton = styled(Button)`
  position: absolute;
  bottom: 15px;
  left: 46%;
`;

const Benefit = (props) => {
  const [showPhotos, setShowPhotos] = useState(false);
  const history = useHistory();

  const { t } = useTranslation();

  const showCard = () => {
    if (props.disableClick) return;
    history.push(`${routes.stay}?id=${props.stay_id}`);
  };

  const togglePhotos = () => {
    setShowPhotos((prev) => !prev);
  };

  const getOptionsCell = () => {
    const options = [];
    if (props.stay_id && props.stay_id !== 'system_30_07_2018_rfids' && user.haveRight('stays.view')) {
      options.push({ text: t('buttons.Перейти в карточку парковки'), func: showCard });
    }
    return <OptionsCell options={options} left={-61} />;
  };

  const parking = props.parking_id ? getParking(props.parking_id) : null;

  return (
    <>
      <GridRow onClick={togglePhotos}>
        {window.config.central && <Cell pr10>{parking}</Cell>}
        <Cell pr10>{getDateTime(props.created_at)}</Cell>
        <Cell pr10>{props.vehicle_plate}</Cell>
        <Cell>{props.mean_id}</Cell>
        {getOptionsCell()}
      </GridRow>
      {showPhotos && (
        <PictureRow>
          <ImageWrap>
            <ImgViewer images={[props.photo]} width={'432px'} height={'300px'} />
            <ClosePicturesButton onClick={togglePhotos}>
              <i className="fa fa-arrow-up" />
              {t('buttons.Скрыть')}
            </ClosePicturesButton>
          </ImageWrap>
        </PictureRow>
      )}
    </>
  );
};

export default Benefit;
