import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { user } from 'services';
import Rfid from './Row';
import RfidForm from './Window';
import Warning from 'modules/components/warnings';
import { Button, ButtonContainer, themes, Total, TableHeader, GridBox, Grid, TableHead, TableHeadRow, TopRow } from 'libs/ui';
import { getRfidTariffList } from 'store/ducks/models/tariffs';

const RfidTableHeader = styled(TableHeader)`
  color: #b7babf;
  opacity: 1;
  border-bottom: 1px solid #6c7277;
`;

const RfidGrid = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    entries,
    reset,
    setWarning,
    removeWarning,
    cardForm,
    getEntries,
    addComment,
    changeStatus,
    setFormInfo,
    removeFormInfo,
    vehicleClasses,
    count,
    clientId,
    epmCategories,
    className,
  } = props;

  const rightAdd = user.haveRight('rfid.add');

  useEffect(() => {
    dispatch(getRfidTariffList());
  }, [dispatch]);

  const getRfidList = () => {
    if (!entries) return [];

    return Object.values(entries).map((rfid) => (
      <Rfid
        key={rfid.id}
        type={props.type}
        reset={reset}
        setWarning={setWarning}
        removeWarning={removeWarning}
        cardForm={cardForm}
        getEntries={getEntries}
        addComment={addComment}
        changeStatus={changeStatus}
        setFormInfo={setFormInfo}
        removeFormInfo={removeFormInfo}
        vehicleClasses={vehicleClasses}
        {...rfid}
      />
    ));
  };

  const showForm = (type) => {
    setFormInfo({
      type: type,
      data: {
        rfid: '',
        owner: '',
        vehicle_class: '',
        vehicle_category: '',
        vehicle_plate: '',
        tariff_id: '',
        comment: '',
      },
    });
  };

  const getRfidForm = () => {
    if (cardForm && (cardForm.type === 'addForm' || cardForm.type === 'editForm')) {
      return (
        <RfidForm
          type={cardForm.type}
          id={cardForm.id}
          clientId={clientId}
          addRfid={props.addRfid}
          updateRfid={props.updateRfid}
          getEntriesCount={props.getEntriesCount}
          removeFormInfo={removeFormInfo}
          setFormData={props.setFormData}
          getEntries={getEntries}
          data={cardForm.data}
          epmCategories={epmCategories}
          vehicleClasses={vehicleClasses}
          getTariffList={() => dispatch(getRfidTariffList())}
        />
      );
    }
  };

  return (
    <GridBox>
      <TopRow>
        <Total white cnt={count} />
        <ButtonContainer>
          {rightAdd && (
            <Button theme={themes.blue} onClick={() => showForm('addForm')}>
              <i className="fa fa-plus" />
              {t('buttons.Добавить RFID')}
            </Button>
          )}
        </ButtonContainer>
      </TopRow>
      <Grid className={className}>
        <TableHead>
          <TableHeadRow>
            {window.config.central && <RfidTableHeader width="140px">{t('grid.Парковка')}</RfidTableHeader>}
            <RfidTableHeader width="100px">{t('sections.RFID')}</RfidTableHeader>
            <RfidTableHeader width="110px">{t('dict_filter.Статус')}</RfidTableHeader>
            <RfidTableHeader width="125px">{t('form.Водитель')}</RfidTableHeader>
            <RfidTableHeader width="215px">{t('form.Категория')}</RfidTableHeader>
            <RfidTableHeader width="165px">{t('dict_filter.Класс ТС')}</RfidTableHeader>
            <RfidTableHeader width="95px">{t('sections.ГРЗ')}</RfidTableHeader>
            <RfidTableHeader width="185px">{t('form.Тариф')}</RfidTableHeader>
            <RfidTableHeader>{t('filter.Комментарий')}</RfidTableHeader>
            <RfidTableHeader width="80px" />
          </TableHeadRow>
        </TableHead>
        <tbody>{getRfidList()}</tbody>
      </Grid>
      {getRfidForm()}
      {props.interface.warning && (
        <Warning
          width="540px"
          header={t('messages.Блокировка RFID метки')}
          mainText={`${t('messages.Блокированные RFID метки не дают право проезда на парковку. Вы уверены что хотите заблокировать RFID №')}${
            Warning.rfid
          }?`}
          cancelButton={{
            func: removeWarning,
            text: t('buttons.Отменить'),
          }}
          acceptButton={{
            func: () => {
              changeStatus(Warning.reqInfo);
              removeWarning();
            },
            text: t('buttons.Заблокировать RFID'),
            img: '/images/icon_lock.png',
            theme: themes.red,
          }}
        />
      )}
    </GridBox>
  );
};

export default RfidGrid;
