import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { HeadLineWithMargin, Card, GeneralInfo } from 'modules/components/card/styledComponents';
import PersonalData from './PersonalData';
import { PageWrapper } from 'modules/components/pageComponents';
import { CloseCardButton } from 'libs/ui';
import { ProfileInfo, ProfileInfoData, ClientData, Tabs, Tab, StyledDataWrapper } from '../styledComponents';
import { addClient, removeClientInfo } from 'store/ducks/models/client';
import { addClientMessageDisplayHandler } from 'store/ducks/interface/clientsCard';
import { routes } from 'router';

const DataTab = styled(Tab)`
  border-bottom: solid 4px #3ea5ff;
  font-weight: bold;
`;

const CreateClientCard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const clientType = useSelector((state) => state.client.typeOfNewClient);

  useEffect(() => {
    if (!clientType) {
      closeCard();
    }
  }, [clientType]);

  const closeCard = () => {
    history.push(routes.clients);
  };

  const openClientCard = (clientId) => {
    history.push(`${routes.client}?id=${clientId}`);
  };

  const getClientType = () => {
    if (clientType) {
      switch (clientType) {
        case 'natural_person':
          return t('form.Физическое лицо');
        case 'legal_person':
          return t('form.Юридическое лицо');
        case 'sole_proprietor':
          return t('form.Индивидуальный предприниматель');
        default:
          return '';
      }
    }
  };

  const getPersonalData = () => {
    if (clientType) {
      return (
        <PersonalData
          clientType={clientType}
          submitText={t('form.Создать карточку')}
          updateClientInfo={(params) => dispatch(addClient({ info: { ...params.info, type: clientType } }, afterSubmit))}
        />
      );
    }
  };

  const afterSubmit = (id) => {
    dispatch(addClientMessageDisplayHandler(true));
    openClientCard(id);
  };

  return (
    <PageWrapper>
      <HeadLineWithMargin>
        <p>{t('cards.Карточка клиента')}</p>
        <CloseCardButton close={closeCard} />
      </HeadLineWithMargin>
      <Card>
        <GeneralInfo>
          <ProfileInfo>
            <img src={`/images/${clientType}.png`} alt="" />
            <ProfileInfoData>
              <p>{getClientType()}</p>
            </ProfileInfoData>
          </ProfileInfo>
          <Tabs>
            <DataTab>{t('form.Данные')}</DataTab>
          </Tabs>
        </GeneralInfo>
        <StyledDataWrapper>
          <ClientData>{getPersonalData()}</ClientData>
        </StyledDataWrapper>
      </Card>
    </PageWrapper>
  );
};

export default CreateClientCard;
