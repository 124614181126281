import React from 'react';
import { connect } from 'react-redux';

import { TableHeader } from 'libs/ui';
import Row from './Row';
import { GridBox, TotalBox, Grid, TableHead, TableHeadRow, TopRow } from 'libs/ui';
import { Total } from 'libs/ui';
import { useTranslation } from 'react-i18next';

const OperationsGrid = (props) => {
  const { t } = useTranslation();

  const getOperationsList = () => {
    const appsArray = props.apps;
    const apps = {};
    appsArray.forEach((el) => (apps[el.id] = el.name));
    const operations = props.entries || props.operations;
    const operationsList = [];
    if (operations) {
      for (let key in operations) {
        operationsList.push(<Row apps={apps} key={operations[key].id} {...operations[key]} />);
      }
    }
    return operationsList;
  };

  const getTotalAmount = () => {
    let totalAmount = 0;
    const operations = props.entries;
    if (operations) {
      for (let key in operations) {
        if (operations[key].amount && typeof operations[key].amount === 'number') {
          totalAmount += operations[key].amount / 100;
        }
      }
    }
    return totalAmount;
  };

  let summ = props.summ;
  if (Number.isNaN(summ) || !summ) {
    summ = 0;
  } else {
    summ /= 100;
  }

  return (
    <GridBox>
      <TopRow>
        <TotalBox>
          <Total cnt={props.count} />
          <Total cnt={summ} title="sum" after=" ₽" />
        </TotalBox>
        {props.getXlsLink()}
      </TopRow>
      <Grid>
        <TableHead>
          <TableHeadRow>
            {window.config.central && <TableHeader width="140px">{t('grid.Парковка')}</TableHeader>}
            <TableHeader width="200px" sortField="timestamp">
              {t('grid.Дата и время')}
            </TableHeader>
            <TableHeader width="115px">{t('dict_filter.Способ оплаты')}</TableHeader>
            <TableHeader width="80px">{t('dict_filter.Результат')}</TableHeader>
            <TableHeader width="100px" sortField="amount">
              {t('grid.Сумма')}
            </TableHeader>
            <TableHeader width="100px" sortField="amount_in">
              {t('grid.Внесено')}
            </TableHeader>
            <TableHeader width="130px" sortField="amount_out">
              {t('grid.Выдано')}
            </TableHeader>
            <TableHeader width="130px" sortField="shortage">
              {t('grid.Недостача')}
            </TableHeader>
            <TableHeader width="150px">{t('dict_filter.Тип СО')}</TableHeader>
            <TableHeader>{t('grid.СО')}</TableHeader>
            <TableHeader width="130px">{t('grid.№ устройства')}</TableHeader>
            <TableHeader width="120px" number>
              {t('grid.Счет N')}
            </TableHeader>
            <TableHeader width="100px" number>
              {t('grid.Смена')}
            </TableHeader>
            <TableHeader width="80px">{t('grid.Действие')}</TableHeader>
          </TableHeadRow>
        </TableHead>
        <tbody>{getOperationsList()}</tbody>
      </Grid>
    </GridBox>
  );
};

const mapStateToProps = (state) => ({
  //summ: state.cashboxOperations.summ
});

export default connect(mapStateToProps)(OperationsGrid);
