import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';

import { user } from 'services';
import CommentForm from 'modules/components/windows/AddComment';
import Comments from 'modules/components/windows/Comments';
import { OptionsCell } from 'libs/ui';
import { addComment, setFormInfo1, removeFormInfo1 } from 'store/ducks/models/pay';

const types = {
  rfid: 'rfids',
  csc: 'epms',
  plates: 'vehiclePlates',
};

const getRealType = (type) => (types[type] ? types[type] : type);

const withComment = (WrappedComponent) => {
  const CommentsHOC = (props) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const formInfo = useSelector((store) => store[getRealType(props.type)]['formInfo']);
    const { parking_id, mean_id, id, status, comments, type, children, ...other } = props;

    const showForm = (type) => {
      dispatch(
        setFormInfo1(
          {
            parking_id,
            value: mean_id,
            id,
            status,
            type,
          },
          getRealType(type)
        )
      );
    };

    const getMenu = ({ parentGetMenu, parentGetForm, brdColor = '' }) => {
      const out = parentGetMenu ? parentGetMenu() : [];

      if (user.haveRight('comment.add')) {
        out.push({ text: t('grid.Оставить комментарий'), func: () => showForm('comment') });
      }
      if (user.haveRight('comment.list') && comments.length) {
        out.push({ text: t('grid.Посмотреть комментарии'), func: () => showForm('commentlist') });
      }

      const disabled = !out.length;

      return (
        <OptionsCell disabled={disabled} left={-62} options={out} brdColor={brdColor}>
          {getForm(parentGetForm)}
        </OptionsCell>
      );
    };

    const getForm = (parentGetForm) => {
      const out = parentGetForm ? parentGetForm() : null;
      if (out) {
        return out;
      }

      const realType = getRealType(type);
      if (formInfo && formInfo.id === id) {
        if (formInfo.type === 'comment') {
          return (
            <CommentForm type={realType} info={formInfo} addComment={addComment} removeFormInfo={removeFormInfo1} />
          );
        } else if (formInfo.type === 'commentlist') {
          return <Comments id={id} removeFormInfo={removeFormInfo1} type={realType} />;
        }
      }
    };

    return (
      <>
        <WrappedComponent {...other} getCommentsCell={getMenu} />
        {children}
      </>
    );
  };

  return CommentsHOC;
};

export default compose(
  connect(null, {
    addComment,
    setFormInfo1,
    removeFormInfo1,
  }),
  withComment
);
