import React, { Component } from 'react';

import { TableHeader } from 'libs/ui';
import Call from './Row';
import { GridBox, Grid, TableHead, TableHeadRow, TopRow } from 'libs/ui';
import { Total } from 'libs/ui';

import { useTranslation } from 'react-i18next';

const StaysGrid = ({ entries, calls, count, hideTotal, className }) => {
  const { t } = useTranslation();

  const getCallsList = () => {
    const calls = entries || calls;
    let callsList = [];

    if (calls) {
      let i = 0;
      for (let key in calls) {
        i++;
        const keyOk = calls[key].id + '-' + i;
        callsList.push(<Call key={keyOk} {...calls[key]} />);
      }
    }
    return callsList;
  };

  return (
    <GridBox>
      {!hideTotal && (
        <TopRow>
          <Total cnt={count} />
        </TopRow>
      )}
      <Grid className={className}>
        <TableHead>
          <TableHeadRow>
            <TableHeader width="110px">{t('dict_filter.Тип')}</TableHeader>
            <TableHeader width="95px">{t('grid.Статус')}</TableHeader>
            <TableHeader width="210px">{t('grid.Время')}</TableHeader>
            <TableHeader width="185px">{t('grid.Длительность')}</TableHeader>
            <TableHeader width="115px">{t('grid.Оборудование')}</TableHeader>
            <TableHeader>{t('grid.№ устройства')}</TableHeader>
            <TableHeader width="155px">{t('grid.Послушать/скачать')}</TableHeader>
          </TableHeadRow>
        </TableHead>
        <tbody>{getCallsList()}</tbody>
      </Grid>
    </GridBox>
  );
};

export default StaysGrid;
