import React, { useState } from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import { Button, themes, ButtonContainer } from 'libs/ui';
import { Popup2 } from 'libs/ui';
import { useTranslation } from 'react-i18next';

const NewTicketContent = styled.div`
  margin-left: 120px;
`;
const NewTicketHeader = styled.p`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`;
const NewTicketInfo = styled.p`
  font-size: 15px;
  margin-bottom: 10px;
`;
const AttentionIcon = styled.img`
  width: 50px;
  height: 46px;
  position: absolute;
  left: 30px;
  top: 60px;
`;
const StyledForm = styled.form`
  border-top: 1px solid ${(props) => (props.$invalid ? '#d33d34' : '#cccccc')};
  border-bottom: 1px solid ${(props) => (props.$invalid ? '#d33d34' : '#cccccc')};
  width: 400px;
  height: 60px;
  margin-left: -100px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    width: 101px;
    font-size: 13px;
    color: #3e3e3e;
  }
  input {
    border: none;
    margin-right: 70px;
  }
`;

const Invalid = (props) => {
  const { t } = useTranslation();
  const [number, setNumber] = useState('');
  const [invalid, setInvalid] = useState(false);

  const setHandicapped = () => {
    if (number) {
      props.setHandicapped(number);
      props.close();
    } else {
      setInvalid(true);
    }
  };

  return (
    <Popup2 w={440}>
      <AttentionIcon src="/images/icon_!.png" alt="" />
      <NewTicketContent>
        <NewTicketHeader>{t('form.Признак инвалид')}</NewTicketHeader>
        <NewTicketInfo style={{ marginBottom: '20px' }}>
          {t('form.Внесите номер удостоверения об инвалидности и нажмите «Сохранить»')}
        </NewTicketInfo>
        <StyledForm $invalid={invalid}>
          <p>{t('form.Номер удостоверения')}</p>
          <input onChange={(e) => setNumber(e.target.value)} value={number} placeholder="Введите номер" />
        </StyledForm>
        <ButtonContainer space="20px" justifyContent={'flex-start'}>
          <Button fontSize="16px" onClick={props.close}>
            {t('buttons.Отменить')}
          </Button>
          <Button theme={themes.blue} fontSize="16px" onClick={setHandicapped}>
            {t('buttons.Сохранить')}
          </Button>
        </ButtonContainer>
      </NewTicketContent>
    </Popup2>
  );
};

export default withRouter(Invalid);
