import { connect } from 'react-redux';

import { prepareFilter } from 'libs/common';
import zones from 'services/zones';
import { GridContainer } from 'libs/ui';
import RidesFilter from './Filter';
import RidesGrid from './Grid';
import { Controller } from 'libs/control';
import { getRequestParams, addRides, clearEntries, setSortParams, setOffset, setCount, reset } from 'store/ducks/models/rides';
import { setScroll } from 'store/ducks/interface/staysGrid';

export const Rides = (props) => {
  /**
   * prepare params
   * @param {*} data
   * @returns
   */
  const prepareParams = (data) => {
    return prepareFilter(props.getRequestParams(data));
  };

  return (
    <GridContainer>
      <Controller
        type="passage"
        gridFilter={RidesFilter}
        grid={RidesGrid}
        zones={zones}
        entries={props.rides}
        offset={props.offset}
        interface={props.interface}
        count={props.count}
        sortParams={props.sortParams}
        locationState={props.location.state}
        //methods
        getRequestParams={prepareParams}
        addEntries={props.addRides}
        clearEntries={props.clearEntries}
        setOffset={props.setOffset}
        setSortParams={props.setSortParams}
        setCount={props.setCount}
        setScroll={props.setScroll}
      />
    </GridContainer>
  );
};

const mapStateToProps = (store) => {
  return {
    rides: store.rides.rides,
    offset: store.rides.offset,
    count: store.rides.count,
    sortParams: store.rides.sortParams,
    interface: store.ridesGrid,
  };
};

export default connect(mapStateToProps, {
  getRequestParams,
  addRides,
  clearEntries,
  setScroll,
  setSortParams,
  setOffset,
  setCount,
  reset,
})(Rides);
