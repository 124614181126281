import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { user } from 'services';
import { UpdateMessage, GradientButton, themes, CloseCardButton } from 'libs/ui';
import Warning from 'modules/components/warnings';
import { PageWrapper, Headline } from 'modules/components/pageComponents';
import { StyledGrid, Card, GeneralInfo } from 'modules/components/card/styledComponents';
import PersonalData from './PersonalData';
import ContactFace from './ContactFace';
import RfidWrapper from './rfid/Wrapper';
import SubscriptionWrapper from './subscription';
import EpmWrapper from './epm';
import PlatesWrapper from './plates';
import { ZonesLimit } from './zones-limit/zones-limit';
import { ZonesLK } from './ZonesLK';
import ClientsGrid from '../grid/Grid';
import {
  ContactFaces,
  ContactFaceWrapper,
  Name,
  Type,
  Amount,
  DataTab,
  RfidTab,
  PlatesTab,
  SubscriptionTab,
  EpmTab,
  ZonesTab,
  ZonesLKTab,
  ProfileInfo,
  ProfileInfoData,
  ClientData,
  Tabs,
  StyledDataWrapper,
  ToggleBox,
  Toggle,
} from '../styledComponents';
import { routes } from 'router';

const StyledClientsGrid = styled(StyledGrid).attrs({ as: ClientsGrid })``;

const UpdateMessageBox = styled(UpdateMessage)`
  top: 480px;
  left: 33%;
`;
const AddContact = styled.span`
	cursor: pointer;
	position: absolute;
	left: : 0;
	bottom: 23px;
	font-size: 15px;
	color: #3ea5ff;
	i {
		margin-right: 5px;
	}
`;

const ClientCard = (props) => {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState('data');
  const [showWarning, setShowWarning] = useState(false);
  const [showUpdateMessage, setShowUpdateMessage] = useState(false);

  useEffect(() => {
    if (props.interface.showMessageAfterAddClient) {
      removeMessageAfterAdd();
    }
  }, [props.interface.showMessageAfterAddClient]);

  const removeMessageAfterAdd = () => {
    setTimeout(() => {
      props.addClientMessageDisplayHandler(false);
    }, 3000);
  };

  const haveContacts = (contacts) => {
    return contacts && contacts.length === 0;
  };

  const clientIsActive = (status) => {
    return status === 'active';
  };

  const closeCard = () => {
    props.history.push(routes.clients);
  };

  const toggleStatus = () => {
    if (clientIsActive(props.clientInfo.status)) {
      setShowWarning(true);
    } else {
      changeClientStatus();
    }
  };

  const changeClientStatus = () => {
    const params = {
      id: props.clientInfo.id,
      status: clientIsActive(props.clientInfo.status) ? 'blocked' : 'active',
    };
    props.changeClientStatus(params, afterChangeStatus);
  };

  const switchTab = (tab) => {
    setSelectedTab(tab);
  };

  const closeWarning = () => {
    setShowWarning(false);
  };

  const afterChangeStatus = () => {
    closeWarning();
    showMessage();
  };

  const getFaceContacts = () => {
    const { contact } = props.clientInfo;
    return contact ? (
      <ContactFace
        showMessageAfterAddContact={props.interface.showMessageAfterAddContact}
        addContactMessageDisplayHandler={props.addContactMessageDisplayHandler}
        key={contact.id}
        info={contact}
        updateClientContact={props.updateClientContact}
        getClientInfo={props.getClientInfo}
      />
    ) : null;
  };

  const showMessage = () => {
    setShowUpdateMessage(true);
    setTimeout(() => {
      setShowUpdateMessage(false);
    }, 3000);
  };

  const getUpdateText = () => {
    const status = props.clientInfo.status;
    return clientIsActive(status) ? t('messages.Клиент успешно разблокирован') : t('messages.Клиент успешно заблокирован');
  };

  const getPersonalData = () => {
    const clientType = props.clientInfo.type;
    const showMessageAfterAddClient = props.interface.showMessageAfterAddClient;

    return (
      <PersonalData
        showMessageAfterAddClient={showMessageAfterAddClient}
        clientType={clientType}
        client_id={props.clientInfo.id}
        updateClientInfo={props.updateClientInfo}
      />
    );
  };

  const getClientType = () => {
    const clientType = props.clientInfo.type;
    switch (clientType) {
      case 'natural_person':
        return t('form.Физическое лицо');
      case 'legal_person':
        return t('form.Юридическое лицо');
      case 'sole_proprietor':
        return t('form.Индивидуальный предприниматель');
      default:
        return '';
    }
  };

  const toClientStays = () => {
    props.history.push(routes.stays, {
      filter: {
        client_name: props.clientInfo.name,
      },
    });
  };

  const { balanceInfo, clientInfo } = props;
  const rights = {
    changeStatus: user.haveRight('clients.change_status'),
    update: user.haveRight('clients.update'),
    rfidList: user.haveRight('rfid.list'),
    zonesLimit: user.haveRight('clients.limits'),
    zonesLK: user.haveRight('clients.zones'),
    staysList: user.haveRight('stays.list'),
    epmList: user.haveRight('csc.list'),
    plateList: user.haveRight('plates.list'),
    subscriptionList: user.haveRight('subscriptions.list'),
  };
  return (
    <PageWrapper>
      <Headline>
        <p>{t('cards.Карточка клиента')}</p>
        <CloseCardButton close={closeCard} />
      </Headline>
      {props.clientInfo ? (
        <>
          <StyledClientsGrid hideTotal clients={[props.clientInfo]} />
          <Card>
            <GeneralInfo>
              <ProfileInfo>
                <img width={100} src={`/images/${clientInfo.type}.png`} alt="" />
                <ProfileInfoData>
                  <Name>{clientInfo.name}</Name>
                  <Type>{getClientType()}</Type>
                  <div>
                    {balanceInfo && (balanceInfo.balance || balanceInfo.balance === 0) ? (
                      <Amount>{`${balanceInfo.balance / 100} ${t('grid.₽')}`}</Amount>
                    ) : null}
                    <Amount>
                      {t('form.Номер счета')}: {clientInfo.account_id || t('form.Не известно')}
                    </Amount>
                    {rights.staysList && (
                      <GradientButton theme={themes.darkGradient} onClick={toClientStays}>
                        {t('buttons.Перейти в парковки')}
                      </GradientButton>
                    )}
                  </div>
                </ProfileInfoData>
              </ProfileInfo>
              <Tabs>
                <DataTab active={selectedTab} onClick={() => switchTab('data')}>
                  {t('form.Данные')}
                </DataTab>
                {rights.rfidList && (
                  <RfidTab active={selectedTab} onClick={() => switchTab('rfid')}>
                    {t('form.RFID метки')}
                  </RfidTab>
                )}
                {rights.epmList && (
                  <EpmTab active={selectedTab} onClick={() => switchTab('epm')}>
                    {t('sections.БСК')}
                  </EpmTab>
                )}
                {rights.plateList && (
                  <PlatesTab active={selectedTab} onClick={() => switchTab('plates')}>
                    {t('sections.ГРЗ')}
                  </PlatesTab>
                )}
                {rights.subscriptionList && (
                  <SubscriptionTab active={selectedTab} onClick={() => switchTab('subscription')}>
                    {t('sections.Абонементы')}
                  </SubscriptionTab>
                )}
                {rights.zonesLimit && (
                  <ZonesTab active={selectedTab} onClick={() => switchTab('zoneslimit')}>
                    {t('sections.Лимит ТС')}
                  </ZonesTab>
                )}
                {rights.zonesLK && (
                  <ZonesLKTab active={selectedTab} onClick={() => switchTab('zoneslk')}>
                    {t('sections.Зоны ЛК')}
                  </ZonesLKTab>
                )}
              </Tabs>
              <ToggleBox isActive={clientIsActive(clientInfo.status)}>
                <p>{t('grid.Активен')}</p>
                <Toggle
                  disabled={!rights.changeStatus}
                  isActive={clientIsActive(clientInfo.status)}
                  onClick={rights.changeStatus ? toggleStatus : () => false}
                >
                  <div></div>
                </Toggle>
                <p>{t('grid.Блокирован')}</p>
              </ToggleBox>
            </GeneralInfo>
            <StyledDataWrapper selectedTab={selectedTab}>
              {selectedTab === 'data' && (
                <>
                  <div style={{ marginBottom: '1rem' }}>
                    <ClientData>{getPersonalData()}</ClientData>
                  </div>
                  <ContactFaceWrapper>
                    <ContactFaces>
                      {!clientInfo.contact && rights.update ? (
                        <AddContact onClick={props.showNewContactForm}>
                          <i className="fa fa-plus" />
                          {t('buttons.Добавить контакт')}
                        </AddContact>
                      ) : null}
                      {props.interface.showNewContactForm && (
                        <ContactFace
                          addContactMessageDisplayHandler={props.addContactMessageDisplayHandler}
                          info={props.newContactFace}
                          client_id={clientInfo.id}
                          addClientContact={props.addClientContact}
                          hideNewContactForm={props.hideNewContactForm}
                        />
                      )}
                      {getFaceContacts()}
                    </ContactFaces>
                  </ContactFaceWrapper>
                </>
              )}
              {selectedTab === 'rfid' && <RfidWrapper clientId={clientInfo.id} {...props} />}
              {selectedTab === 'epm' && <EpmWrapper clientId={clientInfo.id} {...props} />}
              {selectedTab === 'plates' && <PlatesWrapper clientId={clientInfo.id} {...props} />}
              {selectedTab === 'subscription' && <SubscriptionWrapper clientId={clientInfo.id} {...props} />}
              {selectedTab === 'zoneslimit' && <ZonesLimit clientId={clientInfo.id} />}
              {selectedTab === 'zoneslk' && <ZonesLK clientId={clientInfo.id} />}
            </StyledDataWrapper>
          </Card>
          {showUpdateMessage && <UpdateMessageBox>{getUpdateText()}</UpdateMessageBox>}
          {showWarning && (
            <Warning
              width="540px"
              header={t('messages.Блокировка клиента')}
              mainText={{
                __html: `${t('messages.Блокировка клиента приведет к блокировке всех СО этого клиента.')} </br>
								${t('messages.Заблокировать клиента')} ${clientInfo.name}?`,
              }}
              cancelButton={{
                func: closeWarning,
                text: t('buttons.Отменить'),
              }}
              acceptButton={{
                func: changeClientStatus,
                text: t('buttons.Заблокировать клиента'),
                img: '/images/icon_lock.png',
                theme: themes.red,
              }}
            />
          )}
        </>
      ) : null}
    </PageWrapper>
  );
};

export default withRouter(ClientCard);
