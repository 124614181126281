import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button, ButtonContainer } from 'libs/ui';
import { CommentsContent, Header, CommentsBlock, Comment } from './styledComponents';
import { api } from 'api';

import { useTranslation } from 'react-i18next';

const Comments = ({ id, removeFormInfo }) => {
  const { t } = useTranslation();

  const [items, setItems] = useState(null);

  useEffect(() => {
    const fetchComments = async () => {
      const data = await api.call('comment.list', { filter_params: { payment_id: id } });
      setItems(data.items);
    };

    fetchComments();
  }, [id]);

  const getItems = () => {
    return items.map((el, idx) => {
      const d = moment(el.created_at).format('lll');
      return (
        <Comment key={idx}>
          <td>{d}</td>
          <td>{el.user_name}</td>
          <td title={el.comment}>
            <div>{el.comment}</div>
          </td>
        </Comment>
      );
    });
  };

  const close = () => {
    removeFormInfo();
  };

  if (!items) {
    return null;
  }

  const cancelClick = (event) => {
    event.stopPropagation();
  };

  const itemsEl = items.length ? getItems() : null;

  return (
    <CommentsContent onClick={cancelClick}>
      <Header>{t('grid.Комментарии')}</Header>
      <CommentsBlock>
        {items.length > 0 ? (
          <table width="100%">
            <thead>
              <tr>
                <th width="155">{t('form.Дата')}</th>
                <th width="120">{t('grid.Автор')}</th>
                <th>{t('filter.Текст')}</th>
              </tr>
            </thead>
            <tbody>{itemsEl}</tbody>
          </table>
        ) : (
          <p>{t('filter.Комментарии отсутствуют')}</p>
        )}
      </CommentsBlock>
      <ButtonContainer space="20px" justifyContent="flex-end">
        <Button onClick={close}>{t('buttons.Ок')}</Button>
      </ButtonContainer>
    </CommentsContent>
  );
};

export default Comments;
