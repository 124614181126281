import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { data } from 'services';
import { routes } from 'router';
import { GridRow, ZoneIcon, Cell, Button, ImgViewer, OptionsCell } from 'libs/ui';
import { getDateTime, getParking } from 'services/types';

const PictureRow = styled(GridRow)`
  height: 300px;
  width: 1300px;
  display: flex;
  justify-content: space-between;
`;

const ImageWrap = styled.td`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 !important;
`;

const ClosePicturesButton = styled(Button)`
  position: absolute;
  bottom: 15px;
  left: 46%;
`;

const EmptyCell = styled(Cell)`
  text-align: center;
`;

const Ride = (props) => {
  const [showPhotos, setShowPhotos] = useState(false);
  const history = useHistory();

  const { t } = useTranslation();

  const togglePhotos = () => {
    setShowPhotos((prev) => !prev);
  };

  const showCard = () => {
    history.push(routes.stay + '?id=' + props.stay_id);
  };

  const getZonesIcons = (type) => {
    const zones = props.zones.getZones();
    const rideZone = type === 'from' ? props.zone_from : props.zone_to;

    const zone = zones.find((zone) => zone.id === rideZone);
    return zone ? <ZoneIcon color={zone.color}>{zone.name}</ZoneIcon> : null;
  };

  const getPhoto = (photo) => {
    return props.data && props.data.photos ? props.data.photos[photo] : null;
  };

  const getRack = (nodeId, rackId) => {
    const node = getDecorateName('node', nodeId);
    const rack = getDecorateName('rack', rackId);
    return `${node} ${rack}`;
  };

  const getDecorateName = (type, id) => {
    if (id) {
      const prefix = type === 'node' ? 'У' : 'C';
      return type === 'node' && id < 10 ? `${prefix}0${id}` : `${prefix}${id}`;
    }
    return '-';
  };

  const getOptionsCell = () => {
    if (props.list === 'black' && !props.result) {
      return <EmptyCell center>—</EmptyCell>;
    }
    const options = [];
    if (props.stay_id && props.stay_id !== 'system_30_07_2018_rfids') {
      options.push({ text: t('buttons.Перейти в карточку парковки'), func: showCard });
    }
    return <OptionsCell options={options} left={-61} />;
  };

  const parking = getParking(props.parking_id);

  return (
    <>
      <GridRow $is_suspicious={(props.list === 'black' && props.result) || props.is_suspicious} onClick={togglePhotos}>
        {window.config.central && <Cell>{parking}</Cell>}
        <Cell>{getDateTime(props.timestamp)}</Cell>
        <Cell>{props.vehicle_plate}</Cell>
        <Cell>{data.getDictValue('passage.list', props.mean_is_blocked)}</Cell>
        <Cell>{data.getDictValue('passage.type', props.type)}</Cell>
        <Cell>{data.getDictValue('passage.result', props.result)}</Cell>
        <Cell>{getZonesIcons('from')}</Cell>
        <Cell>{getRack(props.node_id, props.rack_id)}</Cell>
        <Cell>{getZonesIcons('to')}</Cell>
        <Cell>{props.csc_owner}</Cell>
        <Cell>{props.vehicle_class}</Cell>
        {getOptionsCell()}
      </GridRow>
      {showPhotos && (
        <PictureRow>
          <ImageWrap>
            <ImgViewer images={[getPhoto('photo_1')]} width={'432px'} height={'300px'} />
            <ImgViewer images={[getPhoto('photo_2')]} width={'432px'} height={'300px'} />
            <ImgViewer images={[getPhoto('photo_3')]} width={'432px'} height={'300px'} />
            <ClosePicturesButton onClick={togglePhotos}>
              <i className="fa fa-arrow-up"></i> {t('buttons.Скрыть')}
            </ClosePicturesButton>
          </ImageWrap>
        </PictureRow>
      )}
    </>
  );
};

export default Ride;
