import React, { useState } from 'react';
//import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { user } from 'services';
import { api } from 'api';
import { Button, themes } from 'libs/ui';
import { getStayInfo } from 'store/ducks/models/stays';
import SetTariff from './forms/set-tariff';
import { useTranslation } from 'react-i18next';

export const SetTariffAction = () => {
  const { t } = useTranslation();

  const info = useSelector((state) => state.stays.stay);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const { parking_id, id, is_active, tariff_id } = info;

  /**
   * show form
   */
  const showForm = () => {
    setShow(true);
  };

  /**
   * hide form
   */
  const hideForm = () => {
    setShow(false);
  };

  /**
   * change mean status
   */
  const action = ({ stayId, tariffId }) => {
    const params = {
      stay_id: stayId,
      tariff_id: tariffId,
    };

    return api.call('stays.set_tariff', params).then(() => {
      dispatch(getStayInfo(id));
    });
  };

  // check
  if (!is_active || !user.haveRight('tariff.list') || !user.haveRight('tariff.replace')) {
    return null;
  }

  return (
    <>
      <Button theme={themes.gray} fontSize="16px" onClick={showForm}>
        <i className="fa fa-ticket"></i>
        {t('buttons.Сменить тариф')}
      </Button>
      {show && <SetTariff id={id} parkingId={parking_id} tariffId={tariff_id} close={hideForm} action={action} />}
    </>
  );
};
