import React, { useState } from 'react';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import styled from 'styled-components';
import { required, WhiteStyledField } from 'libs/form';
import { Button, themes, ButtonContainer } from 'libs/ui';
import { useTranslation } from 'react-i18next';

const StyledButtonContainer = styled(ButtonContainer)`
  margin-top: 20px;
`;

const Form = (props) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    addEntity,
    getEntries,
    updateMessageDisplayHandler,
    displayHandler,
    destroy,
    form,
    initialValues,
  } = props;

  const [role, setRole] = useState('');

  const submit = (values) => {
    let decorateValues = { ...values };
    if (!decorateValues.password) {
      decorateValues.password = values.username;
    }

    if (decorateValues.role_id) {
      decorateValues.role_id = Number(decorateValues.role_id);
    } else {
      decorateValues.role_id = Number(Object.keys(window.config.role_map)[0]);
    }

    return addEntity(decorateValues, afterSubmit).catch((errors) => {
      if (errors && errors.data && errors.data.type === 'duplicate') {
        throw new SubmissionError({
          username: 'duplicate',
        });
      }
    });
  };

  const afterSubmit = () => {
    getEntries({ offset: 0 });
    updateMessageDisplayHandler(true);
    setTimeout(() => {
      updateMessageDisplayHandler(false);
    }, 3000);
    displayHandler(false);
    destroy(form);
  };

  const changeRole = (event) => {
    const { value } = event.target;
    setRole(value);
  };

  const incassator = role === '2' || role === '4';
  const roles = Object.keys(window.config.role_map).map((idx) => (
    <option key={idx} value={idx}>
      {window.config.role_map[idx]}
    </option>
  ));

  return (
    <form onSubmit={handleSubmit(submit)} onChange={changeRole}>
      <WhiteStyledField type="select" label={t('form.Роль')} name="role_id" className="shortfield">
        {roles}
      </WhiteStyledField>
      <WhiteStyledField
        type="text"
        label={incassator ? t('form.Номер БСК') : t('form.Логин')}
        placeholder={incassator ? t('form.Номер БСК') : t('form.Логин')}
        name="username"
        validate={required(t)}
      />
      <WhiteStyledField
        type="text"
        label={t('form.ФИО')}
        placeholder={t('form.ФИО')}
        name="name"
        validate={required(t)}
      />
      <WhiteStyledField
        type="text"
        label={t('form.Пароль')}
        placeholder={t('form.Пароль')}
        name="password"
        className={incassator ? 'hidden' : ''}
        validate={incassator ? null : required(t)}
      />
      <WhiteStyledField type="text" label={t('form.E-mail')} placeholder="someone@gmail.com" name="email" />
      <WhiteStyledField type="text" label={t('grid.Телефон')} placeholder="+7 956 456-56-78" name="phone" />
      <StyledButtonContainer space="20px">
        <Button
          type="button"
          onClick={() => {
            displayHandler(false);
            destroy(form);
          }}
        >
          {t('buttons.Отменить')}
        </Button>
        <Button theme={themes.blue}>
          <i className="fa fa-save" />
          {t('buttons.Сохранить')}
        </Button>
      </StyledButtonContainer>
    </form>
  );
};

export default connect((store, props) => ({
  form: 'addUserForm',
  destroyOnUnmount: false,
  initialValues: props.initialValues,
}))(reduxForm()(Form));
