import React from 'react';
import { TableHeader } from 'libs/ui';
import Row from './Row';
import { GridBox, Grid, TableHead, TableHeadRow, TopRow } from 'libs/ui';
import { Total } from 'libs/ui';

import { useTranslation } from 'react-i18next';

const PromisesGrid = ({ entries, count, getXlsLink }) => {
  const { t } = useTranslation();

  const getList = () => {
    const items = entries;
    //console.log('🚀 ~ items', items);
    let itemsList = [];
    if (items) {
      for (let key in items) {
        itemsList.push(<Row key={key} {...items[key]} />);
      }
    }
    return itemsList;
  };

  return (
    <GridBox>
      <TopRow>
        <Total cnt={count} />
        {getXlsLink()}
      </TopRow>
      <Grid>
        <TableHead>
          <TableHeadRow>
            <TableHeader width="270px" sortField="timestamp">
              {t('form.ФИО')}
            </TableHeader>
            <TableHeader width="220px">{t('filter.№ договора паркирования')}</TableHeader>
            <TableHeader width="150px">{t('dict_filter.Тип')}</TableHeader>
            <TableHeader width="170px">{t('buttons.Номер')}</TableHeader>
            <TableHeader>{t('grid.Долг')}</TableHeader>
            <TableHeader width="140px">{t('grid.Статус оплаты')}</TableHeader>
          </TableHeadRow>
        </TableHead>
        <tbody>{getList()}</tbody>
      </Grid>
    </GridBox>
  );
};
export default PromisesGrid;
