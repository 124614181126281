import { useEffect, useState } from 'react';

import { api } from 'api';
import zones from 'services/zones';
import { notify } from 'libs/common';
import { Button, themes } from 'libs/ui';
import { Buttons, Content } from './zones-limit.styled';
import { LimitByZone } from './limit-by-zone';
import { LimitByTime } from './limit-by-time';

import { useTranslation } from 'react-i18next';

let colors;

export const ZonesLimit = (props) => {
  const { t } = useTranslation();

  const { clientId } = props;

  const [ready, setReady] = useState(false);
  // const [limits, setLimits] = useState([]);
  const [data, setData] = useState(null);
  const [nextrender, setNextrender] = useState(0);

  useEffect(() => {
    const getData = () => {
      api.call('clients.get_limits', { client_id: clientId }).then((data) => {
        colors = zones.getZonesColors();
        // const limits = data.map((el) => {
        //   return { ...el, color: colors[el.zone_id] };
        // });
        // console.log('🚀 ~ limits ~ limits:', limits);
        // setLimits(limits);
        setData(data);
        setReady(true);
      });
    };
    getData();
    return () => {
      setData(null);
      setReady(false);
    };
  }, []);

  /**
   * save limits
   */
  const save = () => {
    // console.log('🚀 ~ save ~ data:', data);

    api
      .call('clients.set_limits', { client_id: clientId, limits: data })
      .then(() => {
        notify(t('messages.Лимиты ТС'), t('messages.Лимиты сохранены'), 'success');
      })
      .catch((error) => {
        // console.log('🚀 ~ save ~ error:', error);
        notify(t('messages.Лимиты ТС'), t('messages.Ошибка сохранения: ') + error.toString());
      });
  };

  const changeData = (out) => {
    setData(out);
    setNextrender((val) => val + 1);
  };

  if (!ready) {
    return null;
  }

  if (!data.length) {
    return (
      <RfidBox>
        <p>{t('messages.Данные по лимитам зон у клиента отсутствуют')}</p>
      </RfidBox>
    );
  }

  const isByZone = data[0].start_time === undefined;

  return (
    <Content>
      {isByZone ? (
        <LimitByZone colors={colors} onChange={changeData} data={data} nextrender={nextrender} />
      ) : (
        <LimitByTime colors={colors} onChange={changeData} data={data} nextrender={nextrender} />
      )}

      <Buttons>
        <Button theme={themes.blue} onClick={save}>
          <i className="fa fa-save" />
          {t('buttons.Сохранить')}
        </Button>
      </Buttons>
    </Content>
  );
};
