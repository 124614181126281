import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { user } from 'services';
import { getRules, toggleNode, toggleRack, toggleZone, updateRule } from 'store/ducks/models/rules';
import zonesService from 'services/zones';
import { PanelWrapper, Tabs, Tab } from 'modules/components/card/zoneTabs/styledComponents';
import { StyledButton, Zone, CheckboxWrapper, Checkbox } from './Zones.styled';

const Zones = ({ selectedZoneId, rules, rulesForm, ruleId, refresh }) => {
  const [selectedZone, setSelectedZone] = useState(selectedZoneId);
  const [saving, setSaving] = useState(false);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedZone(selectedZoneId);
    dispatch(getRules());
  }, [selectedZoneId, dispatch]);

  const getTabs = () => {
    const zones = zonesService.getZones();
    return zones.map((item) => {
      const id = item.zone_id;
      const color = item.background_color;
      const isSelected = id === selectedZone;
      return (
        <Tab isSelected={isSelected} key={id} color={color} type="single" onClick={() => setSelectedZone(id)}>
          {id}
        </Tab>
      );
    });
  };

  const getZone = (zoneId) => {
    const rule = rulesForm.find((el) => el.rule_id === ruleId);
    return rule.zones.find((item) => item.zone_id === zoneId) || [];
  };

  const getCheckboxes = (zoneId) => {
    const rightEdit = user.haveRight('rules.update');
    const zone = getZone(zoneId);
    let zoneNodes = [];
    let allRacks = true;

    if (zone.nodes) {
      zoneNodes = zone.nodes.map((node) => {
        let nodeChecked = true;

        const racks = node.racks.map((rack) => {
          if (!rack.enabled) {
            nodeChecked = false;
            allRacks = false;
          }
          const title = zonesService.getRackTitle(zone.zone_id, node.node_id, rack.rack_id);
          return (
            <Checkbox color={zonesService.getColor(selectedZone)} className="checkbox" key={rack.rack_id}>
              <input
                onChange={() => {
                  if (rightEdit) {
                    dispatch(toggleRack(rack.enabled, ruleId, zone.zone_id, node.node_id, rack.rack_id));
                  }
                }}
                name={`rack_${rack.rack_id}`}
                checked={rack.enabled}
                type="checkbox"
              />
              <label>С{title}</label>
            </Checkbox>
          );
        });

        const title = zonesService.getNodeTitle(zone.zone_id, node.node_id);
        return (
          <div style={{ display: 'flex' }} key={node.node_id}>
            <Checkbox color={zonesService.getColor(selectedZone)} className="checkbox">
              <input
                onChange={() => {
                  if (rightEdit) {
                    dispatch(toggleNode(nodeChecked, ruleId, zone.zone_id, node.node_id));
                  }
                }}
                checked={nodeChecked}
                type="checkbox"
              />
              <label>У{title}</label>
            </Checkbox>
            {racks}
          </div>
        );
      });
    }

    return (
      <CheckboxWrapper>
        <Checkbox color={zonesService.getColor(selectedZone)} className="checkbox">
          <input
            onChange={() => {
              if (rightEdit) {
                dispatch(toggleZone(allRacks, ruleId, zone.zone_id));
              }
            }}
            checked={allRacks}
            type="checkbox"
          />
          <label>{t('form.Все стойки')}</label>
        </Checkbox>
        {zoneNodes}
      </CheckboxWrapper>
    );
  };

  const updateRule = () => {
    if (saving) return;

    setSaving(true);
    dispatch(
      updateRule(ruleId, () => {
        refresh();
        setSaving(false);
      })
    );
  };

  const rightEdit = user.haveRight('rules.update');
  const tabs = getTabs();
  const color = zonesService.getColor(selectedZone);
  const checkboxes = getCheckboxes(selectedZone);
  const cl = saving ? 'saving' : '';

  return (
    <PanelWrapper>
      <Tabs>{tabs}</Tabs>
      <Zone color={color}>
        <div>
          {checkboxes}
          <br />
        </div>
        {rightEdit && (
          <StyledButton className={cl} disabled={saving} onClick={updateRule}>
            <i className="fa fa-save" /> {t('buttons.Сохранить')}
          </StyledButton>
        )}
      </Zone>
    </PanelWrapper>
  );
};

const mapStateToProps = (store) => ({
  rules: store.rules.rules,
  rulesForm: store.rules.rulesForm,
});

export default connect(mapStateToProps)(withRouter(Zones));
