import { reduxForm, FormSection, Form } from 'redux-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Tarrificator } from '../tarifficators/tarifficator';
import { TariffFormMain } from './tariff-form-main';
import { TariffData, FormHeader, SaveButton, UpdateMessageBox } from './tariff-form-old.styled';

const validate = (values) => {
  const errors = {};

  const addError = (zone, field, msg) => {
    if (!errors.zones) {
      errors.zones = {};
    }
    if (!errors.zones[zone]) {
      errors.zones[zone] = {};
    }
    errors.zones[zone][field] = msg;
  };

  const getVal = (time1) => {
    if (!time1) {
      return 0;
    }
    let t1 = time1.split(':');
    return parseInt(t1[0]) * 3600 + parseInt(t1[1]) * 60 + parseInt(t1[2]);
  };

  const zones = values?.zones;
  if (zones) {
    Object.keys(zones).forEach((zone) => {
      const start = zones[zone].start_day_time;
      const end = zones[zone].end_day_time;
      if (start && end) {
        let s1 = getVal(start);
        let e1 = getVal(end);
        // console.log('🚀 ~ Object.keys ~ e1 - s1:', e1 - s1);
        if (e1 - s1 < 3600) {
          addError(zone, 'end_day_time', 'Разница между дневным и ночным временем не может быть меньше часа');
        }
      }
    });
  }
  // console.log('🚀 ~ validate ~ values:', values, errors);
  // errors.zones.P1.free_interval = '123';
  return errors;
};

const TariffForm = (props) => {
  // console.log('🚀 ~ TariffForm ~ props:', props);
  const {
    info,
    onSubmit,
    onSubmitClick,
    tarrificatorParams,
    invalid,
    disabled,
    categories,
    vehicleClasses,
    showUpdateMessage,
    showMessageAfterAdd,
    handleSubmit,
  } = props;
  // console.log('🚀 ~ TariffForm ~ info:', info);

  const { t } = useTranslation();

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <TariffData>
        <FormHeader>{t('form.Данные тарифа')}</FormHeader>
        <TariffFormMain categories={categories} vehicleClasses={vehicleClasses} type={info.type} initialValue={info} disabled={disabled} />
        {showUpdateMessage && <UpdateMessageBox>{t('form.Новый тариф создан')}</UpdateMessageBox>}
        {showMessageAfterAdd && <UpdateMessageBox>{t('form.Новый тариф создан')}</UpdateMessageBox>}
      </TariffData>

      <FormSection name="zones">
        <Tarrificator {...tarrificatorParams} />
      </FormSection>
      <SaveButton disabled={invalid} invisible={disabled} onClick={onSubmitClick} />
    </Form>
  );
};

TariffForm.propTypes = {
  onSubmit: PropTypes.any.isRequired,
  onSubmitClick: PropTypes.any.isRequired,
  initialValues: PropTypes.any.isRequired,
  tarrificatorParams: PropTypes.any.isRequired,
  invalid: PropTypes.any,
  disabled: PropTypes.any,
  categories: PropTypes.any.isRequired,
  vehicleClasses: PropTypes.any.isRequired,
  handleSubmit: PropTypes.any.isRequired,
  showUpdateMessage: PropTypes.bool,
  showMessageAfterAdd: PropTypes.bool,
  info: PropTypes.any,
};

export default reduxForm({
  form: 'TariffForm',
  //initialValues,
  validate,
  enableReinitialize: false,
})(TariffForm);
