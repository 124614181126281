import { getDate } from 'services/types';

export const getTableParams = (t) => {
  const fields = [
    { name: 'vehicle_plate', title: t('sections.ГРЗ'), width: 130, sortField: 'vehicle_plate' },
    {
      name: 'is_active',
      title: t('grid.Статус'),
      width: 145,
      sortField: 'is_active',
      render: (val) => (val ? t('grid.Активен') : t('grid.Деактивирован')),
    },
    {
      name: 'start_at',
      title: t('form.Дата действия с'),
      width: 210,
      sortField: 'start_at',
      render: (val) => (val === null ? '' : getDate(val)),
    },

    {
      name: 'end_at',
      title: t('form.Дата действия по'),
      width: 210,
      sortField: 'end_at',
      render: (val) => (val === null ? '' : getDate(val)),
    },

    { name: 'reason', title: t('form.Причина внесения'), width: 112, sortField: 'reason' },
    { name: 'comment', title: t('filter.Комментарий'), width: 150 },
  ];

  // get row class
  const getRowClass = (row) => {
    let out = '';
    out += ' ' + row.status;
    return out;
  };

  return { fields, getRowClass, withComment: false };
};
