import { reduxForm, SubmissionError } from 'redux-form';
import { useTranslation } from 'react-i18next';

import { user } from 'services';
import { addToGreyPlate, updateGreyPlate } from 'services/payments-service';
import { Button, themes, Popup2, ParkingSession } from 'libs/ui';
import { ParkingFilter2, required as required, WhiteStyledField, Title, ErrorMsg, DictInput } from 'libs/form';
import { StyledButtonContainer } from './greyPlate-form.styled';

const GreyPlateForm = (props) => {
  const { error, destroy, refresh, setMessage, form, handleSubmit, close, type, meanId, meanType } = props;

  const { t } = useTranslation();

  const rightSessionActive = user.haveRight('grey_plate.show_active_stay');

  const isAdd = type === 'addForm';

  /**
   * after submit
   */
  const afterSubmit = () => {
    refresh();
    setMessage(t('grid.Изменения сохранены'));
    setTimeout(() => {
      setMessage('');
    }, 3000);
    close();
    destroy(form);
  };

  /**
   * submit
   * @param {*} values
   * @returns
   */
  const submit = (values) => {
    // data
    const output = { ...values };
    const { vehicle_plate, comment, reason, start_at, end_at, id } = output;
    const out = { vehicle_plate, comment, reason, start_at, end_at, id };

    // Определяем метод в зависимости от типа формы
    const method = isAdd ? addToGreyPlate : updateGreyPlate;

    return method(out)
      .then(() => {
        afterSubmit();
        return true;
      })
      .catch((errors) => {
        if (errors && errors.data) {
          throw new SubmissionError({
            _error: errors.data.type || 'Произошла ошибка',
          });
        }

        if (errors && errors.field && errors.message) {
          const fields = {
            vehicle_plate: t('buttons.Номер'),
          };

          const fieldName = fields[errors.field] || errors.field;
          const out = {
            _error: `${fieldName} - ${errors.message.toLowerCase()}`,
            [errors.field]: errors.message,
          };
          throw new SubmissionError(out);
        } else {
          throw new SubmissionError({
            _error: t('messages.Неизвестная ошибка. Пожалуйста, попробуйте снова.'),
          });
        }
      });
  };

  const cancel = () => {
    close();
    destroy(form);
  };

  const title = isAdd ? t('form.Добавить ГРЗ в серый список') : t('form.Изменить клиента в сером списке');

  return (
    <Popup2 w={482} top={3}>
      <Title>{title}</Title>
      <form onSubmit={handleSubmit(submit)}>
        <ErrorMsg error={error} />
        <ParkingFilter2 />

        <WhiteStyledField type="text" label={t('sections.ГРЗ')} placeholder={t('form.А000AA 000 rus')} name="vehicle_plate" validate={required(t)} />

        <WhiteStyledField
          type="text"
          label={t('form.Причина внесения')}
          placeholder={t('form.Причина внесения')}
          name="reason"
          validate={required(t)}
        />

        <WhiteStyledField
          className="shortfield"
          type="dateTime"
          label={t('form.Дата действия с')}
          placeholder={t('form.Дата действия с')}
          name="start_at"
        />

        <WhiteStyledField
          className="shortfield"
          type="dateTime"
          label={t('form.Дата действия по')}
          placeholder={t('form.Дата действия по')}
          name="end_at"
        />

        <WhiteStyledField
          className="shortfield"
          type="text"
          label={t('filter.Комментарий')}
          placeholder={t('filter.Комментарий')}
          name="comment"
          validate={required(t)}
        />

        {!isAdd && rightSessionActive && <ParkingSession meanType={meanType} meanId={meanId} />}
        <StyledButtonContainer space="20px">
          <Button type="button" onClick={cancel}>
            {t('buttons.Отменить')}
          </Button>
          <Button theme={themes.blue} type="submit">
            <i className="fa fa-save" />
            {t('buttons.Сохранить')}
          </Button>
        </StyledButtonContainer>
      </form>
    </Popup2>
  );
};

export default reduxForm({
  form: 'GreyPlateForm',
  destroyOnUnmount: false,
})(GreyPlateForm);
