import { useTranslation } from 'react-i18next';

import { Button, themes, withPopup } from 'libs/ui';

export const AddClientAction = withPopup((props) => {
  const { t } = useTranslation();
  return (
    <Button onClick={props.popupDisplayHandler} theme={themes.blue}>
      <img src="/images/shape.png" alt="" />
      {t('buttons.Создать клиента')}
    </Button>
  );
});
