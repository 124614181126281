import { data } from 'services';
import { getCategoryById, getVehicleClassById } from 'services/types';
import { ParkingCell } from 'libs/ui';

export const getTableParams = (t) => {
  const fields = [
    { name: 'rfid', title: t('sections.RFID'), width: 120, sortField: 'rfid' },
    { name: 'vehicle_plate', title: t('sections.ГРЗ'), width: 130, sortField: 'vehicle_plate' },
    {
      name: 'status',
      title: t('grid.Статус'),
      width: 145,
      sortField: 'status',
      render: (val) => data.getDictValue('rfid.status', val),
    },
    { name: 'owner', title: t('form.Держатель'), sortField: 'owner' },
    { name: 'client_name', title: t('form.Клиент'), width: 147, sortField: 'client_name' },
    {
      name: 'vehicle_class',
      title: t('dict_filter.Класс ТС'),
      width: 112,
      sortField: 'vehicle_class',
      render: (val) => getVehicleClassById(val),
    },
    {
      name: 'vehicle_category',
      title: t('form.Категория'),
      width: 112,
      sortField: 'vehicle_category',
      render: (val) => getCategoryById(val),
    },
    { name: 'tariff', title: t('form.Тариф'), width: 180 },
    { name: 'comment', title: t('filter.Комментарий'), width: 150 },
  ];

  if (window.config.central) {
    fields.unshift({
      name: 'parking_id',
      title: t('grid.Парковка'),
      render: (val) => <ParkingCell parking_id={val} cmp={'span'} />,
      width: 140,
    });
  }

  // get row class
  const getRowClass = (row) => {
    let out = '';
    out += ' ' + row.status;
    return out;
  };

  return { fields, getRowClass, withComment: true };
};
