import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { user } from 'services';
import { api } from 'api';
import { Button, themes } from 'libs/ui';
import { NewTicket } from './forms/new-ticket';
import { useTranslation } from 'react-i18next';

export const NewTicketAction = (props) => {
  const { withoutInfo } = props;
  const info = withoutInfo
    ? {
        parking_id: 0,
        id: 0,
        is_active: true,
      }
    : useSelector((state) => state.stays.stay);
  const [show, setShow] = useState(false);
  const { parking_id, id, is_active } = info;

  const { t } = useTranslation();

  /**
   * show form
   */
  const showForm = () => {
    setShow(true);
  };

  /**
   * hide form
   */
  const hideForm = () => {
    setShow(false);
  };

  /**
   * change mean status
   */
  const action = ({ parkomat, value, comment, zone }) => {
    const params = {
      stay_id: typeof id === 'string' ? id : null,
      payment_rack_id: parkomat,
      penalty: value ? value * 100 : 0,
      comment: value ? comment : '',
    };
    if (zone) {
      params.zone_id = zone;
    }
    const withParkingId = window.config.central ? parking_id : '';

    return api.call('stays.print_receipt', params, 'NetworkManager', withParkingId ? '/' + parking_id : '');
  };

  // check
  if (!is_active || !user.haveRight('stays.print_receipt')) {
    return null;
  }

  return (
    <>
      <Button theme={themes.blue} fontSize="16px" onClick={showForm}>
        <i className="fa fa-ticket"></i>
        {t('buttons.Новый билет')}
      </Button>
      {show && <NewTicket id={id} parkingId={parking_id} close={hideForm} action={action} withoutInfo={withoutInfo} />}
    </>
  );
};

NewTicketAction.propTypes = {
  withoutInfo: PropTypes.bool,
};
