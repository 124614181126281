import React, { useState } from 'react';

import { TableHeader } from 'libs/ui';
import Row from './Row';
import InfoBlock from './InfoBlock';
import { GridBox, Grid as StyledGird, TableHead, TableHeadRow, TopRow } from 'libs/ui';
import { Total } from 'libs/ui';

import { useTranslation } from 'react-i18next';

const Grid = ({ entries, actions, count, getXlsLink, setActionInfo, actionInfo }) => {
  const { t } = useTranslation();

  const [detail, setDetail] = useState(false);

  const setInfo = (...rest) => {
    setDetail(true);
    setActionInfo(...rest);
  };

  const closeDetail = () => {
    setDetail(false);
  };

  const getEntries = () => {
    const currentEntries = entries || actions;

    let Rows = [];
    if (currentEntries) {
      for (let key in entries) {
        Rows.push(<Row key={entries[key].id} {...entries[key]} setActionInfo={setInfo} />);
      }
    }
    return Rows;
  };

  return (
    <GridBox>
      <TopRow>
        <Total cnt={count} />
        {getXlsLink()}
      </TopRow>
      <StyledGird>
        <TableHead>
          <TableHeadRow>
            <TableHeader width="210px">{t('grid.Дата и время')}</TableHeader>
            <TableHeader width="230px">{t('inputs.Пользователь')}</TableHeader>
            <TableHeader width="230px">{t('form.Роль')}</TableHeader>
            <TableHeader>{t('inputs.Описание')}</TableHeader>
            <TableHeader width="70px"></TableHeader>
          </TableHeadRow>
        </TableHead>
        <tbody>{getEntries()}</tbody>
      </StyledGird>
      {detail && <InfoBlock close={closeDetail} info={actionInfo} />}
    </GridBox>
  );
};

export default Grid;
