import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { useTranslation } from 'react-i18next';

import { user } from 'services';
import { StyledField, ErrorMsg, required, itn, psrnsp } from 'libs/form';
import { ButtonContainer, Submit } from 'libs/ui';
import { LK } from './lk';

function SoleProprietorForm(props) {
  const { t } = useTranslation();

  const { initialValues } = props;
  const rightUpdate = user.haveRight('clients.update');

  return (
    <form onSubmit={props.handleSubmit}>
      <ErrorMsg theme="dark" message={props.error} />

      <StyledField
        validate={required}
        type="text"
        label={t('form.ФИО')}
        name="name"
        placeholder="Иванов Иван Иванович"
      />
      <StyledField
        type="date"
        label={t('form.Дата рождения')}
        name="birth_date"
        placeholder={t('form.08 11 2017')}
        className="shortfield"
      />
      <StyledField
        validate={[itn]}
        mask="9999 9999 9999"
        type="text"
        label="ИНН"
        name="itn"
        placeholder="1234 4321 5643"
      />
      <StyledField
        validate={[psrnsp]}
        mask="9999 9999 9999 999"
        type="text"
        label="ОГРНИП"
        name="psrnsp"
        placeholder="4235 4556 7845 745"
      />
      <StyledField type="text" label={t('form.Паспорт')} name="passport" placeholder="04 05 990440" />
      <StyledField
        type="text"
        label={t('form.Кем выдан')}
        name="passport_issued_by"
        placeholder="Выдан УВД Приморского района г. Санкт-Петербург"
      />
      <StyledField
        type="date"
        label={t('form.Дата выдачи')}
        name="passport_when_issued"
        placeholder={t('form.08 11 2017')}
        className="shortfield"
      />
      <StyledField
        type="text"
        label={t('form.Код подразделения')}
        name="passport_department_code"
        placeholder="233 – 005"
      />
      <LK lk_login={initialValues.lk_login} />
      {rightUpdate && (
        <ButtonContainer>
          <Submit disabled={props.submitting} />
        </ButtonContainer>
      )}
    </form>
  );
}

const getDecorateValues = (values) => {
  let decorateValues = {};
  for (let key in values) {
    if (values[key]) {
      decorateValues[key] = values[key];
    }
  }
  return decorateValues;
};

export default connect((store) => {
  let {
    type,
    name,
    birth_date,
    itn,
    psrnsp,
    passport,
    passport_issued_by,
    passport_when_issued,
    passport_department_code,
    data,
  } = store.client.clientInfo;

  let { lk_unlimited, lk_login } = data ? data : { lk_unlimited: '', lk_login: '' };

  const initialValues = getDecorateValues({
    type,
    name,
    birth_date,
    itn,
    psrnsp,
    passport,
    passport_issued_by,
    passport_when_issued,
    passport_department_code,
    lk_unlimited,
    lk_login,
  });

  return {
    initialValues: initialValues,
  };
})(
  reduxForm({
    form: 'SoleProprietorForm',
  })(SoleProprietorForm)
);
