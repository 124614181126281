import { useEffect } from 'react';
import { reduxForm } from 'redux-form';

import { getOptionsList } from 'services/types';
import { ResetBtn, UseBtn } from 'libs/ui';
import { Container, Input, ParkingFilter, DictFilter, Select, ButtonsContainer } from 'libs/form';

import { useTranslation } from 'react-i18next';

const initial = {};

const Filter = (props) => {
  const { setInitial, reset, handleSubmit, theme, categories, vehicleClasses } = props;

  const { t } = useTranslation();

  // did mount
  useEffect(() => {
    setInitial(initial);
    return () => {
      reset();
      setInitial(initial);
    };
  }, []);

  return (
    <Container theme={theme}>
      <form onSubmit={handleSubmit}>
        <DictFilter w={140} dict="rfid.status" />

        <Input width="170px" name="rfid" type="text" label={t('sections.RFID')} placeholder="3786 4678 6467 5656" />

        <Input width="300px" name="client_name" type="text" label={t('form.Клиент')} placeholder={t('form.Иванов И.И.')} />

        <Input width="300px" name="owner_name" type="text" label={t('form.Держатель')} placeholder={t('form.Иванов И.И.')} />

        <Select width="300px" name="vehicle_category" type="select" label={t('form.Категория')}>
          <option value="">{t('filter.Все')}</option>
          {getOptionsList(categories)}
        </Select>

        <Select width="140px" name="vehicle_class" type="select" label={t('dict_filter.Класс ТС')}>
          <option value="">{t('filter.Все')}</option>
          {getOptionsList(vehicleClasses)}
        </Select>

        <Input width="300px" name="tariff" type="text" label={t('form.Тариф')} placeholder={t('filter.Базовый')} />

        <Input width="170px" name="vehicle_plate" type="text" label={t('sections.ГРЗ')} placeholder={t('form.А000AA 000 rus')} />

        <Input label={t('filter.Комментарий')} width="370px" name="comment" type="text" placeholder={t('filter.Текст комментария')} lastField />

        <ParkingFilter />

        <ButtonsContainer>
          <ResetBtn onClick={reset} theme={theme} />
          <UseBtn />
        </ButtonsContainer>
      </form>
    </Container>
  );
};

export default reduxForm({
  form: 'rfidFilter',
  destroyOnUnmount: false,
  initialValues: initial,
})(Filter);
