import PropTypes from 'prop-types';

import { SortContext } from 'libs/control';
import { TableHeadCell } from './table-header-field.styled';

export const TableHeaderField = (props) => {
  const { sortField, name, title, numeric, align, orderBy, order, ...rest } = props;
  delete rest.render;
  delete rest.clip;

  return (
    <SortContext.Consumer>
      {({ setSort, getSortArrow }) => {
        const other = {};
        if (sortField) {
          other.onClick = () => setSort(props.sortField);
        }

        return (
          <TableHeadCell {...rest} withSort={!!sortField} className={props.className} $numeric={numeric} align={align} {...other}>
            <div>
              <p>{typeof title === 'string' ? title : <img src={title.props.src} />}</p>

              {sortField && getSortArrow && getSortArrow(props.sortField)}
            </div>
          </TableHeadCell>
        );
      }}
    </SortContext.Consumer>
  );
};

TableHeaderField.propTypes = {
  title: PropTypes.node.isRequired,
  name: PropTypes.string,
  sortField: PropTypes.string,
  numeric: PropTypes.bool,
  align: PropTypes.string,
  width: PropTypes.any,
  render: PropTypes.func,
};

TableHeaderField.defaultProps = {
  numeric: false,
};

export default TableHeaderField;
