import React from 'react';

import { user } from 'services';
import { GridUpdateMessage } from 'libs/ui';
import { TableHeader } from 'libs/ui';
import Row from './Row';
import { GridBox, Grid, TableHead, TableHeadRow, TopRow } from 'libs/ui';
import { Button, themes } from 'libs/ui';
import Window from 'modules/components/windows/UpdateWindow';
import addForm from './addForm';
import updateForm from './updateForm';
import { useTranslation } from 'react-i18next';

const UsersGrid = (props) => {
  const { t } = useTranslation();

  const getUserList = () => {
    const users = props?.entries || props?.items;

    let list = [];
    if (users) {
      //console.log(props)
      for (let key in users) {
        list.push(
          <Row
            key={users[key].id}
            formIsShow={props.interface.showUpdateForm}
            setItemInfo={props.setItemInfo}
            deleteItem={props.deleteItem}
            updateFormDisplayHandler={props.updateFormDisplayHandler}
            getEntries={props.getEntries}
            {...users[key]}
          />
        );
      }
    }
    return list;
  };

  return (
    <GridBox>
      <TopRow>
        <p />
        {user.haveRight('collection.add') && (
          <Button theme={themes.blue} onClick={() => props.addFormDisplayHandler(true)}>
            <i className="fa fa-plus" />
            {t('form.Добавить инкассатора')}
          </Button>
        )}
      </TopRow>
      <Grid>
        <TableHead>
          <TableHeadRow>
            <TableHeader>{t('form.ФИО')}</TableHeader>
            <TableHeader width="145px">{t('dict_filter.Статус')}</TableHeader>
            <TableHeader width="165px">{t('sections.БСК')}</TableHeader>
            <TableHeader width="80px"></TableHeader>
          </TableHeadRow>
        </TableHead>
        <tbody>{getUserList()}</tbody>
      </Grid>
      {props.interface.showAddForm ? (
        <Window
          type="add"
          header={t('form.Новый инкассатор')}
          Form={addForm}
          displayHandler={props.addFormDisplayHandler}
          updateMessageDisplayHandler={props.updateMessageDisplayHandler}
          addEntity={props.addItem}
          getEntries={props.getEntries}
        />
      ) : null}
      {props.interface.showUpdateForm ? (
        <Window
          type="update"
          header={props.itemInfo.name}
          Form={updateForm}
          displayHandler={props.updateFormDisplayHandler}
          updateMessageDisplayHandler={props.updateMessageDisplayHandler}
          updateEntity={props.updateItem}
          getEntries={props.getEntries}
          id={props.itemInfo.id}
          initialValues={{
            id: props.itemInfo.id,
            is_active: props.itemInfo.is_active,
            name: props.itemInfo.name,
            card_id: props.itemInfo.card_id,
          }}
        />
      ) : null}
      {props.interface.showUpdateMessage ? <GridUpdateMessage>{t('messages.Изменение сохранено')}</GridUpdateMessage> : null}
    </GridBox>
  );
};

export default UsersGrid;
