import { useEffect } from 'react';
import { reduxForm } from 'redux-form';

import { useTranslation } from 'react-i18next';

import { getOptionsList } from 'services/types';
import { ResetBtn, UseBtn } from 'libs/ui';
import {
  Container,
  Input,
  ParkingFilter,
  DictFilter,
  Select,
  ButtonsContainer,
  DoubleField,
  DateSelect,
  DateTime,
} from 'libs/form';

const initial = {};

const Filter = (props) => {
  const { setInitial, reset, handleSubmit, change, theme, categories, vehicleClasses, clientId } = props;

  const { t } = useTranslation();

  // did mount
  useEffect(() => {
    setInitial(initial);
    return () => {
      reset();
      setInitial(initial);
    };
  }, []);

  return (
    <Container theme={theme}>
      <form onSubmit={handleSubmit}>
        <DictFilter w={140} dict="payment_mean.status" />

        <Input
          width="170px"
          name="mean_id"
          type="text"
          label={t('sections.Абонемент')}
          placeholder="3786 4678 6467 5656"
        />

        <Input
          width="220px"
          name="owner"
          type="text"
          label={t('form.Держатель')}
          placeholder={t('form.Введите текст для поиска')}
        />

        <Select width="140px" name="vehicle_category" type="select" label="Категория">
          <option value="">{t('filter.Все')}</option>
          {getOptionsList(categories)}
        </Select>

        <Select width="140px" name="vehicle_class" type="select" label={t('dict_filter.Класс ТС')}>
          <option value="">{t('filter.Все')}</option>
          {getOptionsList(vehicleClasses)}
        </Select>

        <DateSelect from_name="start_at_from" to_name="start_at_to" change={change} theme={theme}>
          <DoubleField>
            <DateTime
              width="160px"
              name="start_at_from"
              type="dateTime"
              label={t('form.Дата действия с')}
              theme={theme}
            />
            <DateTime width="160px" name="start_at_to" type="dateTime" theme={theme} />
          </DoubleField>
        </DateSelect>

        <DateSelect from_name="end_at_from" to_name="end_at_to" change={change} theme={theme}>
          <DoubleField>
            <DateTime
              width="160px"
              name="end_at_from"
              type="dateTime"
              label={t('form.Дата действия по')}
              theme={theme}
            />
            <DateTime width="160px" name="end_at_to" type="dateTime" theme={theme} />
          </DoubleField>
        </DateSelect>

        <Input width="300px" name="tariff" type="text" label={t('form.Тариф')} placeholder={t('form.Имя тарифа')} />

        <Input
          label={t('filter.Комментарий')}
          width="370px"
          name="comment"
          type="text"
          placeholder={t('filter.Текст комментария')}
        />

        <Input
          width="220px"
          name="vehicle_plate"
          type="text"
          label={t('sections.ГРЗ')}
          placeholder={t('form.Введите текст для поиска')}
        />

        {!clientId && (
          <Input
            label={t('form.Клиент')}
            width="230px"
            name="client_name"
            type="text"
            placeholder={t('form.Название клиента')}
            lastField
          />
        )}

        <ParkingFilter />

        <ButtonsContainer>
          <ResetBtn onClick={reset} theme={theme} />
          <UseBtn />
        </ButtonsContainer>
      </form>
    </Container>
  );
};

export default reduxForm({
  form: 'subscriptionsFilter',
  destroyOnUnmount: false,
  initialValues: initial,
})(Filter);
