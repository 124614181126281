import React, { useState } from 'react';
import { Button, themes, ButtonContainer } from 'libs/ui';
import { FormBox, Header, CommentBox, CommentFieldName, CommentInput } from './styledComponents';

import { useTranslation } from 'react-i18next';

const AddCommentForm = ({ type, info, addComment, removeFormInfo }) => {
  const [comment, setComment] = useState('');

  const { t } = useTranslation();

  const getHeader = () => {
    switch (type) {
      case 'rfids':
        return `${t('sections.RFID')} ${info.value}`;
      case 'epms':
        return `${t('sections.БСК')} ${info.value}`;
      case 'vehiclePlates':
        return `${t('sections.ГРЗ')} ${info.value}`;
      case 'subscriptions':
        return `${t('sections.Абонемент')} ${info.value}`;
      default:
        return '';
    }
  };

  const updateComment = () => {
    const commentInfo = {
      id: info.id,
      comment,
    };
    addComment(commentInfo, type);
    cancelForm();
  };

  const cancelForm = () => {
    removeFormInfo(type);
  };

  return (
    <FormBox type={type} style={{ height: 'auto' }}>
      <Header>{getHeader()}</Header>
      <CommentBox>
        <CommentFieldName>{t('filter.Комментарий')}</CommentFieldName>
        <CommentInput
          autoFocus
          placeholder={t('filter.Напишите свой комментарий')}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </CommentBox>
      <ButtonContainer space="20px" justifyContent="flex-end">
        <Button onClick={cancelForm}>{t('buttons.Отменить')}</Button>
        <Button theme={themes.blue} onClick={updateComment}>
          <img src="/images/save_icon_white.png" alt="" />
          {t('buttons.Сохранить')}
        </Button>
      </ButtonContainer>
    </FormBox>
  );
};

export default AddCommentForm;
