import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { user } from 'services';
import { api } from 'api';
import { Button, themes } from 'libs/ui';
import { getMeanType3 } from 'services/types';
import { getStayInfo } from 'store/ducks/models/stays';
import { useTranslation } from 'react-i18next';

export const TicketAction = () => {
  const { t } = useTranslation();

  const info = useSelector((state) => state.stays.stay);
  const dispatch = useDispatch();

  // check
  if (!info.mean_id || !info.mean_type || !user.haveRight('stays.change_mean_status')) {
    return null;
  }

  /**
   * change mean status
   */
  const changeMeanStatus = () => {
    const { mean_type, mean_id, is_mean_active, id } = info;
    api
      .call('stays.change_mean_status', {
        mean_type,
        mean_id,
        status: !is_mean_active,
      })
      .then(() => {
        dispatch(getStayInfo(id));
      });
  };

  return (
    <Button theme={themes.red} fontSize="16px" onClick={changeMeanStatus}>
      <i className="fa fa-unlock-alt"></i>
      {info.is_mean_active ? t('buttons.Заблокировать ') : t('buttons.Разблокировать ')}
      {t(`sections.${getMeanType3(info.mean_type)}`)}
    </Button>
  );
};
