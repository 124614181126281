import React, { useState } from 'react';
import { Button, themes, ButtonContainer } from 'libs/ui';
import { FormBox, Header, CommentBox, CommentFieldName, CommentInput } from './styledComponents';

const ChangeStatusForm = ({ type, info, removeFormInfo, changeStatus, setWarning }) => {
  const [comment, setComment] = useState('');

  const isBlocked = () => {
    return info.status === 'blocked' || info.status === 'blocked_by_client';
  };

  const getHeader = () => {
    switch (type) {
      case 'rfid':
        return `${t('sections.RFID')} ${info.value}`;
      case 'epm':
        return `${t('sections.БСК')} ${info.value}`;
      default:
        return '';
    }
  };

  const getButtonName = () => {
    const formatType = type === 'rfid' ? t('sections.RFID') : t('sections.БСК');
    return isBlocked() ? `${t('buttons.Разблокировать ')} ${formatType}` : `${t('buttons.Заблокировать ')} ${formatType}`;
  };

  const getChangedStatus = () => {
    return isBlocked() ? 'active' : 'blocked';
  };

  const changeStatusHandler = () => {
    removeFormInfo();
    const params = {
      id: info.id,
      status: getChangedStatus(),
      comment,
    };

    if (isBlocked()) {
      changeStatus(params);
      cancelForm();
    } else {
      setWarning(info.value, params);
    }
  };

  const cancelForm = () => {
    removeFormInfo();
  };

  return (
    <FormBox type={type}>
      <Header>{getHeader()}</Header>
      <CommentBox>
        <CommentFieldName>{t('filter.Комментарий')}</CommentFieldName>
        <CommentInput placeholder={t('filter.Напишите свой комментарий')} value={comment} onChange={(e) => setComment(e.target.value)} />
      </CommentBox>
      <ButtonContainer space="20px" justifyContent="flex-end">
        <Button onClick={cancelForm}>{t('buttons.Отменить')}</Button>
        <Button theme={isBlocked() ? themes.green : themes.red} onClick={changeStatusHandler} status={info.status}>
          <img width="10" src="/images/icon_lock.png" alt="" />
          {getButtonName()}
        </Button>
      </ButtonContainer>
    </FormBox>
  );
};

export default ChangeStatusForm;
