import { user } from 'services';
import { StyledField } from 'libs/form';

import { useTranslation } from 'react-i18next';

export const LK = ({ lk_login }) => {
  const { t } = useTranslation();
  // console.log('🚀 ~ LK ~ lk_login:', lk_login);
  const useLogin = user.canUserLogin();
  if (!useLogin) {
    return null;
  }

  return (
    <>
      <StyledField type="text" label={t('form.Пароль ЛК')} name="lkpwd" noaf={true} />
      {lk_login && (
        <StyledField className="checkbox1" type="checkbox" label={t('form.Безлимитный')} name="lk_unlimited" />
      )}
    </>
  );
};
