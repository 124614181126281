export const required = (t) => (value) => {
  return value || value === 0 ? undefined : t('validation.Обязательное поле');
};

export const itn = (t) => (value) => {
  if (value) {
    return value.length === 12 || value.length === 10 ? undefined : t('validation.В строке должно быть 10 или 12 символов');
  }
};

export const iec = (t) => (value) => {
  if (value) {
    return value.length === 9 ? undefined : t('validation.В строке должно быть 9 символов');
  }
};

export const psrn = (t) => (value) => {
  if (value) {
    return value.length === 13 ? undefined : t('validation.В строке должно быть 13 символов');
  }
};

export const psrnsp = (t) => (value) => {
  if (value) {
    return value.length === 15 ? undefined : t('validation.В строке должно быть 15 символов');
  }
};

export const okpo = (t) => (value) => {
  if (value) {
    return value.length === 8 || value.length === 10 ? undefined : t('validation.В строке должно быть 8 или 10 символов');
  }
};

export const rfid = (t) => (value) => {
  if (value) {
    return value.length === 6 ? undefined : t('validation.В строке должно быть 6 символов');
  }
};

export const tariffIsCorrect = (t, tariffs) => (value) => {
  if (value) {
    if (Array.isArray(tariffs) && tariffs.length) {
      const isCorrect = tariffs.some((tariff) => {
        return tariff.name === value;
      });
      return isCorrect ? undefined : t('validation.Не найдено тарифа с данным именем');
    } else {
      return t('validation.Не найдено тарифа с данным именем');
    }
  }
};

export const min1 = (t) => (value) => {
  if (value === 0) {
    return t('validation.Заполните поле');
  }
  return parseInt(value) === 0 ? t('validation.Период не может быть равен 0 минут') : undefined;
};
