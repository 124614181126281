import { user, data } from 'services';
import { GridRow, OptionsCell, Cell } from 'libs/ui';
import { useTranslation } from 'react-i18next';

const Row = (props) => {
  const { t } = useTranslation();

  const editEntity = () => {
    props.setItemInfo({
      id: props.id,
      is_active: props.is_active,
      card_id: props.card_id,
      name: props.name,
    });
    props.updateFormDisplayHandler(true);
  };

  const deleteEntity = () => {
    props.deleteItem(props.id, () => {
      props.getEntries({ offset: 0 }, null, true);
    });
  };

  const editRight = user.haveRight('collection.update');

  const getMenu = () => {
    //console.log(props)
    const out = [];
    // if (user.haveRight('collection.update')) {
    //   out.push({ text: 'Редактировать', func: editEntity });
    // }
    if (user.haveRight('collection.delete')) {
      out.push({ text: t('buttons.Удалить'), func: deleteEntity });
    }
    return out;
  };

  const menu = getMenu();

  return (
    <GridRow status={props.active ? 'active' : 'blocked'} onClick={editRight ? editEntity : undefined}>
      <Cell>{props.name}</Cell>
      <Cell>{data.getDictValue('users.status', props.is_active)}</Cell>
      <Cell>{props.card_id}</Cell>
      <OptionsCell disabled={props.formIsShow || !menu.length} options={menu} left={-66} />
    </GridRow>
  );
};

export default Row;
