import React, { Component } from 'react';
import { change } from 'redux-form';
import moment from 'moment';
import Calendar from 'rc-calendar';
import InputMask from 'react-input-mask';
import ruRU from 'rc-calendar/lib/locale/ru_RU';
import TimePickerPanel from 'rc-time-picker/lib/Panel';

import { InputBoxContainer, InputBox } from './Date.styled';

const maskChar = '_';
const dateFormat = 'DD.MM.YYYY';
const timeFormat = 'HH:mm';
const dateTimeFormat = dateFormat + ' ' + timeFormat;

export class Date extends Component {
  state = {
    showCalendar: false,
    val: '',
  };

  popup = null;
  timePickerElement = (<TimePickerPanel defaultValue={moment('00:00:00', timeFormat)} />);
  timePickerElement2 = (<TimePickerPanel defaultValue={moment('23:59:59', timeFormat)} />);

  componentDidMount() {
    this.time = moment(); //'23:59:59', timeFormat);
    this.setState({ val: this.prepareDateValue(this.props.input.value) });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.input.value !== this.props.input.value) {
      this.setState({ val: this.prepareDateValue(this.props.input.value) });
    }
  }

  /**
   * get output format
   * @returns
   */
  getFormat = () => {
    return this.props.type === 'dateTime' ? dateTimeFormat : dateFormat;
  };

  /**
   * get foramtted string from moment
   * @param {*} val
   */
  prepareDateValue = (val) => {
    // console.log('🚀 ~ val:', val);
    const format = this.getFormat();
    if (typeof val === 'string') {
      val = moment(val);
    }
    return val.isValid() ? val.format(format) : '';
  };

  /**
   * save date to form
   * @param {*} date moment
   */
  setDate = (date) => {
    const { name } = this.props.input;
    const { dispatch, form } = this.props.meta;
    const { onChange } = this.props;
    if (onChange) {
      onChange(date);
    }
    if (dispatch) {
      dispatch(change(form, name, date));
    }
  };

  /**
   * toggle cal
   * @returns
   */
  toggleCalendar = () => {
    const { disabled } = this.props;
    if (disabled) return;
    if (!this.state.showCalendar) {
      document.addEventListener('click', this.handleOutsideClick, true);
    } else {
      this.setState({ val: this.prepareDateValue(this.props.input.value) });
      document.removeEventListener('click', this.handleOutsideClick, true);
    }
    this.setState({ showCalendar: !this.state.showCalendar });
  };

  /**
   * hide cal
   * @param {*} e
   * @returns
   */
  handleOutsideClick = (e) => {
    if (this.popup) {
      if (this.popup.contains(e.target)) {
        return;
      } else {
        this.toggleCalendar();
      }
    }
  };

  /**
   * get display value for input
   * @param {*} value
   * @returns
   */
  getDisplayedValue = () => {
    const val = this.state.val; // this.props.input.value;
    if (!val) {
      return '';
    }

    return val;

    // const mVal = moment(val);
    // const format = this.getFormat();
    // return mVal.format(format);

    // if (this.props.input.value) {
    //   return type === 'dateTime'
    //     ? moment(this.props.input.value)
    //         .format('lll')
    //         .replace(/[г., ]{4}/, '')
    //         .replace(/\./, '')
    //     : moment(this.props.input.value)
    //         .format('ll')
    //         .replace(/[г., ]{4}/, '')
    //         .replace(/\./, '');
    // } else return '';
  };

  change = (event) => {
    // console.log('🚀 ~ val:', val);
    const val = event.target.value;
    const mVal = moment(val, dateTimeFormat, true);
    // console.log('🚀 ~ val:', val, mVal);
    if (val.includes(maskChar)) {
      this.setState({ val });
    }
    if (mVal.isValid()) {
      this.setState({ val });
      this.setDate(mVal);
    }
  };

  render() {
    const { className, label, placeholder, type, withoutIcon, ...rest } = this.props;
    const timeel = this.props.input.name.indexOf('_to') === -1 ? this.timePickerElement : this.timePickerElement2;
    const changeTime = className.indexOf('cl2359') !== -1;
    const changeTimeZero = className.indexOf('cl0000') !== -1;
    const format = type === 'dateTime' ? dateTimeFormat : dateFormat;
    const mask = type === 'dateTime' ? '99.99.9999 99:99' : '99.99.9999';
    // console.log('🚀 ~ render ~ withoutIcon:', withoutIcon);
    // console.log('🚀 ~ render ~ moment(rest.input.value):', rest.input.value, moment(rest.input.value));
    return (
      <InputBoxContainer className={className}>
        <label>{label}</label>
        <div>
          <InputBox onClick={this.toggleCalendar}>
            {/* <input placeholder={placeholder} value={this.getDisplayedValue(type)} mask={mask} onChange={this.change} /> */}
            <InputMask placeholder={placeholder} value={this.getDisplayedValue()} mask={mask} onChange={this.change} maskChar={maskChar} />
            {!withoutIcon && <i className="fa fa-calendar" />}
          </InputBox>
          {this.state.showCalendar && (
            <div
              ref={(popup) => {
                this.popup = popup;
              }}
              style={{ position: 'absolute' }}
            >
              <Calendar
                value={moment(rest.input.value)}
                selectedValue={moment(rest.input.value)}
                timePicker={this.props.type === 'dateTime' ? timeel : null}
                format={format}
                onSelect={(date) => {
                  //console.log('🚀 ~ render ~ date', date);
                  this.setDate(date);
                }}
                onChange={(date) => {
                  // console.log('🚀 ~ render ~ date:', date);
                  if (changeTime && this.time.minutes() === date.minutes() && this.time.hours() === date.hours()) {
                    date.hours(23);
                    date.minutes(59);
                  }
                  this.setDate(date);

                  if (changeTimeZero && this.time.minutes() === date.minutes() && this.time.hours() === date.hours()) {
                    date.hours(0);
                    date.minutes(0);
                  }
                  this.setDate(date);
                }}
                locale={ruRU}
                className={'calendar'}
                showDateInput={false}
              />
            </div>
          )}
        </div>
      </InputBoxContainer>
    );
  }
}
