import React, { useEffect } from 'react';
import { reduxForm } from 'redux-form';
import styled from 'styled-components';
import moment from 'moment';

import { useTranslation } from 'react-i18next';

import { DateSelect, ParkingFilter, Container, Input, DoubleField, DateTime, ButtonsContainer } from 'libs/form';
import { UseBtn, ResetBtn } from 'libs/ui';

const StyledContainer = styled(Container)`
  form {
  }
`;

const initialValues = {
  datetime_from: moment().startOf('day').format(),
  datetime_to: moment().endOf('day').format(),
};

const Filter = ({ handleSubmit, reset, change, setInitial }) => {
  const { t } = useTranslation();

  useEffect(() => {
    setInitial(initialValues);
  }, [setInitial]);

  return (
    <StyledContainer>
      <form onSubmit={handleSubmit}>
        <DateSelect from_name="datetime_from" to_name="datetime_to" change={change}>
          <DoubleField>
            <DateTime width="160px" name="datetime_from" type="dateTime" label={t('grid.Дата и время')} />
            <DateTime width="160px" name="datetime_to" type="dateTime" />
          </DoubleField>
        </DateSelect>

        <Input name="fn" label={t('form.Номер ФН')} placeholder={t('form.Номер ФН')} type="text" />
        <Input width="220px" name="name" type="text" label={t('form.Клиент')} placeholder={t('form.Клиент')} />

        <DoubleField>
          <Input
            width="80px"
            name="amount_from"
            type="text"
            label={t('grid.Сумма, ₽')}
            placeholder={t('filter.От')}
            naturalNumber
          />
          <Input width="80px" name="amount_to" type="text" placeholder={t('filter.До')} naturalNumber />
        </DoubleField>

        <ParkingFilter />

        <ButtonsContainer>
          <ResetBtn onClick={reset} />
          <UseBtn />
        </ButtonsContainer>
      </form>
    </StyledContainer>
  );
};

export default reduxForm({
  form: 'discountFilter',
  destroyOnUnmount: false,
  initialValues: initialValues,
})(Filter);
