import { useTranslation } from 'react-i18next';

import { data } from 'services';
import { getDate, getVehicleClassById } from 'services/types';
import { ParkingCell, StatusCell } from 'libs/ui';

export const getTableParams = (t) => {
  // get row class
  const getRowClass = (row) => {
    let out = '';
    out += ' ' + row.status;
    return out;
  };

  // const { t } = useTranslation();

  const getFields = (props) => {
    const fields = [
      { name: 'mean_id', title: t('sections.ГРЗ'), width: 170, sortField: 'mean_id' },
      {
        name: 'is_blocked',
        title: t('dict_filter.Список'),
        width: 120,
        renderCell: true,
        style: { paddingLeft: '15px' },
        render: (val, other) => {
          const title = data.getDictValue('plates.list', val);
          return (
            <StatusCell {...other} status={!val ? 'active' : 'blocked'}>
              {title}
            </StatusCell>
          );
        },
      },
      {
        name: 'start_at',
        title: t('form.Дата действия с'),
        width: 210,
        sortField: 'start_at',
        render: (val) => getDate(val),
      },
      {
        name: 'end_at',
        title: t('form.Дата действия по'),
        width: 210,
        sortField: 'end_at',
        render: (val) => getDate(val),
      },
    ];
    if (!props.clientId) {
      fields.push({ name: 'client_name', title: t('form.Клиент'), width: 145 });
    }
    fields.push({
      name: 'vehicle_class',
      title: t('dict_filter.Класс ТС'),
      width: 160,
      sortField: 'vehicle_class',
      render: (val) => getVehicleClassById(val),
    });
    fields.push({ name: 'comment', title: t('form.Причина внесения'), width: 360 });
    fields.push({ name: 'owner', title: t('form.Инициатор'), sortField: 'owner' });

    if (window.config.central) {
      fields.unshift({
        name: 'parking_id',
        title: t('grid.Парковка'),
        render: (val) => <ParkingCell parking_id={val} cmp={'span'} />,
        width: 140,
      });
    }

    return fields;
  };

  return { getFields, getRowClass, withComment: true };
};
