import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, themes, ButtonContainer } from 'libs/ui';
import { setTariff } from 'store/ducks/models/stays';
import { getTariffList } from 'store/ducks/models/tariffs';
import Warning from 'modules/components/warnings';
import { Popup2 } from 'libs/ui';
import { AttentionIcon, NewTicketContent, NewTicketHeader, NewTicketInfo } from './set-tariff.styled';

const SetTariff = (props) => {
  const { t } = useTranslation();
  const [tariff, setTariffState] = useState('');
  const [tariffs, setTariffs] = useState([]);
  const [confirm, setConfirm] = useState(false);

  useEffect(() => {
    props.getTariffList({ status: 'active', parking_id: props.parkingId }, [], (data) => {
      let selectedTariff = props.tariffId;

      if (data.items.length) {
        const found = data.items.some((el) => el.id === selectedTariff);
        if (!found) {
          selectedTariff = data.items[0].id;
        }
      }

      setTariffs(data.items);
      setTariffState(selectedTariff);
    });
  }, [props]);

  const showConfirmForm = () => {
    setConfirm(true);
  };

  const handleTariffChange = (event) => {
    setTariffState(event.target.value);
  };

  const getTariffs = () => {
    return tariffs.map((tariff) => (
      <option key={tariff.id} value={tariff.id}>
        {tariff.name}
      </option>
    ));
  };

  if (!tariffs.length) {
    return null;
  }

  const disabled = !tariff || (tariff && tariff === props.tariffId);

  return (
    <>
      <Popup2 w={640}>
        <AttentionIcon src="/images/icon_!.png" alt="" />
        <NewTicketContent>
          <NewTicketHeader>{t('form.Смена тарифа')}</NewTicketHeader>
          <NewTicketInfo style={{ marginBottom: '20px' }}>
            {t('form.Тариф')}
            <select onChange={handleTariffChange} width="145px" value={tariff}>
              {getTariffs()}
            </select>
          </NewTicketInfo>
          <ButtonContainer space="20px" justifyContent={'flex-start'}>
            <Button fontSize="16px" onClick={props.close}>
              {t('buttons.Отменить')}
            </Button>
            <Button disabled={disabled} theme={themes.blue} fontSize="16px" onClick={showConfirmForm}>
              {t('buttons.Заменить')}
            </Button>
          </ButtonContainer>
        </NewTicketContent>
      </Popup2>
      {confirm && (
        <Warning
          width="480px"
          header={t('form.Тарификация данной парковочной сессии будет изменена. Продожить?')}
          cancelButton={{
            func: () => setConfirm(false),
            text: t('buttons.Отменить'),
          }}
          acceptButton={{
            func: () => {
              setConfirm(false);
              props.action({ stayId: props.id, tariffId: tariff });
              props.close();
            },
            text: t('buttons.Заменить'),
            theme: themes.blue,
          }}
        />
      )}
    </>
  );
};

SetTariff.propTypes = {
  id: PropTypes.any.isRequired,
  parkingId: PropTypes.any,
  tariffId: PropTypes.any.isRequired,
  close: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired,
};

const mapStateToProps = () => {
  return {};
};

export default withRouter(connect(mapStateToProps, { setTariff, getTariffList })(SetTariff));
