import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';

import { user } from 'services';
import { Submit, ButtonContainer } from 'libs/ui';
import { StyledField, required } from 'libs/form';

function ContactFaceForm(props) {
  const { t } = useTranslation();
  const rightUpdate = user.haveRight('clients.update');
  const validateFn = useMemo(() => required(t), [t]);

  return (
    <form onSubmit={props.handleSubmit}>
      <StyledField validate={validateFn} type="text" label={t('form.ФИО')} name="name" placeholder={t('form.Иванов Иван Иванович')} />
      <StyledField validate={validateFn} type="text" label={t('grid.Телефон')} name="tel_num" placeholder={t('grid.+7 (999) 999-99-99')} />
      <StyledField type="text" label={t('grid.Доп.телефон')} name="tel_num_2" placeholder={t('grid.+7 (999) 999-99-99')} />
      <StyledField type="text" label={t('form.E-mail')} name="email" placeholder={t('form.E-mail')} />
      <StyledField type="text" label={t('form.Доп. email')} name="email_2" placeholder={t('form.E-mail')} />
      <StyledField type="text" label={t('form.Веб-сайт')} name="website" placeholder={t('form.Веб-сайт')} />
      <StyledField type="text" label={t('form.Адрес')} name="address" placeholder="г. Санкт-Петербург, Загородный проспект 26, кв 43" />
      {rightUpdate && (
        <ButtonContainer>
          <Submit disabled={props.submitting} />
        </ButtonContainer>
      )}
    </form>
  );
}

export default connect((store, props) => ({
  form: 'ContactFaceForm_' + (props.info && props.info.id ? props.info.id : 'new'),
  initialValues: {
    ...props.info,
  },
}))(reduxForm()(ContactFaceForm));
