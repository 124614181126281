import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import moment from 'moment';

import { useTranslation } from 'react-i18next';

import {
  DateSelect,
  Container,
  Input,
  DoubleField,
  DateTime,
  ButtonsContainer,
  DictFilter,
  ParkingFilter,
  SourceFilter,
} from 'libs/form';
import { ResetBtn, UseBtn } from 'libs/ui';

const initialValues = {
  timestamp_from: moment().startOf('day').format(),
  timestamp_to: moment().endOf('day').format(),
};

const Filter = ({ handleSubmit, reset, change, filter, setInitial }) => {
  useEffect(() => {
    setInitial(initialValues);
  }, [setInitial]);

  const { t } = useTranslation();

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <DateSelect from_name="timestamp_from" to_name="timestamp_to" change={change}>
          <DoubleField>
            <DateTime width="160px" name="timestamp_from" type="dateTime" label={t('grid.Дата и время')} />
            <DateTime width="160px" name="timestamp_to" type="dateTime" />
          </DoubleField>
        </DateSelect>

        <SourceFilter sources="events.source_type" parkingIdLocal={filter ? filter.parking_id : ''} />

        <DictFilter w={140} dict="events.type" />

        <Input
          label={t('inputs.Описание')}
          width="230px"
          name="message"
          type="text"
          placeholder={t('inputs.Введите название')}
        />

        <ParkingFilter last />

        <ButtonsContainer>
          <ResetBtn onClick={reset} />
          <UseBtn />
        </ButtonsContainer>
      </form>
    </Container>
  );
};

const mapStateToProps = (state) => {
  const filterName = 'eventsFilter';
  const filterValues = getFormValues(filterName)(state);

  return {
    filter: filterValues,
  };
};

const FilterForm = reduxForm({
  form: 'eventsFilter',
  destroyOnUnmount: false,
  initialValues: initialValues,
})(Filter);

export default connect(mapStateToProps, { getFormValues })(FilterForm);
