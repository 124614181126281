import React from 'react';
import { TableHeader, Total, GridBox, Grid, TableHead, TableHeadRow, TopRow } from 'libs/ui';
import Ride from './Row';
import { useTranslation } from 'react-i18next';

const RidesGrid = ({ entries, rides, zones, count, getXlsLink }) => {
  const { t } = useTranslation();

  const getRidesList = () => {
    const ridesList = [];
    const rideEntries = entries || rides;

    if (rideEntries) {
      Object.values(rideEntries).forEach((ride) => {
        ridesList.push(<Ride key={ride.id} zones={zones} {...ride} />);
      });
    }
    return ridesList;
  };

  return (
    <GridBox>
      <TopRow>
        <Total cnt={count} />
        {getXlsLink()}
      </TopRow>
      <Grid>
        <TableHead>
          <TableHeadRow>
            {window.config.central && <TableHeader width="140px">{t('grid.Парковка')}</TableHeader>}
            <TableHeader width="210px" sortField="timestamp">
              {t('grid.Дата и время')}
            </TableHeader>
            <TableHeader width="125px" sortField="vehicle_plate">
              {t('sections.ГРЗ')}
            </TableHeader>
            <TableHeader width="102px">{t('dict_filter.Список')}</TableHeader>
            <TableHeader width="75px">{t('dict_filter.Тип')}</TableHeader>
            <TableHeader width="90px">{t('dict_filter.Результат')}</TableHeader>
            <TableHeader width="92px">{t('grid.Зона из')}</TableHeader>
            <TableHeader width="105px">{t('grid.Узел/стойка')}</TableHeader>
            <TableHeader width="82px">{t('grid.Зона в')}</TableHeader>
            <TableHeader>{t('form.Держатель')}</TableHeader>
            <TableHeader width="105px">{t('dict_filter.Класс ТС')}</TableHeader>
            <TableHeader width="80px">{t('grid.Действия')}</TableHeader>
          </TableHeadRow>
        </TableHead>
        <tbody>{getRidesList()}</tbody>
      </Grid>
    </GridBox>
  );
};

export default RidesGrid;
