import styled, { css } from 'styled-components';
import { DataWrapper } from 'modules/components/card/styledComponents';

export const ProfileInfo = styled.div`
  display: flex;
  align-items: center;
`;
export const ClientData = styled.div`
  width: 590px;
  position: relative;
  form > div:last-child {
    margin-top: 20px;
  }
`;
export const PersonalDataHeader = styled.p`
  border-bottom: solid 1px #595d64;
  padding: 0 0 15px 0;
  font-size: 20px;
  color: #fff;
  opacity: 0.6;
`;
export const PersonalDataInputBox = styled.div`
  width: 590px;
  height: 40px;
  border-bottom: solid 1px #595d64;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const PersonalDataFieldName = styled.p`
  font-size: 13px;
  color: #fff;
  opacity: 0.6;
`;
export const PersonalDataInput = styled.input`
  width: 430px;
  height: 100%;
  font-size: 15px;
  background-color: #393e44;
  border: none;
  color: #fff;
  opacity: 0.6;
  &:focus {
    outline: none;
  }
`;
export const ProfileInfoData = styled.div`
  margin-left: 30px;
  color: #fff;
  font-size: 15px;
  div {
    display: flex;
    align-items: center;
  }
`;
export const Tabs = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
`;
export const Tab = styled.p`
  cursor: pointer;
  margin-right: 40px;
  padding-bottom: 6px;
  font-size: 16px;
  color: #3ea5ff;
`;
export const StyledDataWrapper = styled(DataWrapper)`
  display: block;
  padding: ${(props) => {
    if (
      props.selectedTab === 'rfid' ||
      props.selectedTab === 'epm' ||
      props.selectedTab === 'plates' ||
      props.selectedTab === 'subscription'
    ) {
      return '0';
    } else {
      return '25px 20px';
    }
  }};
`;
export const ToggleBox = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  align-items: center;
  p {
    font-size: 15px;
  }
  p:first-child {
    color: ${(props) => (props.isActive ? '#17b26e' : '#dde3e7')};
  }
  p:last-child {
    color: ${(props) => (props.isActive ? '#dde3e7' : '#d33d34')};
  }
`;
export const Toggle = styled.div`
  width: 38px;
  height: 20px;
  cursor: pointer;
  border-radius: 21px;
  background-color: ${(props) => (props.isActive ? '#17b26e' : '#d33d34')};
  margin: 0 15px;
  padding: 1px;
  div {
    position: relative;
    width: 18px;
    height: 18px;
    background-color: #ffffff;
    border-radius: 50%;
    transition: all 0.2s;
    left: ${(props) => (props.isActive ? '0' : '18px')};
  }
  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}
`;
export const Circle = styled.span`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #979797;
`;
export const ContactFaces = styled.div`
  width: 590px;
  position: relative;
`;
export const ContactFaceWrapper = styled.div`
  position: relative;
`;
export const Name = styled.p`
  font-size: 26px;
  margin-bottom: 15px;
`;
export const Type = styled.p`
  font-size: 15px;
  margin-bottom: 15px;
`;
export const Amount = styled.p`
  font-size: 20px;
  margin-right: 30px;
`;
export const Score = styled.p`
  font-size: 15px;
  margin-right: 30px;
`;
export const DataTab = styled(Tab)`
  border-bottom: ${(props) => {
    if (props.active === 'data') return 'solid 4px #3ea5ff';
  }};
  font-weight: ${(props) => {
    if (props.active === 'data') return 'bold';
  }};
`;
export const RfidTab = styled(Tab)`
  border-bottom: ${(props) => {
    if (props.active === 'rfid') return 'solid 4px #3ea5ff';
  }};
  font-weight: ${(props) => {
    if (props.active === 'rfid') return 'bold';
  }};
`;
export const EpmTab = styled(Tab)`
  border-bottom: ${(props) => {
    if (props.active === 'epm') return 'solid 4px #3ea5ff';
  }};
  font-weight: ${(props) => {
    if (props.active === 'epm') return 'bold';
  }};
`;

export const PlatesTab = styled(Tab)`
  border-bottom: ${(props) => {
    if (props.active === 'plates') return 'solid 4px #3ea5ff';
  }};
  font-weight: ${(props) => {
    if (props.active === 'plates') return 'bold';
  }};
`;

export const SubscriptionTab = styled(Tab)`
  border-bottom: ${(props) => {
    if (props.active === 'subscription') return 'solid 4px #3ea5ff';
  }};
  font-weight: ${(props) => {
    if (props.active === 'subscription') return 'bold';
  }};
`;

export const ZonesTab = styled(Tab)`
  border-bottom: ${(props) => {
    if (props.active === 'zoneslimit') return 'solid 4px #3ea5ff';
  }};
  font-weight: ${(props) => {
    if (props.active === 'zoneslimit') return 'bold';
  }};
`;

export const ZonesLKTab = styled(Tab)`
  border-bottom: ${(props) => {
    if (props.active === 'zoneslk') return 'solid 4px #3ea5ff';
  }};
  font-weight: ${(props) => {
    if (props.active === 'zoneslk') return 'bold';
  }};
`;
