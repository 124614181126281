import React from 'react';
import PropTypes from 'prop-types';

import { getAmount, getFormattedDateTime } from 'libs/common';
import { TableEl } from './table.styled';
import { useTranslation } from 'react-i18next';

export const Table = (props) => {
  const { items, headers } = props;

  const { t } = useTranslation();

  /**
   * headers
   */

  const headersEl = headers.map((el, idx) => {
    const { title, type, ...rest } = el;
    return (
      <th key={idx} {...rest}>
        {t(`grid.${title}`)}
      </th>
    );
  });

  const getValue = (val, type) => {
    switch (type) {
      case 'date':
        return getFormattedDateTime(val);
      case 'price':
        return getAmount(val);
      case 'boolean':
        const out = val ? 'да' : 'нет';
        return <span className={val ? 'true' : 'false'}>{out}</span>;
      default:
        return val;
    }
  };

  /**
   * cells
   */
  const itemsEl = items.map((row, idx) => {
    const cells = headers.map((header, idx1) => {
      const { fn, type } = header;
      const val = getValue(row[fn], type);
      return <td key={idx1}>{val}</td>;
    });
    return <tr key={idx}>{cells}</tr>;
  });
  return (
    <TableEl>
      <thead>
        <tr>{headersEl}</tr>
      </thead>
      <tbody>{itemsEl}</tbody>
    </TableEl>
  );
};

Table.propTypes = {
  items: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
};
