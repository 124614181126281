import { useState } from 'react';

import { TableReact } from 'libs/ui';
import { notify } from 'libs/common';
import { sortAdvList } from 'services/adv-service';
import { TableContent } from './list.styled';
import { RowActions } from './row-actions';
import { ActionsHeader } from './actions-header';
import { Media } from './forms/media';

import { useTranslation } from 'react-i18next';

/*
const initial = {
  main: [
    { id: '0', name: 'crm1.png', size: 15568, period: 5, date_from: '', date_to: '' },
    { id: '1', name: 'crm1.mp4', size: 1576100, period: 3, date_from: '', date_to: '' },
    { id: '2', name: 'crm2.png', size: 15568, period: 1, date_from: '', date_to: '' },
  ],
  other: [{ id: '0', name: 'crm3.png', size: 1568, period: 2, date_from: '', date_to: '' }],
};
*/

/**
 * table of adv component
 * @param {*} props
 * @returns
 */
export const List = (props) => {
  const { t } = useTranslation();

  const { data, setData, refreshData } = props;
  const [show, setShow] = useState(false);
  const [row, setRow] = useState(null);

  /**
   * update sort
   * @param {*} data
   */
  const updateSort = async (sortData) => {
    const newData = sortData(data);
    setData(newData);
    const out = newData.map((el) => el.id);
    await sortAdvList(out).catch((error) => {
      notify(t('messages.Ошибка сортировки списка'), error?.message);
    });
    //refreshData();
  };

  const columns = [
    {
      accessorKey: 'name',
      id: 'name',
      header: () => t('grid.Название'),
      size: 150,
    },
    {
      accessorKey: 'filesize',
      id: 'filesize',
      header: () => t('grid.Размер, байт'),
      size: 100,
    },
    {
      accessorKey: 'duration',
      id: 'duration',
      header: () => t('grid.Период, с'),
      cell: (props) => {
        return (props.row?.original?.duration / 1000).toFixed(2);
      },
      size: 70,
    },
    {
      accessorKey: 'datefrom',
      id: 'datefrom',
      header: () => t('form.Дата действия с'),
      size: 80,
    },
    {
      accessorKey: 'datetill',
      id: 'datetill',
      header: () => t('form.Дата действия по'),
      size: 80,
    },
    {
      accessorKey: 'timefrom',
      id: 'timefrom',
      header: () => t('form.Время действия с'),
      size: 90,
    },
    {
      accessorKey: 'timetill',
      id: 'timetill',
      header: () => t('form.Время действия по'),
      size: 90,
    },
    {
      id: 'actions',
      header: () => <ActionsHeader>{t('grid.Действия')}</ActionsHeader>,
      cell: (props) => <RowActions row={props.row} refreshData={refreshData} />,
      align: 'right',
    },
  ];

  /**
   * show media on row click
   * @param {*} row
   */
  const showMedia = (row) => {
    // console.log('🚀 ~ showMedia ~ row:', row);
    setRow(row);
    setShow(true);
  };

  /**
   * hide media
   */
  const closeMedia = () => {
    setShow(false);
  };

  return (
    <TableContent>
      <TableReact columns={columns} data={data} setData={updateSort} rowClick={showMedia} />
      {show && row && <Media row={row} close={closeMedia} />}
    </TableContent>
  );
};

export default List;
