import React from 'react';
import { useTranslation } from 'react-i18next';

import { ParkingFilter2, required, MultiselectView, StyledField, DarkMultiselect } from 'libs/form';

import types from 'services/tariffTypes';
import { SectionTitle } from './tariff-form-main.styled';

export const TariffFormMain = (props) => {
  const { t } = useTranslation();

  const { type, disabled, vehicleClasses, categories } = props;

  //const withSubscriptionPeriod = initialValue.subscription_period !== undefined;
  // const withSecondEntry = initialValue.data.secondentry_amount !== undefined;
  // const withSecondEntry = initialValue.data.secondentry_period !== undefined;

  if (!['individual', 'basic1', 'basic2', 'basic3', 'basic4'].includes(type)) {
    return null;
  }
  const isIndividual = type === 'individual';

  /**
   * get type options
   * @returns
   */
  const getTypeOptions = () => {
    return types.map((type) => {
      return (
        <option key={type.id} value={type.id}>
          {type.name}
        </option>
      );
    });
  };

  return (
    <>
      <StyledField noApperance validate={required} type="select" label={t('dict_filter.Тип')} name="type" disabled={true}>
        {getTypeOptions()}
      </StyledField>
      {isIndividual && (
        <>
          <ParkingFilter2 dark disabled={true} />
          <StyledField
            validate={required}
            placeholder={t('grid.Осень 2017')}
            type="text"
            label={t('grid.Название *')}
            name="name"
            disabled={disabled}
          />
          <DarkMultiselect
            validate={required}
            type="multiselect"
            label={t('form.Класс ТС *')}
            name="vehicle_class"
            placeholder={t('form.Выберите класс ТС')}
            disabled={disabled}
            data={vehicleClasses}
            additionalComponent={MultiselectView}
          />
          <DarkMultiselect
            type="multiselect"
            label={t('form.Категория')}
            name="vehicle_category"
            placeholder={t('form.Выберите категорию ТС')}
            disabled={disabled}
            data={categories}
            additionalComponent={MultiselectView}
          />
        </>
      )}
      <StyledField
        className="shortfield"
        validate={required}
        placeholder={t('form.08 11 2017')}
        type="dateTime"
        label={t('form.Начало *')}
        name="time_from"
        disabled={disabled}
      />
      <StyledField
        type="text"
        placeholder={t('form.15 мин')}
        label={t('form.Время на выезд')}
        name="time_to_leave"
        mask={{ suffix: t('form. мин') }}
        disabled={disabled}
      />
      <SectionTitle>{t('sections.Абонемент')}</SectionTitle>
      <StyledField
        type="text"
        placeholder={t('form.Цена абонемента')}
        label={t('form.Цена абонемента')}
        name="subscription_amount"
        mask={{ suffix: t('form. руб') }}
        disabled={disabled}
      />
      {/* withSubscriptionPeriod && (*/}
      <StyledField
        type="text"
        placeholder={t('grid.30 дней')}
        label={t('form.Срок действия')}
        name="subscription_period"
        mask={{ suffix: t('grid. дней') }}
        disabled={disabled}
      />
      {/*)*/}
      {/* {!isIndividual && ( */}
      <>
        <SectionTitle>{t('form.Повторный въезд')}</SectionTitle>
        <StyledField
          type="text"
          placeholder={t('form.15 мин')}
          label={t('form.Повторный платный въезд')}
          name="secondentry_interval"
          mask={{ suffix: t('form. мин') }}
          disabled={disabled}
        />
      </>
      {/* )} */}

      {
        /*withSecondEntry && */ !isIndividual && (
          <>
            <StyledField
              type="text"
              placeholder={t('form.10 руб')}
              label={t('form.Цена первого периода')}
              name="secondentry_amount"
              mask={{ suffix: t('form. руб') }}
              disabled={disabled}
            />
            <StyledField
              type="text"
              placeholder={t('form.15 сек')}
              label={t('grid.Первый период')}
              name="secondentry_period"
              mask={{ suffix: t('form. мин') }}
              disabled={disabled}
            />
            <StyledField
              type="text"
              placeholder={t('form.15 сек')}
              label={t('form.Отмена платного периода')}
              name="secondentry_duration"
              mask={{ suffix: t('form. мин') }}
              disabled={disabled}
            />
          </>
        )
      }
    </>
  );
};
