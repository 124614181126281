import { Button, themes } from 'libs/ui';
import zones from 'services/zones';
import { LimitByTimeRow } from './limit-by-time-row';
import { Content, Table } from './limit-by-time.styled';

import { useTranslation } from 'react-i18next';

const def = {
  zone_id: '',
  limit: 0,
  weekdays: [true, true, true, true, true, true, true],
  start_time: '00:00:00',
  end_time: '23:59:59',
};

export const LimitByTime = (props) => {
  const { t } = useTranslation();

  const { data, onChange, colors } = props;

  /**
   * change limit
   * @param {*} idx
   * @param {*} event
   */
  const change = (id, name, val) => {
    const out = data.splice(0);
    out[id][name] = val;
    // console.log('🚀 ~ change ~ out:', out);
    onChange(out);
  };

  /**
   *  add new row
   */
  const add = () => {
    const items = zones.getZones();
    const out = data.splice(0);
    out.push({ ...JSON.parse(JSON.stringify(def)), zone_id: items[0].zone_id });
    onChange(out);
  };

  /**
   * del row
   * @param {*} id
   */
  const del = (idx) => {
    const out = data.splice(0);
    out.splice(idx, 1);
    onChange(out);
  };

  const getZonesEl = () => {
    return data.map((el, idx) => {
      const row = { ...el, color: colors ? colors[el.zone_id] : '#000' };
      return <LimitByTimeRow key={idx} row={row} id={idx} onChange={change} onDel={del} />;
    });
  };

  const zonesEl = getZonesEl();

  return (
    <Content>
      <Table>
        <thead>
          <tr>
            <th rowSpan={2}>{t('tables.Парковочная зона')}</th>
            <th rowSpan={2}>{t('grid.Начало')}</th>
            <th rowSpan={2}>{t('tables.Окончание')}</th>
            <th rowSpan={2}>{t('sections.Лимит ТС')}</th>
            <th rowSpan={2} width={30}></th>
            <th colSpan={7}>{t('tables.День недели')}</th>
            <th rowSpan={2}></th>
          </tr>
          <tr>
            <th>{t('tables.Пн')}</th>
            <th>{t('tables.Вт')}</th>
            <th>{t('tables.Ср')}</th>
            <th>{t('tables.Чт')}</th>
            <th>{t('tables.Пт')}</th>
            <th>{t('tables.Сб')}</th>
            <th>{t('tables.Вс')}</th>
          </tr>
        </thead>
        <tbody>{zonesEl}</tbody>
      </Table>
      <Button theme={themes.gray} onClick={add}>
        {t('buttons.Добавить')}
      </Button>
    </Content>
  );
};
