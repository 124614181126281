import React, { useState } from 'react';
import { Button } from 'libs/ui';
import { StyledButtonContainer, StyledInput, Form1, Permissions } from './permForm.styled';
import { useTranslation } from 'react-i18next';

const Form = (props) => {
  const { t } = useTranslation();

  const [permissions, setPermissions] = useState(props.initialValues.permissions);
  const [flt, setFlt] = useState('');

  const setPerm = (e, name) => {
    const val = e.target.checked;
    props.updatePermission(props.id, name, val);
    const out = [...permissions];
    const idxOk = out.findIndex((el) => el.permission_id === name);
    out[idxOk].is_allowed = val;
    setPermissions(out);
  };

  const filter = (e) => {
    setFlt(e.target.value);
  };

  const filteredPermissions = permissions.filter((el) =>
    el.permission_description.toLowerCase().includes(flt.toLowerCase())
  );

  return (
    <form>
      <Form1>
        <Permissions>
          {filteredPermissions.map((el, idx) => (
            <label key={idx}>
              <input
                onChange={(e) => setPerm(e, el.permission_id)}
                type="checkbox"
                name={el.permission_id}
                checked={el.is_allowed}
              />
              <span>{el.permission_description}</span>
            </label>
          ))}
        </Permissions>
      </Form1>
      <StyledButtonContainer space="20px">
        <StyledInput
          value={flt}
          onChange={filter}
          type="text"
          label={t('grid.Фильтр')}
          placeholder={t('grid.Фильтр')}
          name="flt"
        />
        <Button
          type="button"
          onClick={() => {
            props.displayHandler(false);
          }}
        >
          {t('buttons.Закрыть')}
        </Button>
      </StyledButtonContainer>
    </form>
  );
};

export default Form;
