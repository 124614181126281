import { useState, useEffect } from 'react';

import { notify } from 'libs/common';
import { GridContainer, Toggle } from 'libs/ui';
import { getAdvList } from 'services/adv-service';
import { Content, Title, Info, Left, Right, TitleRight } from './index.styled';
import { UploadForm } from './forms/upload-form';
import { List } from './list';

import { useTranslation } from 'react-i18next';

// https://dev.to/griffadev/beautiful-drag-and-drop-interactions-with-react-hooks-4obo
// https://codesandbox.io/s/react-table-drag-and-drop-sort-rows-with-dnd-kit-btpy9?file=/src/DraggableTableRow.jsx
// https://tanstack.com/table/v8/docs/guide/column-defs
// https://github.com/TanStack/table/blob/main/examples/react/column-sizing/src/index.css
// https://solar-resonance-568277.postman.co/workspace/New-Team-Workspace~c6b33463-ce27-4ef6-a299-f7f8b5f4649a/collection/3931594-baf43242-b5f1-4d13-9a21-5b63b91ff0a8

const types = ['payment.block1', 'payment.block2'];

const Adv = (props) => {
  //console.log('🚀 ~ Adv ~ props:', props);
  const search = new URLSearchParams(props.location.search);
  const def = search.get('type');

  const { t } = useTranslation();

  const [type, setType] = useState(def ? def : types[0]);
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(0);

  /**
   * get data
   */
  useEffect(() => {
    let _mounted = true;
    const getList = async () => {
      let err = false;
      const list = await getAdvList(type).catch((error) => {
        err = true;
        notify(t('messages.Ошибка получения списка'), error);
      });
      if (!err) {
        const out = list.items.map((el) => {
          el.id = el.item_id;
          return el;
        });
        if (_mounted) {
          setData(out);
        }
        // initial[type]
      }
    };
    getList();
    return () => {
      _mounted = false;
    };
  }, [type, refresh]);

  useEffect(() => {
    props.history.push({
      //pathname: '/dresses',
      search: '?type=' + type,
    });
  }, [type]);

  /**
   * change type
   * @param {*} event
   */
  const changeType = (event) => {
    const val = event.target.checked;
    const newType = val ? types[1] : types[0];
    setType(newType);
  };

  const setTypeOne = (n) => {
    setType(types[n]);
  };

  /**
   * refresh data
   */
  const refreshData = () => {
    const newRefresh = refresh + 1;
    setRefresh(newRefresh);
  };

  return (
    <GridContainer>
      <Content>
        <Info>
          <Left>
            <Title>
              {t('source_filter.АПП')}
              <TitleRight>
                <Toggle
                  name="type"
                  theme="two"
                  label={t('filter.Главный экран')}
                  label2={t('filter.Остальные')}
                  onChange={changeType}
                  checked={type === types[1]}
                  setOne={() => setTypeOne(0)}
                  setTwo={() => setTypeOne(1)}
                />
              </TitleRight>
            </Title>
            {/*<Desc>Главный экран. Доступные форматы PNG, MP4 c разрешением 1024*300</Desc>*/}
          </Left>
          <Right>
            <UploadForm refreshData={refreshData} type={type} />
          </Right>
        </Info>
      </Content>
      <List type={type} data={data} setData={setData} refreshData={refreshData} />
    </GridContainer>
  );
};

export default Adv;
