import React from 'react';

import { user } from 'services';
import {
  GridUpdateMessage,
  Button,
  themes,
  TableHeader,
  GridBox,
  Grid,
  TableHead,
  TableHeadRow,
  TopRow,
} from 'libs/ui';
import Window from 'modules/components/windows/UpdateWindow';
import Row from './Row';
import Form from './Form';

import { useTranslation } from 'react-i18next';

const BenefitsGrid = (props) => {
  const { t } = useTranslation();

  const getItems = () => {
    const items = props.entries;
    const { updateBenefit, getEntries } = props;
    const other = { updateBenefit, getEntries };

    let list = [];
    if (items) {
      for (let key in items) {
        list.push(
          <Row
            key={items[key].id}
            formIsShow={props.interface.showUpdateForm}
            setItemInfo={props.setItemInfo}
            updateFormDisplayHandler={props.updateFormDisplayHandler}
            {...items[key]}
            {...other}
          />
        );
      }
    }
    return list;
  };

  const addRight = user.haveRight('benefit_types_check.add');

  return (
    <GridBox>
      {addRight && (
        <TopRow>
          <p />
          <Button theme={themes.blue} onClick={() => props.addFormDisplayHandler(true)}>
            <i className="fa fa-plus" />
            {t('buttons.Добавить')}
          </Button>
        </TopRow>
      )}
      <Grid>
        <TableHead>
          <TableHeadRow>
            <TableHeader sortField="name" width="250px">
              {t('form.Клиент')}
            </TableHeader>
            <TableHeader sortField="status" width="160px">
              {t('grid.Статус')}
            </TableHeader>
            <TableHeader sortField1="fn">{t('form.Номер ФН')}</TableHeader>
            <TableHeader sortField1="min_amount" width="150px">
              {t('grid.Минимальная сумма')}
            </TableHeader>
            <TableHeader sortField1="amount" width="150px">
              {t('grid.Сумма скидки')}
            </TableHeader>
            <TableHeader width="80px">{t('grid.Действия')}</TableHeader>
          </TableHeadRow>
        </TableHead>
        <tbody>{getItems()}</tbody>
      </Grid>
      {props.interface.showAddForm && (
        <Window
          type="add"
          header={t('form.Новая скидка')}
          Form={Form}
          displayHandler={props.addFormDisplayHandler}
          updateMessageDisplayHandler={props.updateMessageDisplayHandler}
          addEntity={props.addBenefit}
          getEntries={props.getEntries}
        />
      )}
      {props.interface.showUpdateForm && (
        <Window
          type="update"
          header={props.itemInfo.name}
          Form={Form}
          displayHandler={props.updateFormDisplayHandler}
          updateMessageDisplayHandler={props.updateMessageDisplayHandler}
          updateEntity={props.updateBenefit}
          getEntries={props.getEntries}
          id={props.itemInfo.id}
          initialValues={{
            name: props.itemInfo.name,
            status: props.itemInfo.status,
            fn: props.itemInfo.data.fn,
            min_amount: Math.round(props.itemInfo.data.min_amount / 100),
            amount: Math.round(props.itemInfo.data.amount / 100),
          }}
        />
      )}
      {props.interface.showUpdateMessage ? (
        <GridUpdateMessage>{t('mesages.Изменение сохранено')}</GridUpdateMessage>
      ) : null}
    </GridBox>
  );
};

export default BenefitsGrid;
