import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { change, formValueSelector } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { FieldComponent, min1 } from 'libs/form';
import { themes } from 'libs/ui';
import { getTariffDuration } from 'services/types';
import { AddButton, InputList, InputsPlace, Sums, FieldPlace, InfoPlace } from './periods-loop-inputs.styled';

export const PeriodsLoopInputs = (props) => {
  const { t } = useTranslation();

  const { fields, disabled, meta, tableFields, zone, extended } = props;
  const selector = formValueSelector(meta.form);
  const dispatch = useDispatch();
  const currentLoop = useSelector((state) => {
    const zones = selector(state, 'zones');
    return zones && zones[zone] ? zones[zone].loop : 0;
  });
  const defaultPeriod = extended ? { period: '60', amount: '0', count: '1', loop: false } : { period: '60', amount: '0' };

  /**
   * add period
   */
  const addPeriod = () => {
    fields.push(defaultPeriod);
  };

  // default vals
  useEffect(() => {
    if (fields.length === 0) {
      addPeriod();
    }
    /*
    fields.forEach((__field, idx) => {
      const item = fields.get(idx);
      if (item.loop === undefined) {
        dispatch(change(meta.form, 'zones.' + zone + '.periods[' + idx + '].count', 1));
        dispatch(change(meta.form, 'zones.' + zone + '.periods[' + idx + '].loop', false));
      }
    });
    */
  }, [fields]);

  /**
   * get current days and sum
   * @param {*} id
   * @returns
   */
  const getSum = (id) => {
    const periods = fields.getAll();
    const currentPeriod = fields.get(id);

    if (!Array.isArray(periods) || !currentPeriod) {
      return null;
    }

    let sumPeriods = 0;
    let sumAmounts = 0;

    const periodIndex = periods.findIndex((__period, index) => {
      return index === id;
    });

    if (periodIndex !== -1) {
      for (let i = 0; i <= periodIndex; i++) {
        if (sumPeriods || sumPeriods === 0) {
          sumPeriods += Number(periods[i].period) * (periods[i].count ? periods[i].count : 1);
        }
        if (sumAmounts || sumAmounts === 0) {
          sumAmounts += Number(periods[i].amount) * (periods[i].count ? periods[i].count : 1);
        }
      }
    }

    return (
      <Sums>
        <p>{getTariffDuration(sumPeriods)}</p>
        <p>
          {sumAmounts} {t('grid.₽')}
        </p>
      </Sums>
    );
  };

  /**
   * delete period
   * @param {*} index
   */
  const deletePeriod = (index) => {
    const { form } = meta;
    const cnt = fields.length;

    // change loop value
    if (index === cnt - 1 && parseInt(currentLoop) === index) {
      const newLoop = (index - 1).toString();
      dispatch(change(form, 'zones.' + zone + '.loop', newLoop));
    }
    fields.remove(index);
  };

  const validatePeriod = useCallback(min1(t), [t]);

  //console.log('🚀 == periods loop inputs');
  return (
    <InputList>
      {fields.map((field, index) => {
        return (
          <li key={index}>
            <InputsPlace $extended={extended}>
              <FieldPlace error={meta.error ? meta.error[index] : null} w={tableFields[0].w} right={tableFields[0].right}>
                <FieldComponent validate={validatePeriod} type="text" name={`${field}.period`} naturalNumber maxValueLength={5} disabled={disabled} />
                <span>{t('form. мин')}</span>
              </FieldPlace>
              <FieldPlace w={tableFields[1].w} right={tableFields[1].right}>
                <FieldComponent type="text" name={`${field}.amount`} naturalNumber withMinus fractional maxValueLength={7} disabled={disabled} />
                <span>{t('grid.₽')}</span>
              </FieldPlace>
              {extended && (
                <>
                  <FieldPlace w={tableFields[2].w} right={tableFields[2].right}>
                    <FieldComponent type="text" name={`${field}.count`} naturalNumber maxValueLength={3} disabled={disabled} />
                  </FieldPlace>
                  <FieldPlace w={tableFields[3].w} right={tableFields[3].right} $center={tableFields[3].center}>
                    <FieldComponent type="radio" name="loop" value={index.toString()} disabled={disabled} />
                  </FieldPlace>
                </>
              )}
              <InfoPlace>{getSum(index)}</InfoPlace>
              {!disabled && fields.length > 1 && <img src="/images/cross_icon_white.png" onClick={() => deletePeriod(index)} alt="" />}
            </InputsPlace>
          </li>
        );
      })}
      {!disabled && (
        <AddButton type="button" theme={themes.blue} disabled={disabled} onClick={addPeriod}>
          <i className="fa fa-plus" />
          {t('buttons.Добавить период')}
        </AddButton>
      )}
    </InputList>
  );
};

PeriodsLoopInputs.propTypes = {
  fields: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  extended: PropTypes.bool,
  meta: PropTypes.any.isRequired,
  tableFields: PropTypes.array.isRequired,
  selectedZone: PropTypes.any,
};
