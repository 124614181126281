import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { config } from 'config';
import { Container, Image, ImageWindow, LeftArrow, RightArrow } from './img-viewer.styled';

export class ImgViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      showImageInWindow: false,
    };

    this.setImgRef = (element) => {
      this.imgRef = element;
    };
  }

  clickOnImageHandler = () => {
    if (!this.state.showImageInWindow) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }
    this.setState({ showImageInWindow: !this.state.showImageInWindow });
  };

  handleOutsideClick = (e) => {
    if (this.imgRef) {
      if (this.imgRef.contains(e.target)) {
        return;
      } else {
        this.clickOnImageHandler();
      }
    }
  };

  nextImage = () => {
    if (this.state.index + 1 > this.props.images.length - 1) {
      this.setState({ index: 0 });
    } else {
      this.setState({ index: this.state.index + 1 });
    }
  };

  previousImage = () => {
    if (this.state.index - 1 === -1) {
      this.setState({ index: this.props.images.length - 1 });
    } else {
      this.setState({ index: this.state.index - 1 });
    }
  };

  getImage = (images) => {
    if (images && images[this.state.index]) {
      return <Image innerRef={this.setImgRef} onClick={this.clickOnImageHandler} src={config.network.images_url + images[this.state.index]} alt="" />;
    } else {
      return <Image innerRef={this.setImgRef} $static onClick={this.clickOnImageHandler} src="/images/no-photo.png" opacity="0.5" />;
    }
  };

  render() {
    const { showImageInWindow } = this.state;
    const { images, width, height } = this.props;
    const hideArrow = images.length <= 1;

    return (
      <Container width={width} height={height}>
        <LeftArrow $hide={hideArrow} onClick={this.previousImage}>
          <i className="fa fa-angle-left" aria-hidden="true"></i>
        </LeftArrow>
        {this.getImage(images)}
        <RightArrow $hide={hideArrow} onClick={this.nextImage}>
          <i className="fa fa-angle-right" aria-hidden="true"></i>
        </RightArrow>
        {showImageInWindow && <ImageWindow>{this.getImage(this.props.images)}</ImageWindow>}
      </Container>
    );
  }
}

ImgViewer.propTypes = {
  images: PropTypes.any.isRequired,
  width: PropTypes.any.isRequired,
  height: PropTypes.any.isRequired,
};
