import React, { useState } from 'react';
import moment from 'moment';
import { reduxForm } from 'redux-form';

import zones from 'services/zones';
import { Container, ButtonsContainer, Multiselect, ZoneInput, DateTime, required } from 'libs/form';
import { Button, themes } from 'libs/ui';
import { api } from 'api';
import { Buttons, Confirm, Title, UpdateMessageBox } from './Filter.styled';
import { useTranslation } from 'react-i18next';

const Filter = (props) => {
  const { t } = useTranslation();

  const [data, setData] = useState(undefined);
  const [confirm, setConfirm] = useState(false);
  const [result, setResult] = useState(false);

  const showMessage = () => {
    setResult(true);
    setTimeout(() => {
      setResult(false);
    }, 3000);
  };

  const action = () => {
    const d = data.end_at.toISOString();

    api.call('stays.auto_close', { max_entry_at: d, zones: data.zones }).then(() => {
      setConfirm(false);
      showMessage();
    });
  };

  const closeConfirm = () => {
    setConfirm(false);
  };

  const showConfirm = (formData) => {
    setData(formData);
    setConfirm(true);
  };

  const zonesItems = zones.getZones();
  const d = data ? moment(data.end_at).format('ll') : '';
  const zonesList = data ? data.zones.join(', ') : '';

  return (
    <Container>
      {result && <UpdateMessageBox>{t('filter.Парковки завершены')}</UpdateMessageBox>}
      {confirm ? (
        <Confirm>
          <Title>
            {t('filter.Все активные парковки с датой начала (въездом) до 0:00')} {d} {'filter.для выбранных парковочных зон'} ({zonesList}){' '}
            {'filter.будут завершены. Подтвердить?'}
          </Title>
          <Buttons>
            <Button theme={themes.blue} onClick={closeConfirm}>
              {'filter.Отменить'}
            </Button>
            <Button theme={themes.red} onClick={action}>
              {'filter.Завершить'}
            </Button>
          </Buttons>
        </Confirm>
      ) : (
        <form onSubmit={props.handleSubmit(showConfirm)}>
          <DateTime width="215px" name="end_at" type="date" label={t('filter.Завершить все парковки до')} validate={required(t)} />

          <Multiselect
            width="160px"
            name="zones"
            type="multiselect"
            label={t('sections.Зоны')}
            additionalComponent={ZoneInput}
            data={zonesItems}
            countDisplayedIcons={3}
            simpleErrorWrapper
            validate={required(t)}
          />

          <ButtonsContainer>
            <Button disabled={!data} type="submit" theme={themes.blue}>
              {t('buttons.Завершить')}
            </Button>
          </ButtonsContainer>
        </form>
      )}
    </Container>
  );
};

export default reduxForm({
  form: 'parkingsFilter',
  destroyOnUnmount: false,
})(Filter);
