import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { user } from 'services';
import { Popup2, Button, themes, ButtonContainer, ParkomatsSelect, Loader } from 'libs/ui';
import { ParkingFilter2 } from 'libs/form';
import { findElement } from 'libs/common';
import Warning from 'modules/components/warnings';
import { getPrintableRacks, getActiveZones } from 'services/types';
import { ErrorMsg } from 'pages/styled';
import {
  PlateInput,
  NewTicketInfoAdd,
  NewTicketContent,
  NewTicketHeader,
  NewTicketInfo,
  AttentionIcon,
  Line1,
  RightInputs,
} from './new-ticket.styled';
import { useTranslation } from 'react-i18next';

export const NewTicket = (props) => {
  const { parkingId, close, action, withoutInfo } = props;
  const [error, setError] = useState('');
  const [plate, setPlate] = useState('');
  const [currentParkingId, setCurrentParkingId] = useState(
    parkingId ? '' : user.getMaps().parking[0] ? user.getMaps().parking[0].id : ''
  );
  const [parkomat, setParkomat] = useState('');
  const [confirm, setConfirm] = useState(false);
  const [working, setWorking] = useState(false);
  const [comment, setComment] = useState('Штраф');
  const [racks, setRacks] = useState([]);
  const [zones, setZones] = useState([]);
  const [zone, setZone] = useState('');

  const [penalty, setPenalty] = useState('0');
  const [penaltyValue, setPenaltyValue] = useState(0);

  const { t } = useTranslation();

  /**
   * get parkomats
   */
  useEffect(() => {
    getPrintableRacks().then((data) => {
      if (Array.isArray(data)) {
        if (!withoutInfo) {
          data = data.filter((el) => el.parking_id === parkingId);
        }
        data.forEach((el) => (el.mapped_name = el.name));
        const def = data.length > 0 ? data[0].id : '';
        setRacks(data);
        setParkomat(def);
      } else {
        setRacks([]);
      }
    });

    if (withoutInfo) {
      getActiveZones().then((data) => {
        if (Array.isArray(data)) {
          if (!withoutInfo) {
            data = data.filter((el) => el.parking_id === parkingId);
          }
          data.forEach((el) => (el.mapped_name = el.name));
          const def = data.length > 0 ? data[0].id : '';
          setZones(data);
          setZone(def);
        } else {
          setZones([]);
        }
      });
    }
  }, [parkingId]);

  const showConfirm = () => {
    let value = parseInt(penalty);
    const withPenalty = value === 1000;
    if (withPenalty) {
      value = penaltyValue;
    }

    if (withPenalty && !value) {
      setError(t('messages.Заполните поле Сумма'));
      return;
    }

    setError('');
    setConfirm(true);
  };

  const selectParking = (val) => {
    setCurrentParkingId(val);
  };

  const selectParkomat = (val) => {
    setParkomat(val);
  };

  const selectZone = (val) => {
    setZone(val);
  };

  /**
   * get confirm title
   * @returns
   */
  const getConfirmTitle = () => {
    const rack = findElement(racks, parkomat);
    let confirmTitle =
      t('messages.Будет напечатан билет на устройстве ') + (rack ? rack.mapped_name : '') + t('messages. зоне ') + zone;
    if (parseInt(penalty)) {
      confirmTitle +=
        t('messages., с дополнительным начислением ') + comment + t('messages. в сумме ') + penaltyValue + t('grid.₽');
    } else {
      confirmTitle += t('messages., без дополнительных начислений');
    }
    return confirmTitle;
  };

  /**
   * print
   * @returns
   */
  const print = () => {
    if (working) {
      return;
    }

    let value = parseInt(penalty);
    const withPenalty = value === 1000;
    if (withPenalty) {
      value = penaltyValue;
    }

    if (withPenalty && !value) {
      setError(t('messages.Заполните поле Сумма'));
      return;
    }

    setWorking(true);
    action({ parkomat, value, comment, zone, vehicle_plate: plate })
      .then(() => {
        setWorking(false);
        close();
      })
      .catch((data) => {
        setError(data.message);
        setWorking(false);
      });
  };

  if (!racks || !racks.length) {
    return (
      <Popup2 w={460}>
        <Loader height={'120px'} />
        <ButtonContainer space="20px" justifyContent={'center'} style={{ marginTop: '40px' }}>
          <Button fontSize="16px" onClick={close}>
            {t('buttons.Отменить')}
          </Button>
        </ButtonContainer>
      </Popup2>
    );
  }

  const confirmTitle = getConfirmTitle();

  const changePlate = (event) => {
    setPlate(event.target.value);
  };

  return (
    <>
      <Popup2 w={460}>
        <AttentionIcon src="/images/icon_!.png" alt="" />
        <NewTicketContent>
          <NewTicketHeader>{t('form.Печать нового билета')}</NewTicketHeader>

          {error && (
            <ErrorMsg>
              <img height={46} src="/images/error-triangle-icon.png" alt="" />
              {error}
            </ErrorMsg>
          )}

          <RightInputs>
            {!parkingId && <ParkingFilter2 simple onChange={selectParking} current={currentParkingId} />}
            <ParkomatsSelect racks={racks} onChange={selectParkomat} parkingId={currentParkingId} value={parkomat} />
            {withoutInfo && (
              <ParkomatsSelect
                label={t('grid.Зона')}
                racks={zones}
                onChange={selectZone}
                parkingId={currentParkingId}
                value={zone}
              />
            )}
            <PlateInput>
              <label>{t('sections.ГРЗ')}</label>
              <input
                type="text"
                name="vehicle_plate"
                value={plate}
                onChange={changePlate}
                placeholder={t('form.А000AA 000 rus')}
              />
            </PlateInput>
          </RightInputs>

          <form onChange={(e) => setPenalty(e.target.value)}>
            <NewTicketInfo>
              <input defaultChecked={true} type="radio" name="penalty" value="0" />{' '}
              {t('form.Без дополнительной услуги')}
            </NewTicketInfo>
            <NewTicketInfo>
              <input type="radio" name="penalty" value="1000" /> {t('form.С дополнительной услугой')}
            </NewTicketInfo>
          </form>

          {penalty === '1000' && (
            <NewTicketInfoAdd>
              <Line1>
                <span className="label">{t('grid.Наименование')}</span>
                <input
                  min={0}
                  onChange={(e) => setComment(e.target.value)}
                  type="text"
                  name="penaltytype"
                  value={comment}
                  style={{ margin: 0, width: '142px' }}
                />
              </Line1>
              <Line1>
                <span className="label">{t('grid.Сумма, ₽')}</span>
                <input
                  style={{ margin: 0, width: '142px' }}
                  min={0}
                  onChange={(e) => setPenaltyValue(e.target.value)}
                  type="number"
                  name="penaltyvalue"
                  value={penaltyValue}
                />
              </Line1>
            </NewTicketInfoAdd>
          )}

          <ButtonContainer space="20px" justifyContent={'flex-start'} style={{ marginTop: '40px' }}>
            <Button disabled={working} fontSize="16px" onClick={close}>
              {t('buttons.Отменить')}
            </Button>
            <Button disabled={working} theme={themes.blue} fontSize="16px" onClick={showConfirm}>
              {t('buttons.Печатать')}
            </Button>
          </ButtonContainer>
        </NewTicketContent>
      </Popup2>

      {confirm && (
        <Warning
          top={27}
          width="480px"
          header={confirmTitle}
          cancelButton={{
            func: () => setConfirm(false),
            text: t('buttons.Отменить'),
          }}
          acceptButton={{
            func: () => {
              print();
            },
            text: t('grid.Подтвердить'),
            theme: themes.blue,
          }}
        />
      )}
    </>
  );
};

NewTicket.propTypes = {
  id: PropTypes.any.isRequired,
  parkingId: PropTypes.any,
  close: PropTypes.any.isRequired,
  action: PropTypes.any.isRequired,
  withoutInfo: PropTypes.bool,
};
