import React, { useEffect } from 'react';
import styled from 'styled-components';
import { reduxForm } from 'redux-form';

import { user } from 'services';
import { DictFilter, ParkingFilter, Container, Input, Select, ButtonsContainer } from 'libs/form';
import { ResetBtn, UseBtn } from 'libs/ui';
import { useTranslation } from 'react-i18next';

const StyledContainer = styled(Container)`
  background-color: #4f5761;
  box-shadow: none;
`;

const StyledInput = styled(Input)`
  input {
    background-color: #4f5761;
    color: #fff;
  }
  label {
    color: #b7babf;
  }
`;

const Filter = (props) => {
  const { t } = useTranslation();
  const { handleSubmit, reset, setInitial } = props;

  useEffect(() => {
    setInitial(initialValues);
    return () => {
      reset();
      setInitial(initialValues);
    };
  }, [setInitial, reset]);

  const getCats = () => {
    const maps = user.getMaps();
    const obj = maps.vehicle_category;
    return obj.map((el, idx) => (
      <option key={idx} value={el.id}>
        {el.name}
      </option>
    ));
  };

  const cats = getCats();

  return (
    <StyledContainer>
      <form onSubmit={handleSubmit}>
        <DictFilter w={150} dict="rfid_status" theme="black" exclude={['blocked_by_client']} />

        <StyledInput
          width="170px"
          name="rfid"
          type="text"
          label={t('sections.RFID')}
          placeholder="3786 4678 6467 5656"
        />

        <StyledInput
          width="180px"
          name="owner_name"
          type="text"
          label={t('form.Водитель')}
          placeholder={t('form.Иванов И.И.')}
        />

        <Select width="160px" name="vehicle_category" type="select" label={t('filter.Категория СО')} black>
          <option value="">{t('filter.Все')}</option>
          {cats}
        </Select>

        <StyledInput
          width="135px"
          name="vehicle_plate"
          type="text"
          label={t('sections.ГРЗ')}
          placeholder={t('form.А000AA 000 rus')}
        />

        <StyledInput
          width="160px"
          name="tariff"
          type="text"
          label={t('form.Тариф')}
          placeholder={t('filter.Базовый')}
        />

        <StyledInput
          width="200px"
          name="comment"
          type="text"
          label={t('filter.Комментарий')}
          placeholder={t('filter.Текст комментария')}
        />

        <ParkingFilter black ml0 />

        <ButtonsContainer>
          <ResetBtn onClick={reset} />
          <UseBtn />
        </ButtonsContainer>
      </form>
    </StyledContainer>
  );
};

const initialValues = {};

export default reduxForm({
  form: 'clientRfidFilter',
  destroyOnUnmount: false,
  initialValues,
})(Filter);
