import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';

import { user } from 'services';
import { required, ErrorMsg, StyledField } from 'libs/form';
import { ButtonContainer, Submit } from 'libs/ui';
import { LK } from './lk';
import { useCallback } from 'react';

function NaturalPersonForm(props) {
  const { t } = useTranslation();

  const { initialValues } = props;
  const rightUpdate = user.haveRight('clients.update');

  const requiredField = useCallback(required(t), [t]);

  return (
    <form onSubmit={props.handleSubmit} autoComplete="new-password">
      <ErrorMsg theme="dark" message={props.error} />

      <StyledField validate={requiredField} type="text" label={t('form.ФИО')} name="name" placeholder={t('form.Иванов Иван Иванович')} />
      <StyledField type="date" label={t('form.Дата рождения')} name="birth_date" placeholder={t('form.08 11 2017')} className="shortfield" />
      <StyledField type="text" label={t('form.Паспорт')} name="passport" placeholder="04 05 990440" />
      <StyledField type="text" label={t('form.Кем выдан')} name="passport_issued_by" placeholder="Выдан УВД Приморского района г. Санкт-Петербург" />
      <StyledField type="date" label={t('form.Дата выдачи')} name="passport_when_issued" placeholder={t('form.08 11 2017')} className="shortfield" />
      <StyledField type="text" label={t('form.Код подразделения')} name="passport_department_code" placeholder="233 – 005" />
      <LK lk_login={initialValues.lk_login} />
      {rightUpdate && (
        <ButtonContainer>
          <Submit disabled={props.submitting} />
        </ButtonContainer>
      )}
    </form>
  );
}

const getDecorateValues = (values) => {
  let decorateValues = {};
  for (let key in values) {
    if (values[key]) {
      decorateValues[key] = values[key];
    }
  }
  return decorateValues;
};

export default connect((store) => {
  let { type, name, birth_date, passport, passport_issued_by, passport_when_issued, passport_department_code, data } = store.client.clientInfo;
  let { lk_unlimited, lk_login } = data ? data : { lk_unlimited: '', lk_login: '' };

  const initialValues = getDecorateValues({
    type,
    name,
    birth_date,
    passport,
    passport_issued_by,
    passport_when_issued,
    lk_unlimited,
    lk_login,
    passport_department_code,
  });

  return {
    initialValues: initialValues,
  };
})(
  reduxForm({
    form: 'NaturalPersonForm',
  })(NaturalPersonForm)
);
