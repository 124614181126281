import React, { useMemo } from 'react';

import { TableHeader } from 'libs/ui';
import Ride from './Row';
import { GridBox, Grid, TableHead, TableHeadRow, TopRow } from 'libs/ui';
import { Total } from 'libs/ui';
import { useTranslation } from 'react-i18next';

const RidesGrid = ({ entries, rides, zones, count, getXlsLink }) => {
  const { t } = useTranslation();
  const ridesList = useMemo(() => {
    const rideEntries = entries || rides;
    if (!rideEntries) return [];

    return Object.values(rideEntries).map((ride) => <Ride zones={zones} key={ride.id} {...ride} />);
  }, [entries, rides, zones]);

  return (
    <GridBox>
      <TopRow>
        <Total cnt={count} />
        {getXlsLink()}
      </TopRow>
      <Grid>
        <TableHead>
          <TableHeadRow>
            {window.config.central && <TableHeader width="140px">{t('grid.Парковка')}</TableHeader>}
            {/*<TableHeader width='105px'>Проезд</TableHeader>*/}
            <TableHeader width="210px" sortField="timestamp">
              {t('grid.Дата и время')}
            </TableHeader>
            <TableHeader width="135px" sortField="vehicle_plate">
              {t('sections.ГРЗ')}
            </TableHeader>
            <TableHeader width="75px">{t('dict_filter.Тип')}</TableHeader>
            <TableHeader width="100px">{t('dict_filter.Результат')}</TableHeader>
            <TableHeader width="62px">{t('grid.Зона из')}</TableHeader>
            <TableHeader width="95px">{t('grid.Узел/стойка')}</TableHeader>
            <TableHeader width="62px">{t('grid.Зона в')}</TableHeader>
            <TableHeader width="70px">{t('dict_filter.Класс ТС')}</TableHeader>
            <TableHeader width="150px">{t('dict_filter.Тип СО')}</TableHeader>
            <TableHeader width="150px">{t('grid.СО')}</TableHeader>
            <TableHeader width="135px">{t('form.Держатель')}</TableHeader>
            <TableHeader width="135px">{t('form.Клиент')}</TableHeader>
            <TableHeader width="80px">{t('grid.Действия')}</TableHeader>
          </TableHeadRow>
        </TableHead>
        <tbody>{ridesList}</tbody>
      </Grid>
    </GridBox>
  );
};

export default RidesGrid;
