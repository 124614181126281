import { useState } from 'react';
import { reduxForm, SubmissionError, formValueSelector, getFormAsyncErrors } from 'redux-form';
import { connect } from 'react-redux';

import { api } from 'api';
import { addRfid, updateRfid } from 'services/payments-service';
import { getCategoriesList, getVehicleClassesList, getOptionsList } from 'services/types';
import { /*Confirm,*/ Button, themes, Popup2 } from 'libs/ui';
import { ParkingFilter2, required, WhiteStyledField, Title, ErrorMsg } from 'libs/form';
import { StyledButtonContainer, TextareaStyledField } from './rfid-form.styled';
import { useTranslation } from 'react-i18next';

/**
 * async validate
 * @param {*} values
 * @returns
 */
const asyncValidate = (values) => {
  const val = values.rfid;
  if (window.rfidFormEdit) {
    return Promise.resolve();
  }
  const flt = { rfid: val };
  if (values.parking_id) {
    flt.parking_id = values.parking_id;
  }

  return api.call('rfid.list', { filter_params: flt, sort_params: [], paging_params: { limit: 1 } }).then((data) => {
    if (data.items && data.items.length) {
      const item = data.items[0];
      window.rfid = item;
      if (item.client_id == window.clientClientId) {
        throw { rfid: 'Метка уже добавлена' };
      } else {
        throw { rfid: 'Метка с таким номером привязана к другому клиенту' };
      }
    }
  });
};

/**
 * rfid form
 * @param {*} props
 * @returns
 */
const RfidForm = (props) => {
  const {
    error,
    destroy,
    refresh,
    setMessage,
    form,
    handleSubmit,
    close,
    type,
    clientId,
    id,
    tariffs,
    parkingId,
    transportClass,
    transportCategory,
  } = props;
  const [confirm, setConfirm] = useState(false);
  const [data, setData] = useState(null);

  const isAdd = type === 'addForm';
  window.rfidFormEdit = !isAdd;

  const { t } = useTranslation();

  /**
   * close confirm
   */
  const closeConfirm = () => {
    setConfirm(false);
  };

  /**
   * after submit
   */
  const afterSubmit = () => {
    //getEntries({ offset: 0 }, null, true);
    //getEntriesCount();
    refresh();
    //removeFormInfo();
    setMessage('Изменения сохранены');
    setTimeout(() => {
      setMessage('');
    }, 3000);
    close();
    destroy(form);
  };

  /**
   * submit
   * @param {*} values
   * @returns
   */
  const submit = (values) => {
    // data
    const test = false;
    const out = { ...values };
    // out.is_blocked = out.is_blocked === 'true' || out.is_blocked === true;
    // out.mean_type = 'subscription';
    out.vehicle_category = out.vehicle_category ? parseInt(out.vehicle_category) : null;
    out.vehicle_class = out.vehicle_class ? parseInt(out.vehicle_class) : null;
    out.tariff_id = out.tariff_id ? out.tariff_id : null;
    if (clientId) {
      out.client_id = clientId;
    }

    if (!isAdd) {
      out.id = id;
      // confirm
      if (out.mean_id !== props.initialValues.mean_id) {
        setData(out);
        setConfirm(true);
        return false;
      }
    }
    if (test) {
      console.log('🚀 ~ submit ~ out:', out);
      return Promise.resolve();
    }

    // method
    const method = isAdd ? addRfid : updateRfid;
    return method(out)
      .then(() => {
        afterSubmit();
      })
      .catch((errors) => {
        if (errors && errors.data) {
          throw new SubmissionError({
            _error: errors.data.type,
          });
        }
      });
  };

  // const submitAfterConfirm = () => {
  //   return updateSubscription(data)
  //     .then(() => {
  //       afterSubmit();
  //     })
  //     .catch((errors) => {
  //       if (errors && errors.data) {
  //         throw new SubmissionError({
  //           _error: errors.data.type,
  //         });
  //       }
  //     });
  // };

  const cancel = () => {
    close();
    destroy(form);
  };

  const title = isAdd ? t('form.Добавить СО') : t('form.Изменить СО');
  const disabledRfid = !isAdd || (window.config.central && !parkingId);

  return (
    <Popup2 w={482}>
      <Title>{title}</Title>
      <form onSubmit={handleSubmit(submit)}>
        <ErrorMsg error={error} />

        <input type="hidden" name="mode" />
        <input type="hidden" name="id1" />
        <input type="hidden" name="client_id1" />
        {isAdd && <ParkingFilter2 withEmpty required />}
        {isAdd && (
          <WhiteStyledField
            validate={[required(t), rfid(t), rfidTest]}
            placeholder="78497503480098"
            mask="999999"
            error={error}
            type="text"
            label={t('sections.RFID')}
            name="rfid"
            disabled={disabledRfid}
          />
        )}
        <WhiteStyledField
          validate={required(t)}
          placeholder={t('form.Иванов Иван Иванович')}
          type="text"
          error={error}
          label={t('form.ФИО водителя')}
          name="owner"
        />

        <WhiteStyledField validate={required(t)} type="select" label="Класс ТС" error={error} name="vehicle_class">
          {isAdd && <option value={null}>{t('form.Выберите класс ТС')}</option>}
          {getVehicleClassesList()}
        </WhiteStyledField>

        <WhiteStyledField validate={required(t)} type="select" label="Категория СО" error={error} name="vehicle_category">
          {isAdd && <option value={null}>{t('form.Выберите категорию')}</option>}
          {getCategoriesList()}
        </WhiteStyledField>

        <WhiteStyledField
          validate={required(t)}
          placeholder={t('form.А000AA 000 rus')}
          type="text"
          label={t('sections.ГРЗ')}
          error={error}
          name="vehicle_plate"
        />

        <WhiteStyledField
          className="shortfield"
          type="select"
          label={t('form.Тариф')}
          name="tariff_id"
          error={error}
          disabled={!transportClass || !transportCategory}
          validate={required(t)}
        >
          {isAdd && <option value="">{t('form.Выберите тариф')}</option>}
          {getOptionsList(tariffs)}
        </WhiteStyledField>

        {isAdd && <TextareaStyledField type="textarea" placeholder={t('filter.Комментарий')} label={t('filter.Комментарий')} name="comment" />}

        <StyledButtonContainer space="20px">
          <Button type="button" onClick={cancel}>
            {t('buttons.Отменить')}
          </Button>
          <Button theme={themes.blue}>
            <i className="fa fa-save" />
            {t('buttons.Сохранить')}
          </Button>
        </StyledButtonContainer>

        {/* {confirm && (
          <Confirm
            width={'550px'}
            title={'Номер карты для данной записи будет изменён. Предыдущий номер карты будет удалён. Продолжить?'}
            type="danger"
            cancelButton={{ text: 'Отмена', func: closeConfirm }}
            acceptButton={{ text: 'Изменить номер карты', func: submitAfterConfirm, theme: themes.red }}
          />
        )} */}
      </form>
    </Popup2>
  );
};

//vehicle_category: props.categories ? props.categories[0].id : null,

let reduxRfidForm = reduxForm({
  form: 'rfidForm',
  asyncValidate,
  asyncBlurFields: ['rfid'],
  destroyOnUnmount: false,
})(RfidForm);

const selector = formValueSelector('rfidForm');

reduxRfidForm = connect((state, props) => {
  const transportClass = selector(state, 'vehicle_class');
  const transportCategory = selector(state, 'vehicle_category');
  const parkingId = selector(state, 'parking_id');
  const out = { ...props.initialValues, mode: 'normal', client_id1: props.clientId, id1: props.initialValues.id };
  return {
    transportClass,
    transportCategory,
    parkingId,
    formAsyncErrors: getFormAsyncErrors('rfidForm')(state),
    initialValues: out,
  };
}, {})(reduxRfidForm);

export default reduxRfidForm;
