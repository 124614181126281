// import { config } from 'config';
import { api } from 'api';

/**
 * get tariff template
 * @returns
 */
export const getTariffTemplate = () => {
  return api.call('tariff.get_data');
};
