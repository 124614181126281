import React, { useEffect } from 'react';
import { reduxForm } from 'redux-form';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { DateSelect, Container, Input, Select, DoubleField, DateTime, ButtonsContainer } from 'libs/form';
import { ResetBtn, UseBtn } from 'libs/ui';

const initialValues = {
  timestamp_from: moment().startOf('day').format(),
  timestamp_to: moment().endOf('day').format(),
};

const Filter = ({ handleSubmit, reset, change, setInitial }) => {
  const { t } = useTranslation();

  useEffect(() => {
    setInitial(initialValues);
  }, [setInitial]);

  const roles = Object.keys(window.config.role_map).map((idx) => (
    <option key={idx} value={idx}>
      {window.config.role_map[idx]}
    </option>
  ));

  roles.unshift(
    <option key="00" value="">
      {t('filter.Все')}
    </option>
  );

  return (
    <Container /*height='160px'*/>
      <form onSubmit={handleSubmit}>
        <Select width="160px" name="role_id" type="select" label={t('form.Роль')}>
          {roles}
        </Select>

        <Input
          width="290px"
          name="name"
          type="text"
          label={t('inputs.Пользователь')}
          placeholder={t('form.Иванов И.И.')}
        />

        <DateSelect from_name="timestamp_from" to_name="timestamp_to" change={change}>
          <DoubleField>
            <DateTime width="160px" name="timestamp_from" type="dateTime" label={t('grid.Дата и время')} />
            <DateTime width="160px" name="timestamp_to" type="dateTime" />
          </DoubleField>
        </DateSelect>

        <Input
          width="230px"
          name="action"
          type="text"
          label={t('inputs.Описание')}
          placeholder={t('inputs.Введите название')}
          lastField
        />

        <ButtonsContainer>
          <ResetBtn onClick={reset} />
          <UseBtn />
        </ButtonsContainer>
      </form>
    </Container>
  );
};

export default reduxForm({
  form: 'actionsFilter',
  destroyOnUnmount: false,
  initialValues: initialValues,
})(Filter);
