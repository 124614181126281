import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { StyledField, DarkMultiselect } from 'libs/form';
import { required, MultiselectView } from 'libs/form';
import { ParkingFilter2 } from 'libs/form';
import { SectionTitle } from './tariff-form-main.styled';

const TypeSelect = styled(StyledField)`
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
`;

function TariffForm({ vehicleClasses, categories }) {
  const { t } = useTranslation();

  const validateP = useCallback(required(t), [t]);
  return (
    <>
      <TypeSelect type="select" label={t('dict_filter.Тип')} name="type" disabled={true}>
        <option value="individual">{t('grid.Индивидуальный')}</option>
      </TypeSelect>
      <ParkingFilter2 dark />
      <StyledField validate={required(t)} placeholder={t('grid.Осень 2017')} type="text" label={t('grid.Название *')} name="name" />
      <DarkMultiselect
        validate={validateP}
        type="multiselect"
        label={t('form.Класс ТС *')}
        name="vehicle_class"
        placeholder={t('form.Выберите класс ТС')}
        data={vehicleClasses}
        additionalComponent={MultiselectView}
      />
      <DarkMultiselect
        // validate={required}
        type="multiselect"
        label={t('form.Категория')}
        name="vehicle_category"
        placeholder={t('form.Выберите категорию ТС')}
        data={categories}
        additionalComponent={MultiselectView}
      />
      <StyledField
        className="shortfield"
        validate={required(t)}
        placeholder={t('form.08 11 2017')}
        type="dateTime"
        label={t('grid.Начало *')}
        name="time_from"
      />
      <StyledField
        type="text"
        placeholder={t('form.15 мин')}
        label={t('form.Время на выезд')}
        name="time_to_leave"
        mask={{ suffix: t('form. мин') }}
      />

      <SectionTitle>{t('sections.Абонемент')}</SectionTitle>
      <StyledField
        type="text"
        placeholder={t('form.Цена абонемента')}
        label={t('form.Цена абонемента')}
        name="subscription_amount"
        mask={{ suffix: t('form. руб') }}
      />
      <StyledField
        type="text"
        placeholder={t('grid.30 дней')}
        label={t('form.Срок действия')}
        name="subscription_period"
        mask={{ suffix: t('grid. дней') }}
      />

      <>
        <SectionTitle>{t('form.Повторный въезд')}</SectionTitle>
        <StyledField
          type="text"
          placeholder={t('form.15 мин')}
          label={t('form.Повторный платный въезд')}
          name="secondentry_interval"
          mask={{ suffix: t('form. мин') }}
        />
      </>
    </>
  );
}

export default TariffForm;
