import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { getAmount } from 'libs/common';
import { Content, Title, Value, Collapse, TitleVal } from './sum.styled';
import { useTranslation } from 'react-i18next';

export const Sum = (props) => {
  const { t } = useTranslation();

  const { title, amount, children, ...rest } = props;
  const [show, setShow] = useState(false);
  const sum = getAmount(amount);
  const withChildren = children !== undefined;

  const other = {};
  if (withChildren) {
    other.className = 'link';
    other.onClick = () => {
      setShow((state) => !state);
    };
  }

  // render
  return (
    <>
      <Content {...rest}>
        <Title {...other}>
          <TitleVal open={show}>{t(`grid.${title}`)}</TitleVal>
        </Title>
        <Value>{sum}</Value>
      </Content>
      {withChildren && show && <Collapse>{children}</Collapse>}
    </>
  );
};

Sum.propTypes = {
  title: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  final: PropTypes.bool,
  children: PropTypes.any,
};
