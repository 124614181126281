import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';

import { Container, Input, Select, DoubleField, DateTime, ButtonsContainer, DictFilter, ParkingFilter, DateSelect } from 'libs/form';
import { ResetBtn, UseBtn } from 'libs/ui';

const Filter = (props) => {
  const { t } = useTranslation();

  const { handleSubmit, reset, change } = props;

  const getOptionsList = (field) => {
    const data = props[field];

    if (Array.isArray(data)) {
      return data.map((item) => {
        return (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        );
      });
    }
  };

  return (
    <Container /*height='160px'*/>
      <form onSubmit={handleSubmit}>
        <DictFilter w={120} dict="tariff.status" />

        <DictFilter w={100} dict="tariff.type" />

        <Input width="222px" name="name" type="text" label={t('grid.Название')} placeholder={t('filter.Предприниматели')} />

        <Select width="155px" name="vehicle_category" type="select" label={t('form.Категория')}>
          <option value="">{t('filter.Все')}</option>
          {getOptionsList('categories')}
        </Select>

        <Select width="120px" name="vehicle_class" type="select" label={t('dict_filter.Класс ТС')}>
          <option value="">{t('filter.Все')}</option>
          {getOptionsList('vehicleClasses')}
        </Select>

        <DateSelect from_name="time_from" to_name="time_to" change={change}>
          <DoubleField>
            <DateTime width="160px" name="time_from" type="dateTime" label={t('form.Начало действия')} />
            <DateTime width="160px" name="time_to" type="dateTime" />
          </DoubleField>
        </DateSelect>

        <DateSelect lastField from_name="created_at_from" to_name="created_at_to" change={change}>
          <DoubleField>
            <DateTime width="160px" name="created_at_from" type="dateTime" label={t('form.Дата создания')} />
            <DateTime width="160px" name="created_at_to" type="dateTime" />
          </DoubleField>
        </DateSelect>

        <ParkingFilter />

        <ButtonsContainer>
          <ResetBtn onClick={reset} />
          <UseBtn />
        </ButtonsContainer>
      </form>
    </Container>
  );
};

export default reduxForm({
  form: 'tariffFilter',
  destroyOnUnmount: false,
  initialValues: { status: 'active' },
})(Filter);
