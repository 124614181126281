import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const Popup = styled.div`
  position: absolute;
  z-index: 999;
  top: 20px;
  padding: 0 20px;
  height: 40px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.5));
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  div {
    top: -10px;
    z-index: 998;
    position: absolute;
    border-bottom: 10px solid white;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }
`;

class ZoneInfo extends React.Component {
  state = {
    showInfo: false,
  };

  getNodes() {
    let values = [];
    let nodes = this.props.nodes;
    for (let key in nodes) {
      if (nodes[key] === true) {
        values.push('У' + key);
      } else {
        values.push(...nodes[key]);
      }
    }
    return values.join(',');
  }

  render() {
    return (
      <Wrapper onMouseEnter={() => this.setState({ showInfo: true })} onMouseLeave={() => this.setState({ showInfo: false })}>
        <img src="/images/path-6.png" alt="" />
        {this.state.showInfo ? (
          <Popup>
            <div />
            {this.getNodes()}
          </Popup>
        ) : null}
      </Wrapper>
    );
  }
}

export default ZoneInfo;
