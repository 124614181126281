import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';

import { user } from 'services';
import Tariff from './Row';
import { GridBox, Grid, TableHead, TableHeadRow, TopRow, Total } from 'libs/ui';
import { Button, themes } from 'libs/ui';
import { TableHeader } from 'libs/ui';
import { routes } from 'router';

const TariffsGrid = (props) => {
  const [showWarning, setShowWarning] = useState(false);

  const { t } = useTranslation();

  const { categories, vehicleClasses, entries } = props;

  const showNewTariffCard = () => {
    props.history.push(routes.newTariff);
  };

  const getTariffList = () => {
    let tariffList = [];
    if (entries) {
      for (let key in entries) {
        tariffList.push(<Tariff key={entries[key].id} vehicleClasses={vehicleClasses} categories={categories} {...entries[key]} />);
      }
    }
    return tariffList;
  };

  return (
    <GridBox>
      {props.hideTotal ? null : (
        <TopRow>
          <Total cnt={props.count} />
          {user.haveRight('tariff.add') && (
            <Button theme={themes.blue} onClick={showNewTariffCard}>
              <img src="/images/add-icon.png" alt="" />
              {t('buttons.Добавить индивидуальный тариф')}
            </Button>
          )}
        </TopRow>
      )}
      <Grid className={props.className}>
        <TableHead>
          <TableHeadRow>
            {window.config.central && <TableHeader width="140px">{t('grid.Парковка')}</TableHeader>}
            <TableHeader width="280px">{t('grid.Название')}</TableHeader>
            <TableHeader width="240px">{t('dict_filter.Тип')}</TableHeader>
            <TableHeader width="115px">{t('dict_filter.Статус')}</TableHeader>
            <TableHeader width="210px">{t('form.Дата создания')}</TableHeader>
            <TableHeader width="210px">{t('form.Начало действия')}</TableHeader>
            <TableHeader width="165px">{t('form.Категория')}</TableHeader>
            <TableHeader>{t('dict_filter.Класс ТС')}</TableHeader>
          </TableHeadRow>
        </TableHead>
        <tbody>{getTariffList()}</tbody>
      </Grid>
    </GridBox>
  );
};

export default withRouter(TariffsGrid);
