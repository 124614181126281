import { useState } from 'react';
import { reduxForm, SubmissionError } from 'redux-form';

import { useTranslation } from 'react-i18next';

import { user } from 'services';
import { addSubscription, updateSubscription, getClientsDict } from 'services/payments-service';
import { getCategoriesList, getVehicleClassesList, getOptionsList } from 'services/types';
import { Confirm, Button, themes, Popup2, ParkingSession } from 'libs/ui';
import { ParkingFilter2, required, WhiteStyledField, Title, ErrorMsg, DictInput } from 'libs/form';
import { StyledButtonContainer } from './subscription-form.styled';

/**
 * subscriotion form
 * @param {*} props
 * @returns
 */
const SubscriptionForm = (props) => {
  const {
    error,
    destroy,
    refresh,
    setMessage,
    form,
    handleSubmit,
    close,
    type,
    clientId,
    id,
    meanId,
    meanType,
    tariffs,
  } = props;
  const [confirm, setConfirm] = useState(false);
  const [data, setData] = useState(null);

  const { t } = useTranslation();

  const rightSessionActive = user.haveRight('subscriptions.show_active_stay');
  const isAdd = type === 'addForm';

  const closeConfirm = () => {
    setConfirm(false);
  };

  /**
   * after submit
   */
  const afterSubmit = () => {
    //getEntries({ offset: 0 }, null, true);
    //getEntriesCount();
    refresh();
    //removeFormInfo();
    setMessage(t('grid.Изменения сохранены'));
    setTimeout(() => {
      setMessage('');
    }, 3000);
    close();
    destroy(form);
  };

  /**
   * submit
   * @param {*} values
   * @returns
   */
  const submit = (values) => {
    // data
    const test = false;
    const out = { ...values };
    out.is_blocked = out.is_blocked === 'true' || out.is_blocked === true;
    out.mean_type = 'subscription';
    out.vehicle_category = out.vehicle_category ? parseInt(out.vehicle_category) : null;
    out.vehicle_class = out.vehicle_class ? parseInt(out.vehicle_class) : null;
    out.tariff_id = out.tariff_id ? out.tariff_id : null;
    // if (clientId) {
    //   out.client_id = clientId;
    // }
    if (!out.client_id && clientId) {
      out.client_id = clientId;
    }
    if (!clientId && !out.client_id) {
      out.client_id = null;
      out.client_name = null;
    }

    // dates
    if (out.start_at && typeof out.start_at !== 'string') {
      out.start_at = out.start_at.format();
    }
    if (out.end_at && typeof out.end_at !== 'string') {
      out.end_at = out.end_at.format();
    }

    if (!isAdd) {
      out.id = id;
      // confirm
      if (out.mean_id !== props.initialValues.mean_id) {
        setData(out);
        setConfirm(true);
        return false;
      }
    }
    if (test) {
      console.log('🚀 ~ submit ~ out:', out);
      return Promise.resolve();
    }

    // method
    const method = isAdd ? addSubscription : updateSubscription;

    // submit
    return method(out)
      .then(() => {
        afterSubmit();
      })
      .catch((errors) => {
        if (errors.message === 'Превышен лимит мест') {
          throw new SubmissionError({
            _error: t('messages.Превышен лимит мест'),
          });
        }

        if (errors && errors.field && errors.message) {
          const fields = {
            mean_id: 'Номер',
          };
          const fn = fields[errors.field] ?? errors.field;
          const out = {
            _error: fn + ' ' + errors.message.toLowerCase(),
            [errors.field]: errors.message,
          };
          throw new SubmissionError(out);
        }
      });
  };

  const submitAfterConfirm = async () => {
    try {
      await updateSubscription(data);
      afterSubmit();
    } catch (errors) {
      if (errors && errors.data) {
        throw new SubmissionError({
          _error: errors.data.type,
        });
      }
    }
  };

  const cancel = () => {
    close();
    destroy(form);
  };

  const getClients = (name) => {
    return getClientsDict({
      filter_params: { name },
      sort_params: [{ field_name: 'name', direction: 'asc' }],
      paging_params: { limit: 5, offset: 0 },
    });
  };

  const title = isAdd ? t('form.Добавить СО') : t('form.Изменить СО');

  return (
    <Popup2 w={482} top={3}>
      <Title>{title}</Title>
      {error && (
        <div error={error} style={{ marginBottom: 10 }}>
          {t('messages.ВНИМАНИЕ!')}
          <br />
          {t('messages.Превышен лимит бронирований.')}
          <br />
        </div>
      )}

      <form onSubmit={handleSubmit(submit)}>
        <ErrorMsg error={error} />
        <ParkingFilter2 />

        {!clientId && (
          <WhiteStyledField
            selectedName={props.initialValues.client_name}
            label={t('form.Клиент')}
            placeholder={t('form.Клиент')}
            type="autocomplete2"
            name="client_id"
            getList={getClients}
          />
        )}

        <DictInput dict="payment_mean.status" />

        <WhiteStyledField
          type="text"
          label={t('buttons.Номер')}
          placeholder="3786 4678 6467 5656"
          name="mean_id"
          validate={required(t)}
        />

        <WhiteStyledField
          type="text"
          label={t('sections.ГРЗ')}
          placeholder={t('form.А000AA 000 rus')}
          name="vehicle_plate"
        />

        <WhiteStyledField
          type="text"
          label={t('form.Держатель')}
          placeholder={t('form.Введите текст')}
          name="owner"
          validate={required(t)}
        />

        <WhiteStyledField validate={required(t)} type="select" label={t('dict_filter.Класс ТС')} name="vehicle_class">
          <option value={null}>{t('form.Не задано')}</option>
          {getVehicleClassesList()}
        </WhiteStyledField>

        <WhiteStyledField type="select" label={t('form.Категория')} name="vehicle_category">
          <option value={null}>{t('form.Не задано')}</option>
          {getCategoriesList()}
        </WhiteStyledField>

        <WhiteStyledField
          className="shortfield"
          type="dateTime"
          label={t('form.Дата действия с')}
          placeholder={t('form.Дата действия с')}
          name="start_at"
          validate={required(t)}
        />

        <WhiteStyledField
          className="shortfield"
          type="dateTime"
          label={t('form.Дата действия по')}
          placeholder={t('form.Дата действия по')}
          name="end_at"
          validate={required(t)}
        />

        <WhiteStyledField className="shortfield" type="select" label={t('form.Тариф')} name="tariff_id">
          <option value="">{t('form.Не задано')}</option>
          {getOptionsList(tariffs)}
        </WhiteStyledField>

        {!isAdd && rightSessionActive && <ParkingSession meanType={meanType} meanId={meanId} />}

        <StyledButtonContainer space="20px">
          <Button type="button" onClick={cancel}>
            {t('buttons.Отменить')}
          </Button>
          <Button theme={themes.blue}>
            <i className="fa fa-save" />
            {t('buttons.Сохранить')}
          </Button>
        </StyledButtonContainer>

        {confirm && (
          <Confirm
            width={'550px'}
            title={t(
              'form.Номер карты для данной записи будет изменён. Предыдущий номер карты будет удалён. Продолжить?'
            )}
            type="danger"
            cancelButton={{ text: t('buttons.Отмена'), func: closeConfirm }}
            acceptButton={{ text: t('form.Изменить номер карты'), func: submitAfterConfirm, theme: themes.red }}
          />
        )}
      </form>
    </Popup2>
  );
};

//vehicle_category: props.categories ? props.categories[0].id : null,

export default reduxForm({
  form: 'subscriptionForm',
  destroyOnUnmount: false,
})(SubscriptionForm);
