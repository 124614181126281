import React from 'react';

import { user } from 'services';
import {
  Button,
  themes,
  GridUpdateMessage,
  TableHeader,
  GridBox,
  Grid,
  TableHead,
  TableHeadRow,
  TopRow,
} from 'libs/ui';
import Window from 'modules/components/windows/UpdateWindow';
import Row from './Row';
import addForm from './addForm';
import updateForm from './updateForm';
import permForm from './permForm';

import { useTranslation } from 'react-i18next';

const RolesGrid = (props) => {
  const { t } = useTranslation();

  const getItemsList = () => {
    //console.log(props)
    const items = props.entries;
    let list = [];
    if (items) {
      list = Object.keys(items).map((key) => {
        const item = items[key];
        return (
          <Row
            key={item.id}
            formIsShow={props.interface.showUpdateForm}
            setItemInfo={props.setItemInfo}
            updateFormDisplayHandler={props.updateFormDisplayHandler}
            updatePermDisplayHandler={props.updatePermDisplayHandler}
            {...item}
          />
        );
      });
    }
    return list;
  };

  const items = getItemsList();

  return (
    <GridBox>
      <TopRow>
        <p />
        {user.haveRight('roles.add') && (
          <Button theme={themes.blue} onClick={() => props.addFormDisplayHandler(true)}>
            <i className="fa fa-plus" />
            {t('grid.Добавить роль')}
          </Button>
        )}
      </TopRow>
      <Grid>
        <TableHead>
          <TableHeadRow>
            <TableHeader>{t('grid.Название')}</TableHeader>
            <TableHeader width="80px"></TableHeader>
          </TableHeadRow>
        </TableHead>
        <tbody>{items}</tbody>
      </Grid>
      {props.interface.showAddForm ? (
        <Window
          type="add"
          header={t('form.Новая роль')}
          Form={addForm}
          displayHandler={props.addFormDisplayHandler}
          updateMessageDisplayHandler={props.updateMessageDisplayHandler}
          addEntity={props.addItem}
          getEntries={props.getEntries}
        />
      ) : null}
      {props.interface.showUpdateForm ? (
        <Window
          type="update"
          header={t('form.Роль ') + props.itemInfo.name}
          Form={updateForm}
          displayHandler={props.updateFormDisplayHandler}
          updateMessageDisplayHandler={props.updateMessageDisplayHandler}
          updateEntity={props.updateItem}
          getEntries={props.getEntries}
          id={props.itemInfo.id}
          initialValues={{
            //id: props.itemInfo.id,
            name: props.itemInfo.name,
            permissions: props.itemInfo.permissions,
          }}
        />
      ) : null}
      {props.interface.showPermForm && (
        <Window
          w={580}
          top={7}
          type="perm"
          header={t('form.Разрешения для роли ') + props.itemInfo.name}
          Form={permForm}
          displayHandler={props.updatePermDisplayHandler}
          updateMessageDisplayHandler={props.updateMessageDisplayHandler}
          updateEntity={props.update}
          getEntries={props.getEntries}
          id={props.itemInfo.id}
          updatePermission={props.updatePermission}
          initialValues={{
            //id: props.itemInfo.id,
            name: props.itemInfo.name,
            permissions: props.itemInfo.permissions,
          }}
        />
      )}
      {props.interface.showUpdateMessage ? (
        <GridUpdateMessage>{t('messages.Изменение сохранено')}</GridUpdateMessage>
      ) : null}
    </GridBox>
  );
};

export default RolesGrid;
