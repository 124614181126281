import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import styled from 'styled-components';

import { WhiteStyledField } from 'libs/form';
import { Button, ButtonContainer, themes } from 'libs/ui';
import { required } from 'libs/form';

import { useTranslation } from 'react-i18next';

const checkFn = (t) => (value) => {
  if (value) {
    return value.length === 16 ? undefined : t('messages.В номере должно быть 16 символов');
  } else {
    return t('validation.Обязательное поле');
  }
};

const StyledButtonContainer = styled(ButtonContainer)`
  margin-top: 20px;
`;

let Form = (props) => {
  const { t } = useTranslation();

  const submit = (values) => {
    if (props.type === 'add') {
      props.addEntity(values, afterSubmit);
    } else if (props.type === 'update') {
      props.updateEntity(props.id, values, afterSubmit);
    }
  };

  const afterSubmit = () => {
    props.getEntries({ offset: 0 });
    props.displayHandler(false);
    props.updateMessageDisplayHandler(true);
    setTimeout(() => {
      props.updateMessageDisplayHandler(false);
    }, 3000);
    props.destroy(props.form);
  };

  return (
    <form onSubmit={props.handleSubmit(submit)}>
      <WhiteStyledField validate={required(t)} placeholder={t('form.Клиент')} type="text" label={t('form.Клиент')} name="name" autoFocus />
      <WhiteStyledField validate={checkFn(t)} placeholder={t('form.Номер ФН')} type="text" label={t('form.Номер ФН')} name="fn" maxLength={16} />
      <WhiteStyledField
        validate={required(t)}
        placeholder={t('grid.Минимальная сумма')}
        type="text"
        label={t('grid.Минимальная сумма')}
        name="min_amount"
        naturalNumber
      />
      <WhiteStyledField
        validate={required(t)}
        placeholder={t('grid.Сумма скидки')}
        type="text"
        label={t('grid.Сумма скидки')}
        name="amount"
        naturalNumber
      />
      <StyledButtonContainer space="20px">
        <Button
          type="button"
          onClick={() => {
            props.displayHandler(false);
            props.destroy(props.form);
          }}
        >
          {t('buttons.Отменить')}
        </Button>
        <Button theme={themes.blue}>
          <i className="fa fa-save" />
          {t('buttons.Сохранить')}
        </Button>
      </StyledButtonContainer>
    </form>
  );
};

Form = reduxForm()(Form);

Form = connect((store, props) => {
  let formName = '';
  if (props.type === 'add') {
    formName = 'addBenefitForm';
  } else if (props.type === 'update') {
    formName = `addBenefitForm_${props.id}`;
  }

  return {
    form: formName,
    destroyOnUnmount: false,
    initialValues: props.initialValues,
  };
})(Form);

export default Form;
