import { config } from 'config';
import { api } from 'api';

const mapToArray = (source) => {
  return Object.keys(source).map((idx) => {
    return { id: idx, name: source[idx], comment: '' };
  });
};

export class User {
  isLogined;
  storage;
  permissions;
  interface;
  tarifficator;
  maps = {};
  central;

  /**
   * create
   * @param {*} param0
   */
  constructor({ storage }) {
    this.storage = storage;
    this.isLogined = false;
  }

  /**
   * set user info from api
   * @param {*} info
   */
  setUserInfo = (info) => {
    // console.log('🚀 ~ info:', info);
    if (info.config) {
      this.permissions = info.permissions;
      this.id = info.id;

      const data = Object.assign({}, window.config, info.config);
      // console.log('🚀 ~ info.config:', info.config);
      this.interface = data.interface;

      // console.log('🚀 ~ data.interface:', data.interface);
      this.tarifficator = data.tarifficator;
      data.maps = {};
      data.maps.vehicle_category = mapToArray(info.config.vehicle_category_map);
      data.maps.vehicle_categoryMap = info.config.vehicle_category_map;
      data.maps.vehicle_class = mapToArray(info.config.vehicle_class_map);
      data.maps.vehicle_classMap = info.config.vehicle_class_map;
      data.maps.parking = mapToArray(info.config.parking_map);
      data.maps.parkingMap = info.config.parking_map;
      data.central = data.maps.parking.length > 1 || data.test;
      this.maps = data.maps;
      this.central = data.central;

      window.config = { ...data };
    }
    this.info = info;
    this.isLogined = true;
  };

  getId = () => {
    return this.id;
  };

  /**
   * logout
   */
  logoutUser = () => {
    this.isLogined = false;
    api.logout();
  };

  /**
   * login
   * @param {*} params
   * @returns
   */
  login = async (params) => {
    return await api.call('user.login', params).then((data) => {
      //console.log('🚀 ~ returnawaitapi.call ~ data:', data);
      this.setUserInfo(data);
      window.location = '/';
    });
  };

  /**
   * get user info from api
   * @returns
   */
  getInfo = async () => {
    // if no token no call
    if (!this.storage.getItem('token')) {
      return Promise.resolve();
    }

    // get info
    const res = await api.call('users.info').then((data) => {
      this.setUserInfo(data);
    });

    return res;
  };

  /**
   * have right
   * @param {*} right
   * @returns
   */
  haveRight(right) {
    const permissions = this.permissions;
    if (!permissions) {
      return;
    }
    // console.log('🚀 ~ haveRight ~ permissions[right]:', right,  permissions[right]);
    return permissions[right];
  }

  /**
   * can user login
   * @returns
   */
  canUserLogin() {
    return this.haveRight('clients.lk_add') || config.use_client_login;
  }

  /**
   * is operator
   * @returns
   */
  isOperator() {
    return this.info.access_level === 1;
  }

  getCategories() {
    return this.maps.vehicle_category;
  }

  getVehicleClasses() {
    return this.maps.vehicle_class;
  }

  getPermissions() {
    return this.permissions;
  }

  getTarifficator() {
    return this.tarifficator;
  }

  inInterface(id) {
    return this.interface[id] !== undefined && !this.interface[id];
  }

  getToken() {
    return this.storage.getItem('token');
  }

  getMaps() {
    return this.maps;
  }

  isCentral() {
    return this.central;
  }

  getGridConfig() {
    const out = {};
    const prepareEl = (el) => {
      const a = el.name.split('_');
      const cnt = a.length;
      let prefix = '';
      if (!out[a[0]]) {
        out[a[0]] = {};
      }
      prefix += a[0] + '_';

      if (cnt > 1 && !out[a[0]][a[1]]) {
        out[a[0]][a[1]] = {};
      }
      if (cnt > 1) {
        prefix += a[1] + '_';
      }

      if (cnt > 2 && !out[a[0]][a[1]][a[2]]) {
        out[a[0]][a[1]][a[2]] = [];
      }
      if (cnt > 2) {
        prefix += a[2] + '_';
      }

      const fn = el.name.substring(prefix.length);
      // console.log('🚀 ~ prefix:', prefix);
      // console.log('🚀 ~ fn:', fn);
      out[a[0]][a[1]][a[2]].push({ name: fn, title: fn, active: el.active });
    };

    const items = Object.keys(this.interface)
      .filter((el) => el.indexOf('grid_') !== -1)
      .map((fn) => {
        return { name: fn, active: this.interface[fn] };
      });
    // console.log('🚀 ~ getGridConfig ~ items:', items);

    items.forEach((el) => {
      prepareEl(el);
    });
    return out;
  }
}
