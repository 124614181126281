import { useState, useEffect } from 'react';

import { subscriptionsBill, subscriptionsExtend } from 'services/payments-service';
import { Loader, Button, themes, Popup2 } from 'libs/ui';
import { Content, Buttons, Title, Info } from './extension-form.styled';

import { useTranslation } from 'react-i18next';

export const ExtensionForm = (props) => {
  const { t } = useTranslation();

  const { id, mean_id, close, refresh } = props;
  const [info, setInfo] = useState(null);

  useEffect(() => {
    subscriptionsBill(id).then((data) => {
      setInfo(data);
    });
  }, [id]);

  /**
   * activate
   * @returns
   */
  const activate = () => {
    return subscriptionsExtend(id)
      .then(() => {
        close(true);
        props.refresh();
      })
      .catch((errors) => {
        if (errors && errors.data) {
        }
      });
  };

  if (!info) {
    return (
      <Popup2 w={470}>
        <Content>
          <Loader height={'80px'} />
          <Buttons>
            <Button type="button" onClick={() => close()}>
              {t('buttons.Отменить')}
            </Button>
          </Buttons>
        </Content>
      </Popup2>
    );
  }

  const balance = Math.round(info.amount / 100);
  const date = info.pass_date;

  return (
    <Popup2 w={470}>
      <Content>
        <Title>
          {t('messages.Активировать абонемент №')}
          {mean_id}
        </Title>
        <Info>
          <p>
            {t('messages.Абонемент будет активирован до')} <b>{date}</b>,
          </p>
          <p>
            {t('messages.Баланс')}: <b>{balance}</b>
            {t('grid.₽')}. {t('messages.Продолжить?')}
          </p>
        </Info>
        <Buttons>
          <Button type="button" onClick={() => close()}>
            {t('buttons.Отменить')}
          </Button>
          <Button theme={themes.blue} onClick={activate}>
            {t('buttons.Активировать')}
          </Button>
        </Buttons>
      </Content>
    </Popup2>
  );
};
