import React, { useEffect } from 'react';
import { reduxForm } from 'redux-form';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

import { Container, Input, Select, ButtonsContainer } from 'libs/form';
import { UseBtn } from 'libs/ui';
import { ResetBtn } from 'libs/ui';

const StyledContainer = styled(Container)`
  form {
  }
`;

const initialValues = {};

const Filter = ({ handleSubmit, reset, setInitial }) => {
  const { t } = useTranslation();

  useEffect(() => {
    setInitial(initialValues);
  }, [setInitial]);

  return (
    <StyledContainer>
      <form onSubmit={handleSubmit}>
        <Input width="220px" name="client_name" type="text" label={t('form.ФИО')} placeholder={t('form.ФИО')} />

        <Input
          width="220px"
          name="contract_number"
          type="text"
          label={t('filter.№ договора паркирования')}
          placeholder={t('filter.№ договора паркирования')}
        />

        <Select width="150px" name="is_payed" label={t('grid.Статус оплаты')} type="select">
          <option value="">{t('filter.Все')}</option>
          <option value="payed">{t('grid.Оплачено')}</option>
          <option value="notpayed">{t('grid.Не оплачено')}</option>
        </Select>

        <Input
          width="220px"
          name="mean_number"
          type="text"
          label={t('dict_filter.№ СО')}
          placeholder={t('dict_filter.№ СО')}
        />

        <ButtonsContainer>
          <ResetBtn onClick={reset} />
          <UseBtn />
        </ButtonsContainer>
      </form>
    </StyledContainer>
  );
};

export default reduxForm({
  form: 'promise_payFilter',
  destroyOnUnmount: false,
  initialValues: initialValues,
})(Filter);
