import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import styled from 'styled-components';
import { WhiteStyledField } from 'libs/form';
import { Button, themes, ButtonContainer } from 'libs/ui';
import { required } from 'libs/form';
import { useTranslation } from 'react-i18next';

const StyledButtonContainer = styled(ButtonContainer)`
  margin-top: 20px;
`;

const Form = (props) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    addEntity,
    getEntries,
    updateMessageDisplayHandler,
    displayHandler,
    destroy,
    form,
    initialValues,
  } = props;

  const submit = (values) => {
    let decorateValues = { ...values };
    return addEntity(decorateValues, afterSubmit);
  };

  const afterSubmit = () => {
    getEntries({ offset: 0 });
    updateMessageDisplayHandler(true);
    setTimeout(() => {
      updateMessageDisplayHandler(false);
    }, 3000);
    displayHandler(false);
    destroy(form);
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <WhiteStyledField
        type="text"
        label={t('grid.Название')}
        placeholder={t('grid.Название')}
        name="name"
        validate={required(t)}
        autoFocus
        tabIndex={0}
      />
      <StyledButtonContainer space="20px">
        <Button
          type="button"
          onClick={() => {
            displayHandler(false);
            destroy(form);
          }}
        >
          {t('buttons.Отменить')}
        </Button>
        <Button theme={themes.blue}>
          <i className="fa fa-save" />
          {t('buttons.Сохранить')}
        </Button>
      </StyledButtonContainer>
    </form>
  );
};

export default connect((__store, props) => {
  return {
    form: 'addRoleForm',
    destroyOnUnmount: false,
    initialValues: props.initialValues,
  };
})(reduxForm()(Form));
