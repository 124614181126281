import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

import { WhiteStyledField } from 'libs/form';
import { Button, themes, ButtonContainer } from 'libs/ui';

const StyledButtonContainer = styled(ButtonContainer)`
  margin-top: 20px;
`;

const Form = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const formId = `updateRoleForm_${props.id}`;
  const initialValues = props.initialValues;

  const submit = (values) => {
    let decorateValues = { ...values };

    return props.updateEntity(props.id, decorateValues, afterSubmit).then((errors) => {
      if (errors && errors.data && errors.data.type === 'duplicate') {
        throw new SubmissionError({
          username: 'duplicate',
        });
      }
    });
  };

  const afterSubmit = () => {
    props.getEntries({ offset: 0 });
    props.updateMessageDisplayHandler(true);

    setTimeout(() => {
      props.updateMessageDisplayHandler(false);
    }, 3000);

    props.displayHandler(false);
    dispatch({ type: 'redux-form/REMOVE', meta: { form: formId } });
  };

  return (
    <form onSubmit={props.handleSubmit(submit)}>
      {/* 
      <WhiteStyledField
          type='select'
          label='Статус'
          name='active'
      >
          <option value={true}>Активен</option>
          <option value={false}>Блокирован</option>
      </WhiteStyledField> 
      */}
      <Field
        component={WhiteStyledField}
        type="text"
        label={t('grid.Название')}
        placeholder={t('grid.Название')}
        name="name"
        autoFocus
      />
      <StyledButtonContainer space="20px">
        <Button
          type="button"
          onClick={() => {
            props.displayHandler(false);
            dispatch({ type: 'redux-form/REMOVE', meta: { form: formId } });
          }}
        >
          {t('buttons.Отменить')}
        </Button>
        <Button theme={themes.blue}>
          <i className="fa fa-save" />
          {t('buttons.Сохранить')}
        </Button>
      </StyledButtonContainer>
    </form>
  );
};

// Обернем в reduxForm и export
export default reduxForm({
  destroyOnUnmount: false,
})(Form);
