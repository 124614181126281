import { api } from 'api';

/**
 * vehicle plates
 ****************************/
/**
 * add plate
 * @param {*} data
 * @returns
 */
export const addPlate = (data) => {
  //const method = isAdd ? 'csc.add' : 'csc.update';
  return api.call('plates.add', data);
};

/**
 * update plate
 * @param {*} data
 * @returns
 */
export const updatePlate = (data) => {
  return api.call('plates.update', data);
};

/**
 * delete plate
 * @param {*} id
 * @returns
 */
export const deletePlate = (id) => {
  return api.call('plates.delete', { id: id });
};

/**
 * epm
 ****************************/

/**
 * add epm
 * @param {*} data
 * @returns
 */
export const addEpm = (data) => {
  //const method = isAdd ? 'csc.add' : 'csc.update';
  return api.call('csc.add', data);
};

/**
 * update epm
 * @param {*} data
 * @returns
 */
export const updateEpm = (data) => {
  return api.call('csc.update', data);
};

/**
 * delete epm
 * @param {*} id
 * @returns
 */
export const deleteEpm = (id) => {
  return api.call('csc.delete', { id: id });
};

/**
 * subscriptions
 ****************************/

/**
 * delete Subscription
 * @param {*} id
 * @returns
 */
export const deleteSubscription = (id) => {
  return api.call('subscriptions.delete', { id: id });
};

/**
 * bill subsc
 * @param {*} id
 * @returns
 */
export const subscriptionsBill = (id) => {
  return api.call('subscriptions.bill', { id: id });
};

/**
 * extend subscr
 * @param {*} id
 * @returns
 */
export const subscriptionsExtend = (id) => {
  return api.call('subscriptions.extension', { id: id });
};

/**
 * add subs
 * @param {*} data
 * @returns
 */
export const addSubscription = (data) => {
  //const method = isAdd ? 'csc.add' : 'csc.update';
  return api.call('subscriptions.add', data);
};

/**
 * update subs
 * @param {*} data
 * @returns
 */
export const updateSubscription = (data) => {
  // console.log('🚀 ~ updateSubscription ~ data:', data);
  return api.call('subscriptions.update', data);
};

/**
 * add comment
 * @param {*} info
 * @param {*} type
 * @returns
 */
export const addPaymentComment = (data) => {
  return api.call('comment.add', { linked_id: data.id, comment: data.comment });
};

/**
 * get parking session status
 * @param {*} id
 * @param {*} type
 * @returns
 */
export const getParkingSessionStatus = (id, type) => {
  return api.call('stays.get', { mean_type: type, mean_id: id });
};

/**
 * park session create
 * @param {*} id
 * @param {*} type
 * @returns
 */
export const parkingSessionCreate = (id, type) => {
  return api.call('stays.manual_create', { mean_type: type, mean_id: id });
};

/**
 * park session close
 * @param {*} stayId
 * @returns
 */
export const parkingSessionClose = (stayId) => {
  return api.call('stays.manual_close', { stay_id: stayId });
};

/**
 * get tariffs dict
 * @returns
 */
export const getTariffsDict = () => {
  // api.call('grey_plate.list', { filter_params: {}, sort_params: [], paging_params: {} });

  return api.call('dictionaries.tariff_list', { filter_params: {}, sort_params: [], paging_params: {} }).then((data) => {
    return data.map((el) => {
      return { id: el.id, name: el.name };
    });
  });
};

/**
 * get clients dict
 * @returns
 */
export const getClientsDict = (params = { filter_params: {}, sort_params: [], paging_params: {} }) => {
  return api.call('dictionaries.client_list', params).then((data) => {
    return data.map((el) => {
      return { id: el.id, name: el.name };
    });
  });
};

/**
 * get rfid tariffs list
 * @param {*} id
 * @returns
 */
export const getRfidTariffs = () => {
  return api.call('rfid.tariff_list', { filter_params: {}, sort_params: [], paging_params: {} }).then((data) => {
    return data.map((el) => {
      return { id: el.id, name: el.name };
    });
  });
};

/**
 * replace tariffs
 * @param {*} oldTariffId
 * @param {*} newTariffId
 * @returns
 */
export const replaceRfidTariffs = (oldTariffId, newTariffId) => {
  const params = {
    old_id: oldTariffId,
    new_id: newTariffId,
  };

  return api.call('tariff.replace', params);
};

/**
 * get tariffs list for replace form
 * @param {*} params
 * @returns
 */
export const getTariffs = (params) => {
  return api.call('rfid.tariff_list', params);
};

export const changeRfidStatus = (params) => {
  return api.call('rfid.change_status', params);
};

/**
 * add rfid
 * @param {*} data
 * @returns
 */
export const addRfid = (data) => {
  //const method = isAdd ? 'csc.add' : 'csc.update';
  return api.call('rfid.add', data);
};

/**
 * update rfid
 * @param {*} data
 * @returns
 */
export const updateRfid = (data) => {
  if (data.client_id1) {
    delete data.client_id1;
  }
  return api.call('rfid.update', data);
};

/**
 * get grey list
 * @param {*} params
 * @returns
 */
export const getGreyPlate = (params) => {
  return api.call('grey_plate.list', params);
};

/**
 * add to grey list
 * @param {*} params
 * @returns
 */
export const addToGreyPlate = (params) => {
  return api.call('grey_plate.add', params);
};

/**
 * delete from grey list
 * @param {*} id
 * @returns
 */
export const deleteFromGreyPlate = (id) => {
  return api.call('grey_plate.delete', { id: id });
};

/**
 * update grey list
 * @param {*} params
 * @returns
 */
export const updateGreyPlate = (params) => {
  return api.call('grey_plate.update', params);
};
