import { useState } from 'react';

import { config } from 'config';
import { Popup2, Button, themes, ButtonContainer } from 'libs/ui';
import { AttentionIcon, NewTicketContent, NewTicketHeader, NewTicketInfo, StyledForm, Label } from './FreeBlock.styled';

import { useTranslation } from 'react-i18next';

export const FreeBlock = (props) => {
  const [number, setNumber] = useState('');
  const [invalid, setInvalid] = useState(false);
  const benefitReasons = config.benefit_reasons;
  const withReasons = benefitReasons !== undefined && benefitReasons.length > 0;

  const { t } = useTranslation();

  /**
   * set free
   * @param {*} event
   */
  const setFree = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (number) {
      props.setFree(number);
      props.close();
    } else {
      setInvalid(true);
    }
  };

  const change = (e) => setNumber(e.target.value);

  const optionsEl = withReasons
    ? benefitReasons.map((el, idx) => {
        return (
          <option key={idx} value={el}>
            {el}
          </option>
        );
      })
    : null;

  return (
    <Popup2 w={480}>
      <AttentionIcon src="/images/icon_!.png" alt="" />
      <NewTicketContent>
        <NewTicketHeader>{t('form.Льгота')}</NewTicketHeader>
        <NewTicketInfo style={{ marginBottom: '20px' }}>
          {t('form.Внесите причину предоставления льготы и нажмите «Сохранить»')}
        </NewTicketInfo>
        <StyledForm $invalid={invalid} onSubmit={setFree}>
          <div>
            <p>{t('form.Причина')}:</p>
            <input onChange={change} value={number} placeholder={t('form.Введите причину')} />
          </div>
          {withReasons && (
            <div className="block">
              <Label>{t('form.Измените или выберите из списка')}</Label>
              <select defaultValue={benefitReasons[0]} onChange={change}>
                {optionsEl}
              </select>
            </div>
          )}
        </StyledForm>
        <ButtonContainer space="20px" justifyContent={'flex-start'}>
          <Button fontSize="16px" onClick={props.close}>
            {t('buttons.Отменить')}
          </Button>
          <Button theme={themes.blue} fontSize="16px" onClick={setFree}>
            {t('buttons.Сохранить')}
          </Button>
        </ButtonContainer>
      </NewTicketContent>
    </Popup2>
  );
};
