// import { notify } from 'libs/common';
import { getPageConfig, setPageSavedConfig } from 'services/grid-service';
import { Button, themes } from '../buttons';
import { Filters, Content, Title, Buttons } from './settings-filters.styled';
import { FilterConfig } from './filter-config';

import { useTranslation } from 'react-i18next';

/**
 * get filters from localstorage
 * or from default config
 * @param {*} param0
 * @returns
 */

export const SettingsFilters = ({ page, close }) => {
  const { t } = useTranslation();

  const pageConfig = getPageConfig(page);
  // console.log('🚀 ~ SettingsFilters ~ pageConfig:', pageConfig);

  /**
   * get filters config
   * @returns
   */
  const getFilters = () => {
    return pageConfig.filters.map((el, idx) => {
      return <FilterConfig key={idx} item={el} itemIdx={idx} config={pageConfig} />;
    });
  };

  /**
   * close window
   */
  const cancel = () => {
    close();
  };

  /**
   * save data
   */
  const save = () => {
    // console.log('🚀 ~ save ~ pageConfig:', pageConfig);
    setPageSavedConfig(page, pageConfig);
    close(true);
    // window.location.reload();
  };

  const filtersEl = getFilters();

  return (
    <Content>
      <Title>Настройка фильтров</Title>
      <Filters>{filtersEl}</Filters>
      <Buttons>
        <Button theme={themes.gray} onClick={cancel}>
          {t('buttons.Отмена')}
        </Button>
        <Button theme={themes.red} onClick={save}>
          {t('buttons.Сохранить')}
        </Button>
      </Buttons>
    </Content>
  );
};
