import React from 'react';

import { user } from 'services';
import {
  GridUpdateMessage,
  TableHeader,
  Button,
  themes,
  GridBox,
  Grid,
  TableHead,
  TableHeadRow,
  TopRow,
} from 'libs/ui';
import Window from 'modules/components/windows/UpdateWindow';
import addForm from './forms/addForm';
import updateForm from './forms/updateForm';
import Row from './Row';

import { useTranslation } from 'react-i18next';

const UsersGrid = (props) => {
  const { t } = useTranslation();

  const getUserList = () => {
    const users = props.entries || props.users;

    let list = [];
    if (users) {
      for (let key in users) {
        list.push(
          <Row
            key={users[key].id}
            formIsShow={props.interface.showUpdateForm}
            setUserInfo={props.setUserInfo}
            updateFormDisplayHandler={props.updateFormDisplayHandler}
            {...users[key]}
          />
        );
      }
    }
    return list;
  };

  return (
    <GridBox>
      <TopRow>
        <p />
        {user.haveRight('users.add') && (
          <Button theme={themes.blue} onClick={() => props.addFormDisplayHandler(true)}>
            <i className="fa fa-plus" />
            {t('buttons.Добавить пользователя')}
          </Button>
        )}
      </TopRow>
      <Grid>
        <TableHead>
          <TableHeadRow>
            <TableHeader width="140px">{t('form.Логин')}</TableHeader>
            <TableHeader width="190px" /*sortField="role_id"*/>{t('form.Роль')}</TableHeader>
            <TableHeader width="130px">{t('dict_filter.Статус')}</TableHeader>
            <TableHeader>{t('form.ФИО')}</TableHeader>
            <TableHeader width="180px">{t('form.E-mail')}</TableHeader>
            <TableHeader width="180px" align="right">
              {t('grid.Телефон')}
            </TableHeader>
          </TableHeadRow>
        </TableHead>
        <tbody>{getUserList()}</tbody>
      </Grid>
      {props.interface.showAddForm && (
        <Window
          type="add"
          header={t('form.Новый пользователь')}
          Form={addForm}
          displayHandler={props.addFormDisplayHandler}
          updateMessageDisplayHandler={props.updateMessageDisplayHandler}
          addEntity={props.addUser}
          refresh={props.refresh}
          getEntries={props.getEntries}
        />
      )}
      {props.interface.showUpdateForm && (
        <Window
          type="update"
          header={props.userInfo?.role}
          Form={updateForm}
          displayHandler={props.updateFormDisplayHandler}
          updateMessageDisplayHandler={props.updateMessageDisplayHandler}
          updateEntity={props.updateUser}
          getEntries={props.getEntries}
          refresh={props.refresh}
          id={props.userInfo.id}
          initialValues={{
            is_active: String(props.userInfo?.is_active),
            role_id: props.userInfo?.role_id,
            username: props.userInfo?.username,
            name: props.userInfo.name,
            password: props.userInfo?.password,
            email: props.userInfo?.email,
            phone: props.userInfo?.phone,
          }}
        />
      )}
      {props.interface.showUpdateMessage && <GridUpdateMessage>{t('messages.Изменение сохранено')}</GridUpdateMessage>}
    </GridBox>
  );
};

export default UsersGrid;
