import React from 'react';
import { reduxForm } from 'redux-form';
import { DictFilter, Container, Input, ButtonsContainer } from 'libs/form';
import { ResetBtn, UseBtn } from 'libs/ui';
import { useTranslation } from 'react-i18next';

const Filter = (props) => {
  const { handleSubmit, reset, usersStatus } = props;
  const { t } = useTranslation();

  const getOptionsList = (field) => {
    const data = props[field];

    if (Array.isArray(data)) {
      return data.map((item) => (
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      ));
    }
    return null;
  };

  return (
    <Container /*height='160px'*/>
      <form onSubmit={handleSubmit}>
        <DictFilter w={140} dict="users.status" />

        <Input
          width="220px"
          name="username"
          type="text"
          label={t('form.Логин')}
          placeholder={t('form.Введите текст для поиска')}
        />

        <Input
          width="220px"
          name="name"
          type="text"
          label={t('form.ФИО')}
          placeholder={t('form.Введите текст для поиска')}
        />

        <Input
          width="220px"
          name="email"
          type="text"
          label={t('form.E-mail')}
          placeholder={t('form.Введите текст для поиска')}
        />

        <ButtonsContainer>
          <ResetBtn onClick={reset} />
          <UseBtn />
        </ButtonsContainer>
      </form>
    </Container>
  );
};

export default reduxForm({
  form: 'usersFilter',
  destroyOnUnmount: false,
  initialValues: { is_active: 'true' },
})(Filter);
