import React, { useState, useEffect } from 'react';
import { reduxForm } from 'redux-form';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import {
  DateSelect,
  DictFilter,
  ParkingFilter,
  Container,
  Input,
  Select,
  DoubleField,
  DateTime,
  ButtonsContainer,
} from 'libs/form';
import { ResetBtn, UseBtn, Loader2 } from 'libs/ui';

const Filter = ({ handleSubmit, reset, change, apps, setInitial }) => {
  const [terminals, setTerminals] = useState(apps);

  const { t } = useTranslation();

  useEffect(() => {
    setInitial(initialValues);
  }, [setInitial]);

  const getTerminalOptions = () => {
    return terminals.map((rack) => (
      <option key={rack.id} value={rack.id}>
        {rack.name}
      </option>
    ));
  };

  if (!terminals) {
    return <Loader2 />;
  }

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <DateSelect from_name="ts_from" to_name="ts_to" change={change}>
          <DoubleField>
            <DateTime label={t('grid.Дата и время')} width="160px" name="ts_from" type="dateTime" />
            <DateTime width="160px" name="ts_to" type="dateTime" />
          </DoubleField>
        </DateSelect>

        <DoubleField>
          <Input
            width="80px"
            name="amount_from"
            type="text"
            label={t('grid.Сумма')}
            placeholder={t('filter.От')}
            naturalNumber
          />
          <Input width="80px" name="amount_to" type="text" placeholder={t('filter.До')} naturalNumber />
        </DoubleField>

        <DictFilter w={110} dict="payment.payment_type" />

        <Select label={t('grid.№ устройства')} width="145px" name="payment_terminal" type="select">
          <option value="">{t('filter.Все')}</option>
          {getTerminalOptions()}
        </Select>

        <Input width="100px" name="check_number" type="text" label={t('grid.Счет N')} placeholder="89546" />

        <Input width="70px" name="shift" type="text" label={t('grid.Смена')} placeholder="89546" />

        <DoubleField>
          <DictFilter w={120} dict="payment.mean_type" />
          <Input width="140px" name="mean_number" type="text" label={t('grid.СО')} placeholder="1234567890" />
        </DoubleField>
        <ParkingFilter />

        <DictFilter w={110} dict="payment.success" />

        <ButtonsContainer>
          <ResetBtn onClick={reset} />
          <UseBtn />
        </ButtonsContainer>
      </form>
    </Container>
  );
};

const initialValues = {
  ts_from: moment().startOf('day').format(),
  ts_to: moment().endOf('day').format(),
};

export default reduxForm({
  form: 'cashboxOperationsFilter',
  destroyOnUnmount: false,
  initialValues: initialValues,
})(Filter);
