import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { SubmissionError, reduxForm, Field, useForm } from 'redux-form';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

import { required, WhiteStyledField, DictInput, ErrorMsg } from 'libs/form';
import { Button, themes, ButtonContainer } from 'libs/ui';

const StyledButtonContainer = styled(ButtonContainer)`
  margin-top: 20px;
`;

const Form = (props) => {
  const { t } = useTranslation();

  const {
    id,
    initialValues,
    handleSubmit,
    error,
    displayHandler,
    refresh,
    updateMessageDisplayHandler,
    updateEntity,
    destroy,
  } = props;
  const [role, setRole] = useState(initialValues.role_id.toString());
  const noEdit = initialValues.is_active === 'null' || initialValues.is_active === null;

  useEffect(() => {
    setRole(initialValues.role_id.toString());
  }, [initialValues.role_id]);

  const submit = (values) => {
    let decorateValues = {};

    for (let key in values) {
      switch (key) {
        case 'role_id':
          decorateValues[key] = Number(values[key]);
          break;
        case 'is_active':
          decorateValues[key] =
            values[key].toString() === 'true' ? true : values[key].toString() === 'false' ? false : null;
          break;
        case 'password':
          if (values[key]) {
            decorateValues[key] = values[key];
          }
          break;
        default:
          decorateValues[key] = values[key];
          break;
      }
    }

    return updateEntity(id, decorateValues, afterSubmit)
      .then((errors) => {
        if (errors && errors.data && errors.data.type === 'duplicate') {
          throw new SubmissionError({
            username: 'duplicate',
          });
        }
      })
      .catch((errors) => {
        if (errors && errors.data) {
          throw new SubmissionError({
            _error: errors.data.type,
          });
        }
        if (errors && errors.message) {
          throw new SubmissionError({
            _error: errors.message,
          });
        }
      });
  };

  const afterSubmit = () => {
    refresh();
    updateMessageDisplayHandler(true);
    setTimeout(() => {
      updateMessageDisplayHandler(false);
    }, 3000);
    displayHandler(false);
    destroy(props.form);
  };

  const changeRole = (event) => {
    setRole(event.target.value);
  };

  const incassator = role === '2';
  const roles = Object.keys(window.config.role_map).map((idx) => (
    <option key={idx} value={idx}>
      {window.config.role_map[idx]}
    </option>
  ));

  return (
    <form onSubmit={handleSubmit(submit)} onChange={changeRole}>
      <ErrorMsg error={error} />
      <DictInput dict="users.status" disabled={noEdit} />
      <Field
        component={WhiteStyledField}
        type="select"
        label={t('form.Роль')}
        name="role_id"
        disabled={noEdit}
        className="shortfield"
      >
        {roles}
      </Field>
      <Field
        component={WhiteStyledField}
        disabled={noEdit}
        type="text"
        label={incassator ? t('form.Номер БСК') : t('form.Логин')}
        placeholder={incassator ? t('form.Номер БСК') : t('form.Логин')}
        name="username"
        validate={required(t)}
      />
      <Field
        component={WhiteStyledField}
        type="text"
        label={t('form.ФИО')}
        placeholder={t('form.Иванов Иван Иванович')}
        name="name"
        // validate={required(t)}
        disabled={noEdit}
      />
      <Field
        component={WhiteStyledField}
        type="text"
        label={t('form.Новый пароль')}
        placeholder="password"
        name="password"
        className={incassator ? 'hidden' : ''}
        disabled={noEdit}
      />
      <Field
        component={WhiteStyledField}
        type="text"
        label="E-mail"
        placeholder="someone@gmail.com"
        name="email"
        disabled={noEdit}
      />
      <Field
        component={WhiteStyledField}
        type="text"
        label="Телефон"
        placeholder="+7 956 456-56-78"
        name="phone"
        disabled={noEdit}
      />
      <StyledButtonContainer space="20px">
        <Button
          type="button"
          onClick={() => {
            displayHandler(false);
            destroy(props.form);
          }}
        >
          {t('buttons.Отменить')}
        </Button>
        <Button theme={themes.blue} disabled={noEdit}>
          <i className="fa fa-save" />
          {t('buttons.Сохранить')}
        </Button>
      </StyledButtonContainer>
    </form>
  );
};

const mapStateToProps = (state, props) => ({
  form: `updateUserForm_${props.id}`,
  destroyOnUnmount: false,
  initialValues: props.initialValues,
});

export default connect(mapStateToProps)(reduxForm()(Form));
