import React from 'react';
import styled, { css } from 'styled-components';

import { user } from 'services';
import { Select } from './fields';
import { useTranslation } from 'react-i18next';

const Place = styled.div`
  margin: 0 0px 0 20px;

  ${Select} {
    margin-right: 0;
  }

  ${(props) =>
    props.ml0 &&
    css`
      margin: 0;
    `}
`;

export const ParkingFilter = (props) => {
  const { t } = useTranslation;

  if (!window.config.central) {
    return null;
  }

  const parkings = user.getMaps().parking.map((el, idx) => {
    return (
      <option key={idx} value={el.id}>
        {el.name}
      </option>
    );
  });

  return (
    <Place ml0={props.ml0}>
      <Select
        black={props.black}
        width="140px"
        name="parking_id"
        type="select"
        label={t('grid.Парковка')}
        defaultValue={props.defaultValue}
      >
        <option value="">{'filter.Все'}</option>
        {parkings}
      </Select>
    </Place>
  );
};
