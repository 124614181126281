import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { user } from 'services';
import { routes } from 'router';
import { getMeanType } from 'services/types';
import { TableExt, CloseCardButton, Button, themes } from 'libs/ui';
import { Headline, PageWrapper } from 'modules/components/pageComponents';
import { Wrapper as CardWrapper } from 'modules/components/card/styledComponents';
import Warning from 'modules/components/warnings';
import {
  getStayInfo,
  removeStayInfo,
  changeSuspicious,
  changeHandicapped,
  closeParking,
} from 'store/ducks/models/stays';
import { setParameters, resetParameters } from 'store/ducks/interface/widgetPopup';
import {
  MeanTypeId,
  MeanId,
  AddtionalWrapper,
  ButtonContainer2,
  ClientName,
  FreeButton,
  HandicappedBlock,
  Hover,
} from './stay-card.styled';
import Promise from './Promise';
import NewPay from './NewPay';
import Invalid from './Invalid';
import { FreeBlock } from './FreeBlock';
import { TicketAction, NewTicketAction, AppPayAction, SetTariffAction } from './actions';
import { getTableParams } from 'grids/stay/grid.params';

import { StayCardRide } from './ride';
import { Payments } from './payments/payments';

const StayCard = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const info = useSelector((store) => store.stays.stay);

  const [showInvalid, setShowInvalid] = useState(false);
  const [showFree, setShowFree] = useState(false);
  const [showNewPay, setShowNewPay] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [showPromise, setShowPromise] = useState(false);

  const hoverDelay = useRef(null);
  const hoverDelayClose = useRef(null);
  const hoverPlace = useRef(null);

  useEffect(() => {
    const id = getStayId();
    dispatch(removeStayInfo());
    dispatch(getStayInfo(id));

    return () => {
      clearTimeout(hoverDelay.current);
      clearTimeout(hoverDelayClose.current);
    };
  }, [dispatch]);

  const toggleSuspicious = () => {
    const data = !info.is_suspicious;
    dispatch(changeSuspicious(getStayId(), data));
  };

  const toggleInvalid = () => {
    const data = !info.is_handicapped;
    dispatch(changeHandicapped(getStayId(), data));
  };

  const getStayId = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('id');
  };

  const closeCard = () => {
    dispatch(removeStayInfo());
    if (document.referrer) {
      history.goBack();
    } else {
      history.push(routes.stays);
    }
  };

  const openNewPay = () => setShowNewPay(true);
  const closeNewPay = () => setShowNewPay(false);
  const openFree = () => setShowFree(true);
  const closeFree = () => setShowFree(false);
  const openInvalid = () => setShowInvalid(true);
  const closeInvalid = () => setShowInvalid(false);

  const getRides = () => {
    const rides = info.periods || [];
    return rides.map((item, idx) => {
      const from = info.passages[item.id_1] || { data: { photos: {} } };
      const to = info.passages[item.id_2] || { data: { photos: {} } };
      const last = rides.length === idx + 1;

      return (
        <StayCardRide
          key={`period${item.id_1}${item.id_2}`}
          from={from}
          to={to}
          last={last}
          amount={item.amount}
          duration={item.duration}
          is_suspicious={info.is_suspicious}
        />
      );
    });
  };

  const mouseEnter = () => {
    clearTimeout(hoverDelayClose.current);
    hoverDelay.current = setTimeout(setWidgetParams, 500);
  };

  const mouseLeave = (e) => {
    const target = e.relatedTarget;
    hoverDelayClose.current = setTimeout(() => {
      const widget = document.getElementById('widget-popup');
      if (target !== widget) {
        clearTimeout(hoverDelay.current);
        dispatch(resetParameters());
      }
    }, 500);
  };

  const setWidgetParams = () => {
    if (hoverPlace.current) {
      const el = hoverPlace.current;
      const rect = el.getBoundingClientRect();
      const leftShift = 250;
      const parameters = {
        content: el.innerText,
        width: 550,
        fontSize: '14px',
        top: rect.bottom + 15,
        left: rect.left - leftShift,
        parent: el,
        clearParentClose: () => clearTimeout(hoverDelayClose.current),
      };
      dispatch(setParameters(parameters));
    }
  };

  const confirmCloseParking = () => setConfirm(true);
  const promiseForm = () => setShowPromise(true);
  const hidePromiseForm = () => setShowPromise(false);

  const second = info && info.is_secondentry;
  const tableParams = getTableParams(false);

  return (
    <PageWrapper>
      <Headline>
        <p>{t('cards.Карточка парковки')}</p>
        <CloseCardButton close={closeCard} />
      </Headline>
      {info && (
        <>
          <TableExt rows={{ count: 1, items: [info] }} {...tableParams} />
          <CardWrapper>
            <MeanTypeId>
              {info.mean_type && (
                <ClientName>{`${getMeanType(info.mean_type)} 
              ${info.mean_number || ''}`}</ClientName>
              )}
              {info.mean_type === 'receipt' && <MeanId>ID {info.mean_id}</MeanId>}
            </MeanTypeId>

            {getRides()}

            <AddtionalWrapper>
              <ButtonContainer2 space="20px">
                <TicketAction />
                <NewTicketAction />
                <AppPayAction />

                {info.mean_id &&
                  info.mean_type &&
                  (info.is_handicapped ? (
                    <HandicappedBlock
                      className="free"
                      ref={hoverPlace}
                      onMouseEnter={mouseEnter}
                      onMouseLeave={mouseLeave}
                    >
                      <img src="/images/tax-free.png" alt="" />
                      <Hover>
                        {t('form.Льгота')}
                        <br />
                        {info.handicapped_permit_number}
                      </Hover>
                    </HandicappedBlock>
                  ) : (
                    info.is_active &&
                    user.haveRight('stays.set_exemption') && (
                      <FreeButton theme={themes.gray} fontSize="16px" onClick={openFree}>
                        <img src="/images/tax-free.png" alt="" />
                        {t('form.Льгота')}
                      </FreeButton>
                    )
                  ))}

                <SetTariffAction />

                {info.is_active && user.haveRight('stays.manual_close') && (
                  <Button theme={themes.red} fontSize="16px" onClick={confirmCloseParking}>
                    {t('buttons.Закрыть парковку')}
                  </Button>
                )}
                {second && (
                  <Button title={t('grid.Повторный проезд')} theme={themes.gray} disabled>
                    <img src="/images/second.png" height="20" alt="se" />
                  </Button>
                )}
                {info.is_active && user.haveRight('promise_pay.edit') && (
                  <Button title={t('sections.Договор паркирования')} theme={themes.gray} onClick={promiseForm}>
                    {t('sections.Договор паркирования')}
                  </Button>
                )}
              </ButtonContainer2>

              <Payments info={info} />
            </AddtionalWrapper>

            {showPromise && <Promise id={info.id} parkingId={info.parking_id} close={hidePromiseForm} />}
            {showNewPay && (
              <NewPay
                id={info.id}
                parkingId={info.parking_id}
                meanId={info.mean_id}
                meanType={info.mean_type}
                close={closeNewPay}
              />
            )}
            {showInvalid && (
              <Invalid
                setHandicapped={(number) => dispatch(changeHandicapped(getStayId(), number))}
                close={closeInvalid}
              />
            )}
            {showFree && (
              <FreeBlock
                t={t}
                setFree={(number) => dispatch(changeHandicapped(getStayId(), number))}
                close={closeFree}
              />
            )}
          </CardWrapper>
        </>
      )}

      {confirm && (
        <Warning
          width="480px"
          header={t('form.Закрыть парковку?')}
          cancelButton={{
            func: () => setConfirm(false),
            text: t('buttons.Отменить'),
          }}
          acceptButton={{
            func: () => {
              setConfirm(false);
              dispatch(
                closeParking(info.id, () => {
                  dispatch(getStayInfo(info.id));
                })
              );
            },
            text: t('grid.Подтвердить'),
            theme: themes.blue,
          }}
        />
      )}
    </PageWrapper>
  );
};

export default StayCard;
