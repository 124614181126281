import { useState } from 'react';
import moment from 'moment';
import { useFormikContext } from 'formik';

import { Button, themes, Popup2 } from 'libs/ui';
import { Form, TextField, MediaField, DateField, Buttons } from 'libs/formik';
import { addAdv } from 'services/adv-service';
import { Content, Alert } from './upload-form.styled';
import { validationSchema } from './upload-form.validation';

import { useTranslation } from 'react-i18next';

//const maxSize = 10 * 1024 * 1024;
//let fileInfo = null;

const ButtonsBlock = (props) => {
  const { t } = useTranslation();

  const { hideForm } = props;
  const { isValid } = useFormikContext();
  return (
    <Buttons>
      <Button type="button" onClick={hideForm}>
        {t('buttons.Отменить')}
      </Button>
      <Button type="submit" theme={themes.blue} disabled={!isValid}>
        <i className="fa fa-save" />
        {t('buttons.Сохранить')}
      </Button>
    </Buttons>
  );
};

export const UploadForm = ({ type, refreshData }) => {
  const [form, setForm] = useState('');

  const { t } = useTranslation();

  /**
   * show form
   */
  const showForm = () => {
    setForm('add');
  };

  /**
   * hide form
   */
  const hideForm = () => {
    setForm('');
  };

  /**
   * get file content
   * @param {*} file
   */
  const getFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = (() => {
        return (e) => {
          // console.log('🚀 ~ return ~ e.target:', e);
          const out = e.target.result.split('base64,');
          resolve(out[1]);
        };
      })();

      if (file) {
        reader.readAsDataURL(file);
      } else {
        reject();
      }
    });
  };

  /**
   * add file action
   * @param {*} data
   */
  const add = (t) => async (data, helpers) => {
    let err = false;
    const out = { ...data };
    const tr = {
      'Failed to fetch': t('messages.Ошибка отправки данных'),
    };

    /*
    if (out.file1.size && out.file1.size > maxSize) {
      const dif = out.file1.size - maxSize;
      const err = 'Превышен максимальный размер файла ' + toMb(maxSize) + 'Мб на ' + toMb(dif) + 'Мб';
      helpers.setErrors({ api: err });
      return Promise.reject(err);
    }
    */
    const { file } = out.file1;

    out.list_id = type;
    out.datefrom = out.datefrom ? out.datefrom.format('YYYY-MM-DD') : null;
    out.datetill = out.datetill ? out.datetill.format('YYYY-MM-DD') : null;
    out.timefrom = out.timefrom ? out.timefrom : null;
    out.timetill = out.timetill ? out.timetill : null;
    out.name = file.name;
    out.data = await getFileContent(file);
    console.log('🚀 ~ add ~ out.duration:', out.duration);
    out.duration = out.duration ? Math.round(out.duration * 1000) : 0;
    delete out.file1;
    // console.log('🚀 ~ add ~ out:', out);
    // return Promise.reject('1');
    // console.log('🚀 ~ add ~ data:', out);
    await addAdv(out).catch((error) => {
      err = true;
      const errOut = {};
      if (error && error.data) {
        error.data.forEach((el) => {
          errOut[el.param] = el.message;
        });
      } else {
        if (typeof error === 'string' && error.indexOf('<html') !== -1) {
          errOut.api = t('messages.Ошибка отправки данных');
        } else {
          errOut.api = error?.message ? error?.message : error.toString();
        }

        // tr
        if (tr[errOut.api]) {
          errOut.api = tr[errOut.api];
        }
      }
      helpers.setErrors(errOut);
    });

    if (!err) {
      hideForm();
      refreshData();
    }
  };

  const d1 = moment();
  const d2 = '';
  const initial = {
    duration: 5,
    datefrom: d1,
    datetill: d2,
    timefrom: '', //07:00:00
    timetill: '',
    file1: '',
  };

  /**
   * filter number input
   * @param {*} event
   */
  const filterNumbers = (event) => {
    // console.log('🚀 ~ filterNumbers ~ event.charCode:', event.keyCode, event.charCode);
    if ([/*188,  190,*/ 189].includes(event.keyCode)) {
      event.preventDefault();
    }
  };

  /**
   * set field duration from media
   * @param {*} fileInfo
   * @param {*} helpers
   */
  const setDuration = (fileInfo, helpers) => {
    // console.log('🚀 ~ setDuration ~ fileInfo, helpers:', fileInfo, helpers);
    if (fileInfo.duration) {
      const val = fileInfo.duration.toFixed(2);
      helpers.setFieldValue('duration', val);
    }
  };

  return (
    <Content>
      <Button theme={themes.blue} onClick={showForm}>
        {t('buttons.Добавить файл')}
        {/* ДОбавить Файл */}
      </Button>
      {form === 'add' && (
        <Popup2 close={hideForm} title={t('buttons.Добавить файл')} w={540}>
          <Alert>
            {t('messages.Доступные форматы')} <b>PNG</b>, <b>MP4</b> {t('messages.c разрешением')} <b>1024*430</b>
          </Alert>
          <Form initialValues={initial} validationSchema={validationSchema} onSubmit={add}>
            <MediaField name="file1" label={t('grid.Файл')} accept="image/png, video/mp4" onChange={setDuration} />
            <TextField
              step=".01"
              min={0}
              max={10000}
              onKeyDown={filterNumbers}
              name="duration"
              label={t('grid.Период, с')}
              type="number"
            />
            <DateField name="datefrom" label={t('grid.Дата с')} />
            <DateField name="timefrom" label={t('grid.Время с')} type="time" withoutIcon />
            <DateField name="timetill" label={t('grid.Время по')} type="time" withoutIcon />
            <DateField name="datetill" label={t('grid.Дата по')} />
            <ButtonsBlock hideForm={hideForm} />
          </Form>
        </Popup2>
      )}
    </Content>
  );
};

export default UploadForm;
