import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getVehicleClasses } from 'services/types';
import { Content, CarType, RackName } from './rack-info.styled';

export const RackInfo = (props) => {
  const { t } = useTranslation();

  const { rackId, vehicleClass } = props;
  const vehicleClasses = getVehicleClasses();

  /**
   * get rack name
   * @param {*} value
   * @returns
   */
  const getRackName = (value) => {
    return value ? `C${value}` : null;
  };

  /**
   * get vehicle class
   * @param {*} id
   * @returns
   */
  const getVehicleClass = (id) => {
    if (!id) {
      return t('filter.Не определено');
    }
    const vehicleClass = vehicleClasses.find((elem) => {
      return elem.id.toString() === id.toString();
    });

    return vehicleClass ? vehicleClass.name : t('filter.Не определено');
  };

  // render
  return (
    <Content>
      <RackName>{getRackName(rackId)}</RackName>
      <CarType>
        <span>{t('dict_filter.Класс ТС')}:</span>
        <p>{getVehicleClass(vehicleClass)}</p>
      </CarType>
    </Content>
  );
};

RackInfo.propTypes = {
  rackId: PropTypes.any,
  vehicleClass: PropTypes.any,
};
