import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { user, data } from 'services';
import { routes } from 'router';
import { ParkingCell, Cell, GridRow } from 'libs/ui';
import StatusForm from 'modules/components/windows/ChangeStatus';
import withComment from 'modules/components/windows/withComment';

import { useTranslation } from 'react-i18next';

const RfidGridCell = styled(Cell)`
  border-bottom: 1px solid #6c7277;
  color: #fff;
  height: 40px;
`;

const RfidLockCell = styled(RfidGridCell)`
  &::before {
    position: absolute;
    left: 5px;
    content: ${(props) => (props.status === 'active' ? '' : 'url(/images/icon_lock_transparent.png)')};
  }
`;

const ClientsRfidRow = styled(GridRow)`
  background-color: ${(props) => (props.status === 'active' ? '#393e43' : '#4d5156')};
  td {
    color: ${(props) => (props.status === 'active' ? '#fff' : 'rgba(248, 248, 248, 0.4)')};
  }
  &:nth-child(2n) {
    background-color: #4d5157;
  }
  &:hover {
    background-color: #919aa6;
  }
`;

const Rfid = (props) => {
  const history = useHistory();

  const { t } = useTranslation();

  const isBlocked = () => props.is_blocked;

  const getLockIcon = () => {
    return <img src={isBlocked() ? '/images/icon_unlock_black.png' : '/images/icon_lock_black.png'} alt="" style={{ marginRight: '10px' }} />;
  };

  const showEditForm = () => {
    props.setFormInfo(
      {
        type: 'editForm',
        id: props.id,
        data: {
          rfid: props.rfid || '',
          owner: props.owner || '',
          vehicle_class: props.vehicle_class || '',
          vehicle_category: props.vehicle_category || '',
          vehicle_plate: props.vehicle_plate || '',
          tariff: props.tariff || '',
          tariff_id: props.tariff_id || '',
          comment: props.comment || '',
          parking_id: props.parking_id || '',
        },
      },
      props.type
    );
  };

  const showStatusForm = () => {
    props.setFormInfo(
      {
        type: 'status',
        value: props.rfid,
        id: props.id,
        status: props.status,
      },
      props.type
    );
  };

  const getForm = () => {
    const cardForm = props.cardForm;
    if (cardForm && cardForm.type === 'status' && cardForm.id === props.id) {
      return (
        <StatusForm
          type="rfid"
          info={cardForm}
          getEntries={props.getEntries}
          changeStatus={props.changeStatus}
          removeFormInfo={props.removeFormInfo}
          setWarning={props.setWarning}
          removeWarning={props.removeWarning}
        />
      );
    }
    return null;
  };

  const toClientStays = () => {
    history.push(routes.stays, {
      filter: {
        mean_type: 'rfid',
        mean_number: props.rfid,
      },
    });
  };

  const getDisplayedValue = (field, id) => {
    const dataArray = props[field];
    if (Array.isArray(dataArray)) {
      const foundItem = dataArray.find((item) => Number(item.id) === id);
      return foundItem ? foundItem.name : '';
    }
    return '';
  };

  const getMenu = () => {
    const rightUpdate = user.haveRight('rfid.update');
    const rightStaysList = user.haveRight('stays.list');
    const menuItems = [];
    if (rightStaysList) {
      menuItems.push({ text: t('buttons.Перейти в парковки'), func: toClientStays });
    }
    if (rightUpdate) {
      menuItems.push({ text: t('buttons.Редактировать'), func: showEditForm });
      menuItems.push({
        text: isBlocked() ? t('buttons.Разблокировать ') : t('buttons.Заблокировать '),
        func: showStatusForm,
      });
    }
    return menuItems;
  };

  const maps = user.getMaps();
  const cat = maps.vehicle_categoryMap[props.vehicle_category] || props.vehicle_category;

  return (
    <ClientsRfidRow status={props.status}>
      <ParkingCell parking_id={props.parking_id} cmp={RfidGridCell} />
      <RfidLockCell status={props.status}>{props.rfid}</RfidLockCell>
      <RfidGridCell>{data.getDictValue('payment_mean.status', props.is_blocked)}</RfidGridCell>
      <RfidGridCell>{props.owner}</RfidGridCell>
      <RfidGridCell>{cat}</RfidGridCell>
      <RfidGridCell>{getDisplayedValue('vehicleClasses', props.vehicle_class)}</RfidGridCell>
      <RfidGridCell>{props.vehicle_plate}</RfidGridCell>
      <RfidGridCell>{props.tariff}</RfidGridCell>
      <RfidGridCell>{props.comment}</RfidGridCell>
      {props.getCommentsCell({
        parentGetMenu: getMenu,
        parentGetForm: getForm,
        brdColor: '#6c7277',
      })}
    </ClientsRfidRow>
  );
};

export default withComment(Rfid);
