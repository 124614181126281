import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'libs/ui';
import { useTranslation } from 'react-i18next';

export const BtnImages = (props) => {
  const { t } = useTranslation();
  // render
  return (
    <Button fontSize="13px" onClick={props.action}>
      <i className="fa fa-image"></i>
      {t('buttons.Фото')}
    </Button>
  );
};

BtnImages.propTypes = {
  action: PropTypes.any.isRequired,
};
