import React from 'react';
import PropTypes from 'prop-types';
//import moment from 'moment';

import { Sum } from 'libs/ui';
import { Content } from './payments.styled';
//import { PenaltySum } from './penalty-sum';
import { Table } from './table';
import { useTranslation } from 'react-i18next';

export const Payments = (props) => {
  const { info } = props;
  const { t } = useTranslation;

  /*
  let payDate = '';
  if (info && info.last_success_payment) {
    payDate =
      ' ' +
      moment(info.last_success_payment.created_at)
        .format('lll')
        .replace(/[г., ]{4}/, '')
        .replace(/\./, '');
  }
  */
  const withPenalties = info.penalties && info.penalties.length;

  // render
  return (
    <Content>
      <Sum title="Стоимость парковки" amount={info.amount} />
      {info.penalties && info.penalties.length > 0 && (
        <Sum title="Штрафы" amount={info.penalty_amount}>
          <Table
            items={info.penalties}
            headers={[
              { title: 'Время', fn: 'created_at', type: 'date' },
              { title: 'Наименование', fn: 'comment' },
              { title: 'Сумма', fn: 'amount', type: 'price' },
              { title: 'Оплачен', fn: 'is_payed', type: 'boolean' },
              //{ title: 'Пользователь', fn: 'success',  },
            ]}
          />
        </Sum>
      )}
      {info.benefits && info.benefits.length > 0 && (
        <Sum title="Льготы/Cкидки" amount={info.benefit_amount}>
          <Table
            items={info.benefits}
            headers={[
              { title: 'Время', fn: 'created_at', type: 'date' },
              { title: 'Наименование', fn: 'comment' },
              { title: 'Сумма', fn: 'amount', type: 'price' },
              //{ title: 'Пользователь', fn: 'success', type: 'boolean' },
            ]}
          />
        </Sum>
      )}

      {info.payments && info.payments.length > 0 && (
        <Sum title="Оплачено" amount={info.payed_amount}>
          <Table
            items={info.payments}
            headers={[
              { title: 'Время', fn: 'created_at', type: 'date' },
              { title: 'Сумма', fn: 'amount', type: 'price' },
              { title: 'Успех', fn: 'success', type: 'boolean' },
            ]}
          />
        </Sum>
      )}

      <Sum title="Итого к оплате" amount={info.debt} final />
    </Content>
  );
};

Payments.propTypes = {
  info: PropTypes.any.isRequired,
};
