import React, { Component } from 'react';
import { reduxForm } from 'redux-form';

import { Container, ButtonsContainer, Input, DictFilter } from 'libs/form';
import { UseBtn, ResetBtn } from 'libs/ui';

import { useTranslation } from 'react-i18next';

const Filter = ({ handleSubmit, reset }) => {
  const { t } = useTranslation();

  return (
    <Container /*height='160px'*/>
      <form onSubmit={handleSubmit}>
        <DictFilter dict="benefit_types.status" />
        <Input name="name" label={t('form.Клиент')} placeholder={t('form.Клиент')} type="text" />
        <Input name="fn" label={t('form.Номер ФН')} placeholder={t('form.Номер ФН')} type="text" />
        <Input
          width={'170px'}
          name="min_amount"
          label={t('grid.Минимальная сумма, ₽')}
          placeholder={t('grid.Минимальная сумма')}
          type="text"
          naturalNumber
        />
        <Input
          width={'170px'}
          name="amount"
          label={t('grid.Сумма скидки, ₽')}
          placeholder={t('grid.Сумма скидки')}
          type="text"
          naturalNumber
        />

        <ButtonsContainer>
          <ResetBtn onClick={reset} />
          <UseBtn />
        </ButtonsContainer>
      </form>
    </Container>
  );
};

export default reduxForm({
  form: 'benefit_typesFilter',
  destroyOnUnmount: false,
  initialValues: {},
})(Filter);
