import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ImgViewer } from 'libs/ui';
import { Pictures, ClosePhotoButton } from './images.styled';

export const Images = (props) => {
  const { t } = useTranslation();
  // render
  return (
    <Pictures>
      <ImgViewer images={props.photosFrom} width={'630px'} height={'400px'} />
      <ImgViewer images={props.photosTo} width={'630px'} height={'400px'} />
      <ClosePhotoButton onClick={props.hideImages}>
        <i className="fa fa-arrow-up"></i> {t('buttons.Скрыть')}
      </ClosePhotoButton>
    </Pictures>
  );
};

Images.propTypes = {
  hideImages: PropTypes.any.isRequired,
  photosFrom: PropTypes.any.isRequired,
  photosTo: PropTypes.any.isRequired,
};
