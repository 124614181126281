import React, { useState } from 'react';
import { reduxForm, SubmissionError, Field, Form, submit } from 'redux-form';
import { useDispatch } from 'react-redux';
import { getTariffs, replaceRfidTariffs } from 'services/payments-service';
import { Button, themes, ButtonContainer } from 'libs/ui';
import { required } from 'libs/form';
import Warning from 'modules/components/warnings';
import { Popup2 } from 'libs/ui';
import { Inputs, ReplaceBlock, ReplaceHeader, StyledField } from './replace-window.styled';
import { useTranslation } from 'react-i18next';

const ReplaceWindow = (props) => {
  const { close, handleSubmit, reset, valid } = props;
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [showWarning, setShowWarning] = useState(false);
  const [oldTariff, setOldTariff] = useState(null);
  const [newTariff, setNewTariff] = useState(null);

  const submit = (values) => {
    if (!oldTariff || !newTariff) {
      throw new SubmissionError({ _error: 'Выберите тарифы для замены.' });
    }

    return replaceRfidTariffs(oldTariff.id, newTariff.id).then((errors) => {
      if (errors && Array.isArray(errors)) {
        let submissionErrors = {};
        errors.forEach((element) => {
          submissionErrors[element.field] = element.type;
        });
        throw new SubmissionError(submissionErrors);
      }

      reset();
      close(true);
    });
  };

  const getTariffsList = () => {
    return getTariffs({
      filter_params: {
        type: 'individual',
        status: 'active',
      },
      sort_params: [{ field_name: 'id', direction: 'asc' }],
    });
  };

  return (
    <Popup2 w={770}>
      <ReplaceHeader>{t('buttons.Заменить тариф у RFID')}</ReplaceHeader>
      <Form onSubmit={handleSubmit(submit)}>
        <Inputs>
          <StyledField
            placeholder={t('form.Старый тариф')}
            type="autocomplete2"
            name="old_id"
            getList={getTariffsList}
            validate={required(t)}
            onSelectItem={(val) => setOldTariff(val)}
          />
          <img src="/images/long-arrow-right.png" alt="" />
          <StyledField
            placeholder={t('form.Новый тариф')}
            type="autocomplete2"
            name="new_id"
            getList={getTariffsList}
            validate={required(t)}
            onSelectItem={(val) => setNewTariff(val)}
          />
        </Inputs>
        <ReplaceBlock>
          <img src="/images/warning.png" alt="" />
          <p>{t('messages.При массовой замене тарифа у RFID, новый тариф автоматически прекратит действие активного.')}</p>
        </ReplaceBlock>
        <ButtonContainer space="20px">
          <Button
            type="button"
            onClick={() => {
              reset();
              close();
            }}
          >
            {t('buttons.Отменить')}
          </Button>
          <Button type="button" theme={themes.blue} onClick={() => setShowWarning(true)} disabled={!valid}>
            {t('buttons.Заменить')}
          </Button>
        </ButtonContainer>
      </Form>

      {showWarning && (
        <Warning
          width="480px"
          header={`
            ${t('buttons.Заменить тариф')} «${oldTariff ? oldTariff.name : ''}»
            ${t('messages.на ')} «${newTariff ? newTariff.name : ''}»?
          `}
          cancelButton={{
            func: () => setShowWarning(false),
            text: t('buttons.Отменить'),
          }}
          acceptButton={{
            func: () => {
              setShowWarning(false);
              dispatch(submit('replaceTariffForm'));
            },
            text: t('buttons.Заменить'),
            theme: themes.blue,
          }}
        />
      )}
    </Popup2>
  );
};

export default reduxForm({
  form: 'replaceTariffForm',
  destroyOnUnmount: false,
})(ReplaceWindow);
