import React from 'react';
import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';

import { Filters } from 'libs/form';

const Filter = ({ handleSubmit, reset, type }) => {
  const { t } = useTranslation();

  const filters = [
    { type: 'dict', w: 140, dict: 'clients.status' },
    { type: 'dict', w: 120, dict: 'clients.type' },
    { type: 'input', w: 290, name: 'name', title: t('form.Клиент'), placeholder: t('form.Иванов И.И.') },
    {
      type: 'input',
      w: 290,
      name: 'contact_name',
      title: t('grid.Контактное лицо'),
      placeholder: t('form.Иванов И.И.'),
    },
    { type: 'input', w: 160, name: 'tel_num', title: t('grid.Телефон'), placeholder: t('grid.+7 (999) 999-99-99') },
  ];

  return <Filters items={filters} page={type} handleSubmit={handleSubmit} reset={reset} />;
};

export default reduxForm({
  form: 'clientsFilter',
  destroyOnUnmount: false,
  initialValues: { status: 'active' },
})(Filter);
