import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { user } from 'services';
import { Total, Button, themes, ButtonContainer, TableHeader, GridBox, Grid, TableHead, TableHeadRow, TopRow, withPopup } from 'libs/ui';
import Client from './Row';
import { routes } from 'router';

const CreateClientButton = withPopup((props) => {
  if (!user.haveRight('clients.add')) {
    return null;
  }

  return (
    <Button onClick={props.popupDisplayHandler} theme={themes.blue}>
      <img src="/images/shape.png" alt="" />
      {'buttons.Создать клиента'}
    </Button>
  );
});

const ClientsGrid = (props) => {
  const [showCreateClientMenu, setShowCreateClientMenu] = useState(false);
  const history = useHistory();

  const getClientsList = () => {
    const clients = props.entries || props.clients;
    if (!clients) return [];

    return clients.map((client) => <Client key={client.id} groups={props.groups} {...client} />);
  };

  const showNewClientPage = (type) => {
    props.setTypeOfNewClient(type);
    history.push(routes.newClient);
  };

  return (
    <GridBox>
      {!props.hideTotal && (
        <TopRow>
          <Total cnt={props.count} />
          <ButtonContainer space="18px">
            <CreateClientButton
              options={[
                { text: 'form.Физ. лицо', func: () => showNewClientPage('natural_person') },
                { text: 'form.ИП', func: () => showNewClientPage('sole_proprietor') },
                { text: 'form.Юр. лицо', func: () => showNewClientPage('legal_person') },
              ]}
            />
            {props.getXlsLink()}
          </ButtonContainer>
        </TopRow>
      )}
      <Grid className={props.className}>
        <TableHead>
          <TableHeadRow>
            <TableHeader sortField="name">{'form.Клиент'}</TableHeader>
            <TableHeader width="100px">{'dict_filter.Тип'}</TableHeader>
            <TableHeader width="115px">{'dict_filter.Статус'}</TableHeader>
            <TableHeader width="215px">{'grid.Контактное лицо'}</TableHeader>
            <TableHeader width="140px">{'grid.Телефон'}</TableHeader>
          </TableHeadRow>
        </TableHead>
        <tbody>{getClientsList()}</tbody>
      </Grid>
    </GridBox>
  );
};

export default ClientsGrid;
