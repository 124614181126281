import { data } from 'services';
import { getShortName, getCategoryById, getVehicleClassById, getDate } from 'services/types';
import { ParkingCell } from 'libs/ui';

export const getTableParams = (t) => {
  // get row class
  const getRowClass = (row) => {
    let out = '';
    out += ' ' + row.status;
    return out;
  };

  const getFields = (props) => {
    const fields = [
      { name: 'pan', title: t('sections.БСК'), width: 140, sortField: 'pan' },
      { name: 'vehicle_plate', title: t('sections.ГРЗ'), width: 170, sortField: 'vehicle_plate' },
      {
        name: 'is_blocked',
        title: t('grid.Статус'),
        width: 155,
        sortField: 'status',
        render: (val) => data.getDictValue('payment_mean.status', val),
      },
      {
        name: 'start_at',
        title: t('form.Дата действия с'),
        width: 210,
        sortField: 'start_at',
        render: (val) => getDate(val),
      },
      {
        name: 'end_at',
        title: t('form.Дата действия по'),
        width: 210,
        sortField: 'end_at',
        render: (val) => getDate(val),
      },
      { name: 'name', title: t('form.Держатель'), sortField: 'name', render: (val) => getShortName(val) },
    ];

    if (!props.clientId) {
      fields.push({ name: 'client_name', title: t('form.Клиент'), width: 145 });
    }
    fields.push({
      name: 'vehicle_class',
      title: t('dict_filter.Класс ТС'),
      width: 160,
      sortField: 'vehicle_class',
      render: (val) => getVehicleClassById(val),
    });

    fields.push({
      name: 'category_id',
      title: t('form.Категория'),
      width: 185,
      sortField: 'category_id',
      render: (val) => getCategoryById(val),
    });

    fields.push({ name: 'comment', title: t('filter.Комментарий'), width: 205 });

    if (window.config.central) {
      fields.unshift({
        name: 'parking_id',
        title: 'grid.Парковка',
        render: (val) => <ParkingCell parking_id={val} cmp={'span'} />,
        width: 140,
      });
    }

    return fields;
  };

  return { getFields, getRowClass, withComment: true };
};
